<template>
  <div class="add-nurse">
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="720px">
        <v-card :loading="getLoadingState" :disable="getLoadingState">
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>


            <v-card-title color="primary" class="text-h6 font-weight-regular justify-space-between">
              <span>{{ currentTitle }}</span>
              <v-avatar color="primary" size="24" v-text="step"></v-avatar>
            </v-card-title>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-container>
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.first_name" label="First name*" required
                            :rules="nameRules" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.last_name" label="Last name" :rules="nameRules" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="nurseProfile.email" label="Email*" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.phone_number" label="Phone number*" type="tel" required
                            :rules="phoneRules" hint="256793400004" :counter="14" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.other_number" label="Other number" type="tel"
                            hint="256793400004" :counter="14" />
                        </v-col>
                        <v-col cols="12">
                          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.date_of_birth" label="Date of birth" v-on="on"
                                type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.date_of_birth" color="green lighten-1"
                              @input="menu2 = false" />
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="nurseProfile.about_me" label="About Me" type="text" :counter="200" />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="nurseProfile.personal_statement" label="Personal statement" type="text"
                            :counter="200" />
                        </v-col>
                        </v-col>
                      </v-row></v-card-text>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete v-model="nurseProfile.gender" :items="['Male', 'Female', 'N/A']" label="Gender"
                            single />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete v-model="nurseProfile.availability" :items="[
                            'Full time',
                            'Night Time',
                            'Day Time',
                            'N/A',
                          ]" label="Availability" single :rules="nameRules" />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete v-model="nurseProfile.work_status" :items="[
                            'Student',
                            'Internship',
                            'Employed',
                            'Unemployed',
                            'Others',
                          ]" label="Work status" single :rules="nameRules" />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="nurseProfile.ref_name" label="Referee name*" />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="nurseProfile.ref_email" label="Referee email" />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="nurseProfile.ref_phone_number" label="Referee phone number"
                            :counter="14" />
                        </v-col>
                        <v-col cols="12">
                          <vuetify-google-autocomplete id="address" v-model="nurseProfile.address"
                            append-icon="mdi-search" placeholder="Start typing" label="Place of residence"
                            clearable="clearable" types="address" :enable-geolocation="true" :place-name="true"
                            country="UG" @placechanged="getAddressData" />
                        </v-col>
                        <v-col cols="12">
                          <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.join_date" label="Sign up date" v-on="on" type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.join_date" date-format="DD-MM-YYYY"
                              @input="menu3 = false" />
                          </v-menu>
                        </v-col>


                      </v-row></v-card-text>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="nurseProfile.work_place" label="Most recent work place" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="nurseProfile.pastPosition" label="Most recent work position" />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="nurseProfile.work_description" label="Describe your responsiblities." />
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox v-model="checkbox"
                            :label="`Is current position? ${checkbox == true ? 'YES' : 'NO'}`"></v-checkbox>

                        </v-col>
                        <v-col cols="6">
                          <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.work_started" label="Date work started" v-on="on"
                                type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.work_started" date-format="DD-MM-YYYY"
                              @input="menu3 = false" />
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.work_ended" label="Date work ended" v-on="on"
                                type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.work_ended" date-format="DD-MM-YYYY"
                              @input="menu3 = false" />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="4">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="nurseProfile.school" label="School attended" single />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete v-model="nurseProfile.education_level" :items="[
                            'Certificate',
                            'Diploma',
                            'Degree',
                            'Masters',
                            'PhD',
                            'Others',
                          ]" label="Highest education level" single />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete v-model="nurseProfile.qualification" :rules="nameRules" label="Qualifications"
                            editable :items="[
                              'Registered Bachelor of Science Nursing',
                              'Registered Bachelor of Science Midwifery',
                              'Registered Masters of Science Nursing',
                              'Registered Health Tutor-Nursing',
                              'Registered Health Tutor- Midwifery',
                              'Registered Public Health Nurse',
                              'Registered Comprehensive Nurse',
                              'Registered Nurse',
                              'Registered Midwife',
                              'Registered Mental Health Nurse',
                              'Registered Paediatric Nurse',
                              'Enrolled Nurse',
                              'Enrolled Midwife',
                              'Enrolled Mental Health Nurse',
                              'Enrolled Comprehensive Nurse',
                              'Community Health Worker',
                              'Village Health Team',
                              'Others',
                            ]" single />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.grade" label="Grade" type="text" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.certificate_no" label="Nursing certificate number"
                            type="text" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field v-model="nurseProfile.graduation_year" label="Graduation Year*" type="number"
                            :counter="4" :length="4" :rules="nameRules" />
                        </v-col>

                        <v-col cols="6">
                          <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.school_started" label="Date course started" v-on="on"
                                type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.school_started" date-format="DD-MM-YYYY"
                              @input="menu3 = false" />
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="nurseProfile.school_ended" label="Date course ended" v-on="on"
                                type="date" />
                            </template>
                            <v-date-picker v-model="nurseProfile.school_ended" date-format="DD-MM-YYYY"
                              @input="menu7 = false" />
                          </v-menu>
                        </v-col>

                      </v-row>
                    </v-card-text>
                  </v-window-item>
                </v-window>
              </v-container>
              <small>*indicates required field</small>
            </v-form>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn v-if="step > 1" variant="tonal" @click="step--">
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="step < 4" color="primary" variant="flat" @click="step++">
                Next
              </v-btn>
            </v-card-actions>



          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="red darken-1" text @click="closeDialog()">
              Close
            </v-btn>
            <v-btn v-if="isEditing" color="green darken-1" text :disabled="getLoadingState"
              @click="submitUpdateProfile()">
              Update
            </v-btn>
            <v-btn v-else color="green darken-1" text :disabled="getLoadingState" @click="addNurseProfile()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-dialog v-model="getLoadingState" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text class="pt-3">
              Please wait...
              <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ProfileForm",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    nurse: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      step: 1,
      lazy: false,
      valid: false,
      date: new Date(),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      ld: true,
      checkbox: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [(v) => !!v || "Field is required"],
      phoneRules: [(v) => !!v || "Phone number is required"],
      nurseProfile: {
        first_name: "",
        last_name: "",
        ref_phone_number: "",
        ref_email: "",
        ref_name: "",
        work_status: "",
        address: "",
        availability: "",
        gender: "",
        pastPosition: "",
        isCurrentPosition: false,
        work_place: "",
        work_started: '',
        work_ended: '',
        work_description: '',
        graduation_year: "",
        certificate_no: "",
        qualification: "",
        school: "",
        grade: '',
        date_of_birth: "",
        other_number: "",
        phone_number: "",
        education_level: "",
        school_started: '',
        school_ended: '',
        grade: '',
        latitude: "",
        longitude: "",
        join_date: "",
        email: "",
        about_me: '',
        personal_statement: '',

      },
    };
  },
  computed: {
    ...mapGetters([
      "listItem",
      "getLoadingState",
      "isInternetConnected",
      "getSelectedId",
    ]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formTitle: {
      get() {
        return this.title;
      },
      set(title) {
        this.$emit("input", title);
      },
    },
    submitAction: {
      get() {
        return this.isEditing;
      },
      set(isEditing) {
        this.$emit("input", isEditing);
      },
    },
    today() {
      let endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        1
      );
      return endDate.toISOString().slice(0, 10);
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Basic Information";
        case 2:
          return "Basic Info cont'd";
        case 3:
          return "Work History";
        default:
          return "Education Background";
      }
    }
  },

  watch: {
    dialog() {
      this.nurseProfile = Object.assign({}, this.nurse);
      if (this.isEditing) {
        this.getDataFromServer();
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getAddressData(addressData, placeResultData) {
      this.nurseProfile.address = addressData.name;
      this.nurseProfile.latitude = addressData.latitude;
      this.nurseProfile.longitude = addressData.longitude;
    },
    getDataFromServer() {
      let joinDate = this.nurse.join_date
        ? new Date(this.nurse.join_date)
        : moment();
      let dob = this.nurse.date_of_birth
        ? new Date(this.nurse.date_of_birth)
        : moment();
      // Format it and save to vue data property

      this.nurseProfile.join_date = this.frontEndDateFormat(joinDate);
      this.nurseProfile.date_of_birth = this.frontEndDateFormat(dob);
    },
    frontEndDateFormat(date) {
      return moment(date).format("LL");
    },
    backEndDateFormat(date) {
      return moment(new Date(date)).format("LL");
    },
    async addNurseProfile() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Some fields are not filled");
        return;
      }
      const payload = {
        nurse: {
          first_name: this.nurseProfile.first_name.trim(),
          last_name: this.nurseProfile.last_name.trim(),
          username:
            this.nurseProfile.first_name.trim() +
            " " +
            this.nurseProfile.last_name.trim(),
          email: this.nurseProfile.email.trim(),
          phone_number: this.nurseProfile.phone_number.trim(),
          other_number: this.nurseProfile.other_number,
          address: this.nurseProfile.address.trim(),
          about_me: this.nurseProfile.about_me.trim(),
          personal_statement: this.nurseProfile.personal_statement.trim(),
          date_of_birth: this.nurseProfile.date_of_birth,
          verified: false,
          active: true,
          gender: this.nurseProfile.gender,
          ref_phone_number: this.nurseProfile.ref_phone_number.trim(),
          ref_email: this.nurseProfile.ref_email,
          ref_name: this.nurseProfile.ref_name,
          employment_status: this.nurseProfile.work_status.trim(),
          availability: this.nurseProfile.availability.trim(),
          pastPosition: this.nurseProfile.pastPosition.trim(),
          work_place: this.nurseProfile.work_place.trim(),
          certificate_no: this.nurseProfile.certificate_no,
          qualification: this.nurseProfile.qualification.trim(),
          school: this.nurseProfile.school.trim(),
          education_level: this.nurseProfile.education_level.trim(),
          latitude: this.nurseProfile.latitude,
          longitude: this.nurseProfile.longitude,
          graduation_year: this.nurseProfile.graduation_year,
          profile_picture: "",
          profileComplete: false,
          online: false,
          work_status: this.nurseProfile.work_status.trim(),
          join_date:
            this.nurseProfile.join_date || new Date().format("m-d-Y h:i:s"),
        },

        work: {
          position: this.nurseProfile.pastPosition.trim(),
          company: this.nurseProfile.work_place.trim(),
          currentPosition: this.checkbox,
          endDate: this.nurseProfile.work_ended,
          startDate: this.nurseProfile.work_started,
          description: this.nurseProfile.work_description,


        },
        academics: {
          qualification: this.nurseProfile.qualification.trim(),
          school: this.nurseProfile.school.trim(),
          course: [this.nurseProfile.qualification.trim()],
          startDate: this.nurseProfile.school_ended,
          endDate: this.nurseProfile.school_started,
          grade: this.nurseProfile.grade,



        }
      };


      if (this.isInternetConnected) {
        try {
          const response = await this.$store.dispatch(
            "createNurseProfile",
            payload
          );
          console.log(response)

          if (response.data.status === "success") {
            this.$toast.success("Nurse profile created", {
              color: "success",
              queueable: true,
              timeout: 5000,
            });
            this.dialog = false;
          } else if (response.data.status === "failed") {
            this.$toast.error(response.data.error);
          }
        } catch (e) {
          this.$toast.error(e.response.data.error);
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },

    async submitUpdateProfile() {
      this.backEndDateFormat(this.nurseProfile.join_date);
      this.backEndDateFormat(this.nurseProfile.date_of_birth);
      console.log(this.nurseProfile)
      if (this.isInternetConnected) {
        try {
          const response = await this.$store.dispatch(
            "updateNurseProfile",
            this.nurseProfile
          );
          if (response.data.status === "success") {
            this.$toast.success("Nurse profile updated", {
              color: "success",
              queueable: true,
              timeout: 5000,
            });
            this.dialog = false;
            this.$router.go()
          } else if (response.data.status === "failed") {
            this.$toast.error(response.data.error);
          }
        } catch (e) {
          this.$toast.error(e.response.data.error);
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>