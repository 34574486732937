<template>
  <section class="order-detail-section">
    <core-home />
    <v-container id="printMe" class="order-detail">
      <v-row no-gutters>
        <v-col class="justify-center">
          <v-card :loading="loading" :disabled="loading">
            <section class="top-section d-flex">
              <div class="teheca-info">
                <img
                  src="@/assets/logo.png"
                  alt="Teheca Logo"
                  width="90"
                  height="90"
                />
                <h1 class="text-uppercase">Teheca Shop</h1>
                <p class="mb-0">0200 902 468</p>
                <p class="mb-0">info@teheca.com</p>
              </div>

              <v-card :color="getCardColor" dark class="order-top-info">
                <div class="d-flex justify-space-between">
                  <div class="mt-8 order-top">
                    <p>
                      <span>Order No:</span>
                      <span class="font-weight-bold">{{
                        order.orderNumber
                      }}</span>
                    </p>

                    <p class="pt-3">
                      <span
                        >Shipping fees:{{
                          formatAmount(order.shippingFee)
                        }}</span
                      ><br />
                      <span>Sub total :{{ formatAmount(order.subTotal) }}</span
                      ><br />
                      <span>Amount: {{ formatAmount(order.total) }}</span>
                    </p>
                  </div>

                  <div class="mt-1">
                    <p>
                      <span>{{ order.timeAdded }}</span
                      >,
                      <span>{{ order.dateAdded }}</span>
                    </p>

                    <div>
                      <p class="text-uppercase font-weight-light">
                        Order status
                      </p>
                      <dropdown
                        :value="order.status"
                        :order="order"
                        :options="dropDownOptions"
                      />
                    </div>

                    <p class="font-italic mt-2">
                      Updated on:
                      <span>{{ getDate(order.updatedAt) }}</span
                      >,
                      <span>{{ getTime(order.updatedAt) }}</span>
                    </p>
                  </div>
                </div>
              </v-card>
            </section>

            <v-divider />
            <v-spacer />
            <section class="middle-section">
              <div class="d-flex justify-space-between">
                <div>
                  <div>
                    <h4>Name</h4>
                    <p>{{ getUserName }}</p>
                  </div>

                  <div class="pt-5">
                    <h4>Contact</h4>
                    <p>{{ order.contact }}</p>
                  </div>
                </div>

                <div>
                  <div>
                    <h4>Shipping Address</h4>
                    <p>{{ order.address }}</p>
                  </div>

                  <div class="pt-5">
                    <v-dialog v-model="showDialog" persistent max-width="290">
                      <v-card>
                        <v-card-title class="headline">
                          Update Shipping Fee
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            v-model="shipping"
                            label="Enter Amount"
                            :rules="rules"
                            hide-details="auto"
                            type="number"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="showDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="handleShippingUpdate"
                          >
                            Update
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>

                <div>
                  <div>
                    <h4>Payment Method</h4>
                    <p>{{ order.paymentMethod }}</p>
                  </div>

                  <div class="pt-5">
                    <h4>Deivery Method</h4>
                    <p>{{ order.deliveryMethod }}</p>
                  </div>
                </div>
              </div>
            </section>
            <v-divider />
            <v-spacer />

            <section class="bottom-section">
              <div class="d-flex justify-space-between">
                <h2>Order Items</h2>
                <p>{{ getLength }} items</p>
              </div>

              <v-data-table
                :headers="headers"
                :items="order.products"
                class="elevation-2 data"
              >
                <template v-slot:item.image="{ item }">
                  <img width="80" height="80" :src="item.product_image" />
                </template>

                <template v-slot:item.amount="{ item }" class="amount">
                  <p class="text-justify">
                    {{ formatAmount(item.total_amount) }}
                  </p>
                </template>
              </v-data-table>
            </section>
          </v-card>
        </v-col>
      </v-row>
      <v-btn bottom color="secondary" dark fab fixed right @click="print">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-container>
  </section>
</template>

<script>
import Index from "@/components/Index";
import { mapGetters } from "vuex";
import { formatCurrency, orderStates } from "../../helpers";
import ShopDropdown from "./ShopDropdown";
const { CONFIRMED, RECEIVED, PENDING, COMPLETED, CANCELLED } = orderStates;

export default {
  name: "ShopComponent",
  components: {
    "core-home": Index,
    dropdown: ShopDropdown,
  },
  data() {
    return {
      showDialog: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      shipping: "",
      headers: [
        {
          text: "Id",
          value: "product_id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Price",
          value: "amount",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      order: "getSelectedOrder",
      customer: "getSelectedCustomer",
      loading: "getLoadingState",
      isConnected: "isInternetConnected",
    }),
    getLength() {
      return this.order.products === undefined
        ? ""
        : this.order.products.length;
    },
    getUserName() {
      return this.customer === undefined ? "--" : this.customer.displayName;
    },
    dropDownOptions() {
      const options = [PENDING, CONFIRMED, RECEIVED, COMPLETED, CANCELLED];
      return options;
    },
    getCardColor() {
      const status = this.order.status;
      if (status === RECEIVED) return "#388E3C";
      else if (status === CONFIRMED) return "pink";
      else if (status === PENDING) return "orange";
      else if (status === CANCELLED) return "red";
      else return "#388E3C";
    },
  },
  created() {
    const orderPayload = { id: this.$router.history.current.params.id };
    this.fetchOrder(orderPayload);
  },
  methods: {
    formatAmount(amount) {
      if (amount === null || amount === undefined) {
        return "0.0";
      }
      return formatCurrency(amount);
    },
    getDate(tsmp) {
      const date = new Date(tsmp);
      return ` ${date.getDate()} - ${date.getMonth()} - ${date.getFullYear()}`;
    },
    getTime(tsmp) {
      const date = new Date(tsmp);
      return ` ${date.getHours()}:${date.getMinutes()}`;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    async fetchOrder(payload) {
      this.$store.dispatch("fetchSelectedOrder", payload);
    },
    async handleShippingUpdate() {
      this.showDialog = false;
      const price = this.shipping.trim();
      if (price.length < 1 || price === "" || price === null) {
        this.$toast.error("Field not filled");
        return;
      }
      const payload = {
        id: this.$router.history.current.params.id,
        data: {
          key: "shippingFee",
          value: parseInt(price),
        },
      };

      if (this.isConnected) {
        try {
          const result = await this.$store.dispatch("updateOrder", payload);
          if (result) {
            return result;
          }
        } catch (e) {
          this.$toast.error(e.response.data.error);
          return e;
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.order-detail-section {
  overflow: scroll;
}

.order-detail {
  padding: 50px;
  overflow: scroll;

  .teheca-info {
    width: 30%;
  }

  .order-top {
    font-size: 1.3em;
  }
  .top-section {
    height: 250px;
  }

  .middle-section,
  .top-section,
  .bottom-section {
    padding: 30px;
  }

  .order-top-info {
    background-color: $primary;
    width: 70%;
    padding: 20px;
  }
}
</style>
