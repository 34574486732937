<template>
  <v-dialog v-model="showDialog" persistent max-width="500">
    <v-card :loading="loading">
      <v-img height="150" src="@/assets/images/nurse-01.png">
        <v-btn icon dark @click="dismissDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-img>

      <v-card-title class="mx-3">
        {{ booking.service }}
      </v-card-title>
      <v-card-text>
        <v-list-item>
          <v-avatar color="accent" size="40">
            <v-icon color="white darken-2"> mdi-account </v-icon>
          </v-avatar>

          <v-list-item-content class="mx-3">
            <v-list-item-title>
              {{ booking.firstName }} {{ booking.lastName }}</v-list-item-title
            >
            <v-list-item-subtitle>
              {{ booking.primaryPhoneNumber | formatPhoneNumber }}/
              {{ booking.otherPhoneNumber | formatPhoneNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-avatar color="primary" size="40">
            <v-icon color="white darken-2"> mdi-package </v-icon>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title>
              Ugx {{ formatAmount(booking.totalCost) }}</v-list-item-title
            >
            <v-list-item-subtitle>
              {{ booking.extras || booking.packageDetails }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-avatar color="accent" size="40">
            <v-icon color="white darken-2"> mdi-calendar </v-icon>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title> {{ serviceStartDate }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ booking.service }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-avatar color="primary" size="40">
            <v-icon color="white darken-2"> mdi-mother-nurse </v-icon>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title> Assigned nurse</v-list-item-title>
            <v-list-item-subtitle>
              {{ booking.nurseNames }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer />

          <v-btn icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-avatar color="accent" size="40">
            <v-icon color="white darken-2"> mdi-human-pregnant </v-icon>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title> Requested by</v-list-item-title>
            <v-list-item-subtitle>
              {{ booking.requestedFor }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn v-show="shouldCancel" color="red" text @click="cancelRequest()">
          Cancel request
        </v-btn>
        <v-btn
          v-show="shouldConfirm"
          color="orange"
          text
          @click="confirmRequest()"
        >
          Confirm request
        </v-btn>
        <v-btn
          v-show="shouldStart"
          color="primary"
          text
          @click="startRequest()"
        >
          Start request
        </v-btn>
        <v-btn
          v-show="shouldComplete"
          color="primary"
          text
          @click="completeRequest()"
        >
          complete request
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { remove } from "lodash";
import moment from "moment";
export default {
  name: "BookingDialog",
  data() {
    return {
      loading: false,
      selection: 1,
      expand: true,
    };
  },
  computed: {
    ...mapGetters([
      "currentBooking",
      "showDialog",
      "newBookingsList",
      "activeBookingsList",
      "completedBookingsList",
      "isInternetConnected",
    ]),
    booking() {
      return this.currentBooking;
    },
    showRating() {
      return this.booking.action !== "completed";
    },
    rating() {
      return 4.5;
    },
    shouldConfirm() {
      return this.booking.status === "Pending";
    },
    shouldCancel() {
      return this.booking.status !== "Completed";
    },
    shouldComplete() {
      return this.booking.status === "onGoing";
    },
    shouldStart() {
      return this.booking.status === "Accepted";
    },
    serviceStartDate() {
      return moment(new Date(this.currentBooking.serviceStartDate)).format(
        "LL"
      );
    },
  },
  methods: {
    dismissDialog() {
      this.$store.commit("SHOW_DIALOG", false);
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    formatAmount(amount) {
      return amount;
    },
    completeRequest() {
      let payload = {
        field: "status",
        value: "Completed",
        docId: this.booking.docId,
        user: this.booking.clientId,
      };
      if (this.isInternetConnected) {
        this.updateBooking(payload);
      } else {
        this.$toast.error("No internet connection");
      }
    },
    startRequest() {
      let payload = {
        field: "status",
        value: "onGoing",
        docId: this.booking.docId,
        user: this.booking.clientId,
      };
      if (this.isInternetConnected) {
        this.updateBooking(payload);
      } else {
        this.$toast.error("No internet connection");
      }
    },
    cancelRequest() {
      let payload = {
        field: "status",
        value: "Cancelled",
        docId: this.booking.docId,
        user: this.booking.clientId,
      };
      if (this.isInternetConnected) {
        this.updateBooking(payload);
      } else {
        this.$toast.error("No internet connection");
      }
    },
    confirmRequest() {
      let payload = {
        field: "status",
        value: "Accepted",
        docId: this.booking.docId,
        user: this.booking.clientId,
      };
      if (this.isInternetConnected) {
        this.updateBooking(payload);
      } else {
        this.$toast.error("No internet connection");
      }
    },
    async updateBooking(payload) {
      this.dismissDialog();
      try {
        const result = await this.$store.dispatch("updateBooking", payload);
        if (result) {
          this.$toast.success(
            `Booking status changed to ${payload.value.toLowerCase()}`,
            {
              color: "green",
            }
          );
          this.updateDataSet(result.data);
        }
      } catch (e) {
        this.$toast.error("Error updating booking");
        throw Error(e);
      }
    },
    updateDataSet(request) {
      let bookings = [];
      // eslint-disable-next-line eqeqeq
      if (request.status == "Accepted") {
        bookings = [...this.activeBookingsList, ...[request]];
        this.$store.commit("UPDATE_BOOKING_LIST", {
          status: "active",
          bookings,
        });
      }

      // eslint-disable-next-line eqeqeq
      if (request.status == "Cancelled" || request.status == "completed") {
        bookings = [...this.completedBookingsList, ...[request]];
        this.$store.commit("UPDATE_BOOKING_LIST", {
          status: "completed",
          bookings,
        });
      }
    },
    adjustBookingLists(listType, item, status) {
      let bookings = listType;
      remove(bookings, function (n) {
        return n.docId === item.docId;
      });
      this.$store.commit("UPDATE_BOOKING_LIST", { status: status, bookings });
    },
  },
};
</script>
<style lang="scss" scoped>
.cost {
  font-weight: bold;
  color: #000;
}
</style>
