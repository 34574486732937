import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { state } from './defaultState.js'

export default {
    state,
    mutations,
    getters,
    actions
}
