<template>
  <v-card class="order-card">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="jobs"
      class="elevation-2"
      :search="search"
      :loading="loading"
      loading-text="Loading job cards... Please wait"
    >
      <template v-slot:item.start_date="{ item }">
        <p class="mt-4">
          {{ item.start_date | setDateFormat("DD-MM-YYYY") }}
        </p>
      </template>
      <template v-slot:item.end_date="{ item }">
        <p class="mt-4">
          {{ item.end_date | setDateFormat("DD-MM-YYYY") }}
        </p>
      </template>

      <template v-slot:item.status="{ item }">
        <v-btn outlined x-small :color="getColor(item.status)">
          {{ item.status }}
        </v-btn>
      </template>

      <template v-slot:item.process="{ item }">
        <v-btn x-small color="orange" outlined  @click="viewJob(item)"> Details </v-btn>
      </template>
    </v-data-table>

    <add-job-card
      v-model="showDialog"
      title="Update Job Card"
      :is-editing="true"
      :job="selectedJob"
    />
  </v-card>
</template>

<script>
import { orderStates, formatDate } from "../../../helpers";
import { mapGetters } from "vuex";
import AddJobCard from "./AddJobCard";
const { CONFIRMED, RECEIVED, PENDING } = orderStates;

export default {
  name: "JobCardDataTable",
  components: {
    "add-job-card": AddJobCard,
  },
  props: {
    customerId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Job Cards",
    },
  },
  data() {
    return {
      search: "",
      showDialog: false,
      job: {},
      headers: [
        {
          text: "Care care No.",
          value: "care_card_number",
        },
        {
          text: "Start date",
          value: "start_date",
        },
        {
          text: "End ate",
          value: "end_date",
        },
        {
          text: "Service",
          value: "service",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Amt paid",
          value: "receipt_amount",
        },

        { text: "Manage", value: "process", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingState",
      selectedJob: "getSelectedJob",
      jobs: "getCustomerJobs",
      orders: "getCustomerOrders",
    }),
    id() {
      return this.customerId;
    },
    showLoading() {
      return this.loading;
    },
  },
  created() {
    // this.fetchCustomerJobs()
    // this.$store.dispatch('getCustomerJobList', { id: this.id })
  },
  methods: {
    getColor(value) {
      if (value == "Canceled") return "red";
      else if (value == PENDING) return "orange";
      else if (value == CONFIRMED) return "pink";
      else if (value == RECEIVED) return "green";
      else return "blue";
    },
    formatDate(value) {
      return formatDate(value);
    },
    viewJob(item) {
      this.showDialog = !this.showDialog;
      this.$store.commit("UPDATE_SELECTED_JOB", item);
    },
    // fetchCustomerJobs () {
    //     const payload = {
    //         id: this.id
    //     }
    //     console.log(this.id)
    //     this.$store.dispatch('getCustomerJobList', payload)
    // }
  },
};
</script>

<style></style>