const getNurseList = (state) => state.nurseList

const getNurseJobList = (state) => state.nurseJobList

const getNurseLoading = (state) => state.loadingNurses

const getShowNurseDialog = (state) => state.showAddNurseDialog

const getNurseReviews = (state) => state.nurseReviews

const showReviewDialog = (state) => state.showReviewDialog

export { getNurseList, getNurseJobList, getNurseLoading, getShowNurseDialog, getNurseReviews, showReviewDialog }
