/* eslint-disable no-unused-vars */
import { size } from 'lodash'
import { api } from '../../../utils/api'

/**
 * Calls the action to get user data for customer
 * @param {*} param0
 * @param {*} payload
 */
const getAllCustomerList = async ({ rootState, dispatch }, payload) => {
  const config = {
    config: {
      offset: size(rootState.dataListItems),
      limit: rootState.limitRequestSize,
    },
    url: 'customer',
    ...payload,
  }
  await dispatch('getListItems', config)
}
/**
 * Create customer profile
 * @param {*} param0
 * @param {*} payload
 */
const createCustomer = async ({ commit, rootState }, payload) => {
  const url = '/customer'
  try {
    const result = await api.post(url, payload)
    if (result) {
      let data = [result.data]
      const list = [...rootState.dataListItems, ...data]
      commit('UPDATE_DATA_LIST_ITEMS', list)
      return result
    }
  } catch (e) {
    throw e
  }
}
/**
 * Action to update the nurse profile
 * @param commit
 * @param rootState
 * @param payload
 * @returns {Promise<*>}
 */
const updateCustomerProfile = async ({ rootState, commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)

  const url = `/customer/${rootState.selectedId}`
  try {
    const result = await api.put(url, payload)
    if (result) {
      const { data } = result.data
      commit('UPDATE_SELECTED_LIST_ITEM_DATA', data)
      commit('UPDATE_LOADING_DATA_STATUS', false)

      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}
/**
 * Get one customer profile
 * @param commit
 * @param rootState
 * @returns {Promise<*>}
 */
const getCustomer = async ({ rootState }) => {
  const url = `/customer/${rootState.selectedId}`
  try {
    const result = await api.get(url)
    if (result.data.status === 'success') {
      return result
    }
  } catch (e) {
    throw e
  }
}

const fetchCustomerOrders = async ({ commit }, payload) => {
  if (!payload) {
    return
  }
  const url = `/user_income_trace/${payload}`
  commit('UPDATE_LOADING_DATA_STATUS', true)
  try {
    const result = await api.get(url)
    if (result.data.status === 'success') {
      const {data} = result.data
      commit('UPDATE_CUSTOMER_ORDERS', data.rows)
      commit('UPDATE_ORDER_COUNT', data.count)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const createCustomerOrder = async ({ state, commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  try {
    const result = await api.post('/income_trace', payload)

    if (result.data.status === 'success') {
      const { data } = result.data
      let newRecord = []
      newRecord.push(data)
      const income_trace = [...state.customerOrders, ...newRecord]
      commit('UPDATE_CUSTOMER_ORDERS', income_trace)
      commit('UPDATE_ORDER_COUNT', state.customerOrderCount+1)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {

    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const updateCustomerOrder = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  try {
    const result = await api.put(`/income_trace/${payload.id}`, payload)

    if (result.data.status === 'success') {
          commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

/**
 * This action deletes the 
 * @param {*} param0 
 * @param {*} payload 
 */
const deleteCustomer = async ({ commit, rootState }, payload) => {
  console.log("caled")
  try {
    return await api.delete(`/customer/${payload.id}`)

  } catch (error) {
    throw Error(error)
  }

}

export {
  createCustomer,
  fetchCustomerOrders,
  getAllCustomerList,
  getCustomer,
  updateCustomerProfile,
  createCustomerOrder,
  updateCustomerOrder,
  deleteCustomer
}
