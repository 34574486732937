import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

const state = {
    tehecaAuth: JSON.parse(localStorage.getItem('tehecaAuth')) || {}
}

export default {
    state,
    mutations,
    getters,
    actions
}
