<template>
  <v-dialog
    v-model="showDialog"
    transition="dialog-bottom-transition"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                lable="Customer Name"
                v-model="reviewForm.customer"
                :value="customer()"
              />
              <!-- <v-autocomplete
                :items="entries"
                :loading="loading"
                :search-input.sync="search"
                :value="customer"
                hide-no-data
                hide-selected
                clear-cache
                item-text="firstname"
                item-value="id"
                label="Customer Name"
                placeholder="Start typing to Search"
                return-object
                @change="(item) => selectedCustomer(item)"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for customer by name
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.firstname }} {{ item.last }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete> -->
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="reviewForm.customer_review"
                label="Customer Feedback*"
                required
                rows="3"
                hint="Enter here customers feedback"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <span class=""> Customer rating of nurse service </span>
              <v-rating
                hint="Client Rating "
                v-model="reviewForm.customer_rating"
                color="primary"
                large
              ></v-rating>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="reviewForm.nurse_review"
                label="Nurse Feedback*"
                required
                rows="3"
                hint="Enter here nurses feedback"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <span class="">
                How likely will the customer recommend our service
              </span>
              <v-slider
                hint="Service NPS"
                max="10"
                min="1"
                v-model="reviewForm.nps"
                :thumb-color="ex3.color"
                thumb-label
                ticks
              ></v-slider>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()"> Close </v-btn>
        <v-btn color="blue darken-1" text @click="saveReview()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ReviewForm",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    job: {
      type: Object,
      default: () => { },
    },
    title: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    review: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      model: null,
      search: null,
      customer_info: {},
      ex3: { val: 0, color: "green" },
      reviewDetails: {},
      loading: false,
      reviewForm: {
        customer: "",
        nurse_rating: 0.0,
        customer_rating: 0.0,
        customer_review: "",
        nurse_review: "",
        job_id: "",
        user_id: "",
        nurse_id: "",
        nps: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getDataListSearchItems",
      "showReviewDialog",
      "getSelectedJob",
    ]),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    submitAction: {
      get() {
        return this.isEditing;
      },
      set(isEditing) {
        this.$emit("input", isEditing);
      },
    },
  },
  watch: {
    getSelectedJob(val) {
      this.reviewForm.user_id = this.userId();
      this.reviewForm.customer = this.customer();
      this.reviewForm.job_id = this.selectedJobId();
      this.reviewForm.nurse_id = this.nurseId();
    },
    submitAction(val) {
      this.reviewForm.user_id = this.userId();
      this.reviewForm.customer = this.customer();
      this.reviewForm.job_id = this.selectedJobId();
      this.reviewForm.nurse_id = this.nurseId();
    },
    showReviewDialog(val) {
      this.dialog = val;
    },
  },

  methods: {
    userId() {
      return this.submitAction
        ? this.review.user_id
        : this.getSelectedJob.job.UserId;
    },
    selectedJobId() {
      return this.submitAction ? this.review.job_id : this.getSelectedJob.jobId;
    },
    getJobId() {
      if (this.job) {
          return this.job.id
      } 
      return null
    },
    customer() {
      return this.submitAction
        ? this.review.customer
        : this.getSelectedJob.job.client_name;
    },
    nurseId() {
      return this.submitAction
        ? this.review.nurse_id
        : this.getSelectedJob.nurseId;
    },
    closeDialog() {
      this.showDialog = !this.showDialog;
    },

    async saveReview() {
      try {
        const result = await this.$store.dispatch(
          "saveReview",
          this.reviewForm
        );
        if (result) {
          this.$toast.success("Review added", {
            color: "success",
            dismissable: true,
            queueable: true,
          });
          const jobUpdate = {
            id: this.getJobId(),
            data: {
              rating: this.reviewForm.nps,
            },
          };
          await this.$store.dispatch("updateJobCard", jobUpdate);
        }
        this.closeDialog();
      } catch (error) {
        this.$toast.error("Error saving review  ", {
          color: "error",
          dismissable: true,
          queueable: true,
        });
        this.closeDialog();

        throw Error(error);
      }
    },
  },
};
</script>