import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon, VTextField } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'
import VuetifyGoogleAutocomplete from '@sudocho/vuetify-google-autocomplete'
Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon,
        VTextField
    }
})
Vue.use(VuetifyToast, {
    x: 'right',
    y: 'top'
})

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: process.env.VUE_APP_GOOGLE_PLACES_API
})

const vuetifyOpts = {
    rtl: false,
    icons: {
        iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#388E3C',
                accent: '#FF4081',
                secondary: '#ffe18d',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            },
            light: {
                primary: '#4CAF50',
                accent: '#e91e63',
                secondary: '#30b1dc',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            }
        }
    }
}

export default new Vuetify(vuetifyOpts)
