/**
 * Update the token in the localStorage
 * @method setToken
 * @param  {Object} commit vuex mutations
 * @param  {Object} payload values to update
 */
const setToken = ({ state }) => {
    localStorage.setItem('tehecaAuth', JSON.stringify(state.tehecaAuth))
    const expires = parseInt(state.tehecaAuth.expiresIn)
    setTimeout(() => removeToken(), expires)
}

/**
 * Update the token in the localStorage
 * @method setToken
 * @param  {Object} commit vuex mutations
 * @param  {Object} payload values to update
 */
const removeToken = () => {
    localStorage.removeItem('tehecaAuth')
}

export {
    setToken,
    removeToken
}
