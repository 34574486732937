import Vue from 'vue'
import Vuex from 'vuex'
import token from './modules/token'
import careTeam from './modules/careteam'
import customers from './modules/customers'
import comms from './modules/comms'
import bookings from './modules/bookings'
import shop from './modules/shop'
import jobCards from './modules/jobCard'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { state } from './default_state'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        token,
        careTeam,
        comms,
        customers,
        bookings,
        shop,
        jobCards
    },
    strict: process.env.NODE_ENV !== 'production'
})
