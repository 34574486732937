<template>
  <div class="comm-content">
    <v-tabs class="elevation-1">
      <v-tab ripple> Push Notifications </v-tab>
      <v-tab ripple> Health care info </v-tab>
      <v-tab-item class="notifications">
        <v-card flat>
          <v-card-text>
            <notification />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item class="care-tips">
        <v-card flat>
          <v-card-text>
            <care-tips />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
        <router-view></router-view>
  </div>
</template>

<script>
import Notification from "./NotificationManager";
import CareTips from "./CareTips";

export default {
  name: "Communication",
  components: {
    notification: Notification,
    "care-tips": CareTips,
  },
};
</script>
<style lang="scss" scoped>
.comm-content {

}
</style>
