<template>
  <section class="nurse-profile-content">
    <v-card>
      <v-img class="white--text align-end" height="300px" src="@/assets/bg.png">
        <div class="userPic">
          <v-avatar size="120">
            <input
              ref="file"
              type="file"
              style="display: none"
              accept="image/*"
              @change="onFilePicked"
            />
            <button @click="$refs.file.click()">
              <v-avatar v-if="profile_picture" size="150">
                <v-img
                  :src="profile_picture"
                  aspect-ratio="1"
                  class="accent lighten-2"
                />
              </v-avatar>
              <v-avatar v-else color="accent" size="130">
                <span class="white--text headline">{{
                  userInitial(listItem.username)
                }}</span>
              </v-avatar>
            </button>

            <!-- <div>
              <input type="file" @change="handleFileUpload" />
            </div> -->
          </v-avatar>
          <div class="profile">
            <v-card-title class="names">
              {{ listItem.first_name }} {{ listItem.last_name }},({{
                listItem.gender
              }})
            </v-card-title>
            <v-card-subtitle class="contact pt-3">
              {{ listItem.phone_number | formatPhoneNumber }} /
              {{ listItem.other_number | formatPhoneNumber }}
              <br />
              <v-rating
                dense
                :value="listItem.rating"
                class="rating"
                color="white"
              />
            </v-card-subtitle>
          </div>
        </div>
      </v-img>
      <v-tabs class="elevation-1" :centered="true" :grow="true" align-with-title v-model="selectedTab">
        <v-tab>Profile</v-tab>
        <v-tab>Nurse Jobs</v-tab>
        <v-tab @click="showReview">Reviews</v-tab>

        <v-tab-item>
          <profile-content />
        </v-tab-item>
        <v-tab-item >
          <nurse-job-data-table title="My Jobs" :jobs="getNurseJobList" />
        </v-tab-item>

        <v-tab-item>
          <nurse-review-data-table :reviews="getNurseReviews" />
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!-- This should be a component -->
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline"> Confirm upload? </v-card-title>

        <v-card-text>Save Profile Picture?</v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="uploadToFb()">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="prorgressDialog" max-width="400" persistent>
      <v-card>
        <v-card-text>
          <v-progress-circular
            :size="70"
            :width="6"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-card-text>
      </v-card>
    </v-dialog>
    <profile-form
      v-model="showAddNurseDialog"
      title="Add new nurse profile"
      :is-editing="false"
      :nurse="{}"
    />
    <v-btn bottom color="primary" dark fab fixed right @click="showDialogs()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>
<script>
import { Storage } from '@google-cloud/storage'

import { mapGetters } from 'vuex'
import { Helper } from '@/helpers/helper'
//import ProfileForm from './ProfileForm.vue'
import AddNurseCard from './AddNurseCard.vue'
import NurseJobDataTable from './NurseJobDataTable'
import NurseReviewDataTable from './NurseReviewDataTable'
import ProfileContent from './ProfileContent'
import * as utils from '../../helpers'
import { size } from 'lodash'
import moment from 'moment'

export default {
  name: 'ProfileDetailsSection',
  components: {
    'profile-form': AddNurseCard,
    'nurse-job-data-table': NurseJobDataTable,
    'nurse-review-data-table': NurseReviewDataTable,
    'profile-content': ProfileContent,
  },
  data() {
    return {
      centered: false,
      grow: false,
      showAddNurseDialog: false,
      showReviewDaiload: false,
      prorgressDialog: false,
      user: {},
      imageName: '',
      imageUrl: '',
      imageFile: '',
      dialog: false,
      selectedTab: null
    }
  },
  computed: {
    ...mapGetters([
      'listItem',
      'getLoadingState',
      'getNurseJobList',
      'isInternetConnected',
      'getNurseReviews',
    ]),
    profile_picture() {
      return this.imageUrl !== ''
        ? this.imageUrl
        : this.listItem.profile_picture
    },
    selectedUser() {
      return this.listItem
    },
    rating() {
      return parseInt(this.selectedUser.rating)
    },
    approved() {
      return this.selectedUser.verified === true
        ? 'Approved'
        : 'Pending approval'
    },
    displayName() {
      return this.selectedUser.first_name + '_' + this.selectedUser.last_name
    },
    uploadFile(file) {
      const storage = new Storage({
        keyFilename: '../../firebase/service-account-key.json',
        projectId: 'teheca-dev',
      })
      const bucketName = 'teheca-dev.appspot.com'
      const filename = file.name

      const bucket = storage.bucket(bucketName)
      const fileStream = bucket.file(filename).createWriteStream()

      fileStream.on('error', (err) => {
        console.error(`Error uploading ${filename}: ${err}`)
      })

      fileStream.on('finish', () => {
        console.log(`Successfully uploaded ${filename}`)
      })

      return fileStream.end(file.data)
    },
  },
  created() {
    this.fetchProfile()
    this.fetchReviews()
  },
  methods: {
    async handleFileUpload(e) {
      const file = e.target.files[0]
      await this.uploadFile(file)

      // const storage = new Storage({
      //   keyFilename: '../../firebase/service-account-key.json',
      //   projectId: 'teheca-dev',
      // })
      // const bucketName = 'teheca-app.appspot.com'
      // const filename = file.name

      // const bucket = storage.bucket(bucketName)
      // console.log(bucket)
      // const fileStream = bucket.file(filename).createWriteStream()
      // console.log(storage)
      // fileStream.on('error', (err) => {
      //   console.error(`Error uploading ${filename}: ${err}`)
      // })

      // fileStream.on('finish', () => {
      //   console.log(`Successfully uploaded ${filename}`)
      // })

      // // fileStream.end(file.data)
    },
    async fetchProfile() {
      await this.$store.dispatch('getNurseDetails', this.$route.params.id)
    },

    async fetchReviews() {
      await this.$store.dispatch('fetchNurseReviews', this.$route.params.id)
    },
    joinDate(date) {
      return moment(date).format('LL')
    },
    userInitial(username) {
      return Helper.initialGenerate(username)
    },
    showReview() {
      this.showAddNurseDialog = false
      this.showReview = true
    },
    editUser() {
      this.showAddNurseDialog = true
    },
    showDialogs() {
      this.showAddNurseDialog = !this.showAddNurseDialog
    },

    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result
          this.imageFile = files[0]
          this.dialog = !this.dialog
        })
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }
    },
    async uploadToFb() {
      try {
              
        this.dialog = !this.dialog;
        if (!this.imageFile) {
          return;
        }

        const imageData = await utils.fileProcessor(
          this.displayName.toLowerCase(),
          this.imageFile
        );
        if (size(imageData)) {
          this.prorgressDialog = !this.prorgressDialog
          let payload = {
            imageFile: imageData.data.image.data,
            imageName: imageData.data.image.filename,
            userId: this.selectedUser.id,
            phone_number: this.selectedUser.phone_number,
            email: this.selectedUser.email,
            firebaseId: this.selectedUser.firebaseId,
            url: `/careteam/${this.selectedUser.id}`,
          };


          // Create a storage reference from our storage service
          const storageRef = ref(storage, 'nurseprofile');


          const result = await uploadBytes(storageRef, payload.imageFile).then((snapshot) => {
            console.log(snapshot)
            console.log('Uploaded a blob or file!');
          });
          console.log(result);
        }
      } catch (e) {
        throw Error(e)
      }
    },
    async uploadProfilePic() {
      this.dialog = !this.dialog
      if (!this.imageFile) {
        return
      }

      const imageData = await utils.fileProcessor(
        this.displayName.toLowerCase(),
        this.imageFile
      )
      if (size(imageData)) {
        this.prorgressDialog = !this.prorgressDialog
        let payload = {
          imageFile: imageData.data.image.data,
          imageName: imageData.data.image.filename,
          userId: this.selectedUser.id,
          phone_number: this.selectedUser.phone_number,
          email: this.selectedUser.email,
          firebaseId: this.selectedUser.firebaseId,
          url: `/careteam/${this.selectedUser.id}`,

        }
        try {
          const response = await this.$store.dispatch('uploadPicture', payload)
          if (response) {
            this.$toast.success('Profile image set', {
              color: 'success',
              queueable: true,
              timeout: 5000,
            })
            this.prorgressDialog = false
          }
        } catch (e) {
          this.prorgressDialog = false
          console.log(e.message)
          this.$toast.error('Error uploading nurse photo')
          throw Error(e)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.userPic {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  text-align: center;

  .names {
    display: inline-block;
    text-align: center;
    font-size: 26px;
    margin-bottom: 5px;

    @include media-breakpoint-up(xl) {
      font-size: 2.5em;
    }
  }

  .contact {
    font-size: 16px;
    color: white;

    @include media-breakpoint-up(xl) {
      font-size: 2em;
    }
  }
}
</style>
