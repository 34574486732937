/**
 * Updates the token
 * @method UPDATE_TOKEN
 * @param  {Object} state vuex state object
 * @param  {Object} payload values to update
 */
const SET_TEHECA_AUTH = (state, payload) => {
    state.tehecaAuth = { ...state.tehecaAuth, ...payload }
}

export {
    SET_TEHECA_AUTH
}
