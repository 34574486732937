<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="2">
        <user-list :infinite-handler="getAllCustomerList" />
      </v-col>
      <v-col cols="10">
        <router-view />
      </v-col>
    </v-row>

    <add-customer-dialog
      v-model="showDialog"
      title="Add Customer"
      :is-editing="false"
    />
    <v-btn
      bottom
      color="secondary"
      dark
      fab
      fixed
      right
      @click="showDialog = !showDialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import AddCustomerDialog from "./AddCustomerDialog";
import { UserLists } from "@/components/common";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerContent",
  components: {
    "user-list": UserLists,
    "add-customer-dialog": AddCustomerDialog,
  },
  data() {
    return {
      showDialog: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      customer: "listItem",
      listType: "listType",
      userId: "getSelectedId",
    }),
    selectedListType() {
      return this.listType;
    },
  },
  watch: {
    userId: function () {
      this.$nextTick(() => {
        this.getDetails();
        this.$store.dispatch("fetchCustomerOrders", this.customer.id);
      });
    },
  },
  created() {
    if (this.userId) {
      this.getDetails();
    }
    this.$store.dispatch("fetchCustomerOrders", this.customer.id);
  },
  updated() {
    if (this.customer) {
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  methods: {
    ...mapActions(["getAllCustomerList"]),
    selectedMessage(id) {
      this.$store.commit("RESET_SELECTED_ITEM_DETAILS");
      this.$store.commit("UPDATE_SELECTED_ID", id);
      this.show = true;
    },
    async getDetails() {
      try {
        const result = await this.$store.dispatch("getCustomer");
        if (result) {
          this.show = true;
        }
      } catch (err) {
        this.$toast.error("Error loading  profile.");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user-list {
  margin-left: -2%;
}
</style>
