/**
 * Set user is logged In
 * @param {*s} state
 * @param {boolean} payload
 */
const ISLOGGEDIN = (state, payload) => {
  state.isAuthenticated = payload
}
/**
 * Set user is logged out
 * @param {*} state
 */
const LOGOUT = (state) => {
  state.isAuthenticated = false
}

/**
 * Update the intenet connection status
 * @param {*} state
 */
const UPDATE_INTERNET_STATE = (state, status) => {
  state.isConnected = status
}

/**
 * Update loading state
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_LOADING_DATA_STATUS = (state, payload) => {
  state.loadingState = payload
}
/**
 * Update the selected id
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_SELECTED_ID = (state, payload) => {
  state.selectedId = payload
}
/**
 * Updates the state data with the list items from search
 * @param {*} state
 * @param {Array} payload
 */
const UPDATE_DATA_LIST_SEARCH_ITEMS = (state, payload) => {
  state.searchListDataItems = payload
}
/**
 * Updates the state data with the list items
 * @param {*} state
 * @param {Array} payload
 */
const UPDATE_DATA_LIST_ITEMS = (state, payload) => {
  state.dataListItems = payload
}
/**
 * Update state of search action
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_IS_SEARCH = (state, payload) => {
  state.isSearch = payload
}
/**
 * Resets the sidebar list contents
 * @method REST_SIDEBAR
 * @param  {Object} state vuex state Object
 */
const REST_USER_LIST = (state) => {
  state.listItems = []
  state.dataListItems = []
  state.noResultsFound = false
}

/**
 * Clear selected item deatils
 * @method RESET_SELECTED_ITEM_DETAILS
 * @param  {Object} state vuex state Object
 */
const RESET_SELECTED_ITEM_DETAILS = (state) => {
  state.listItem = {}
}

/**
 * Update the data object of the selected item on the user list
 * @method UPDATE_SELECTED_LIST_ITEM_DATA
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_SELECTED_LIST_ITEM_DATA = (state, payload) => {
  state.listItem = payload
}

/**
 * Set the type of list to load
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_LIST_TYPE = (state, payload) => {
  state.listType = payload
}

/**
 * Updates the current selected booking record.
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_SELECTED_BOOKING = (state, payload) => {
  state.booking = payload
}

/**
 * Used to handle dialogshow and hidding
 * @param {*} state
 * @param {*} payload
 */
const SHOW_DIALOG = (state, payload) => {
  state.showDialog = payload
}
/**
 *
 * @param state
 * @param payload
 * @method UPDATE_DASHBOARD_TITLE
 */
const UPDATE_DASHBOARD_TITLE = (state, payload) => {
  state.title = payload
}

/**
 * Toggle showing drawer
 * @param {Object} state
 * @param {Boolean} payload
 */
const SHOW_DRAWER = (state, payload) => {
  state.showDrawer = payload
}

/**
 * @method UPDATE_SEARCH_LIST
 * @param {Object} state
 * @param {Array} payload
 */
const UPDATE_SEARCH_LIST = (state, payload) => {
  state.searchList = payload
}

/**
 * To show send message dialog or not.
 * @method SHOW_SEND_MSG_DIALOAG
 * @param {Object} state
 * @param {Boolean} payload
 */
const SHOW_SEND_MSG_DIALOAG = (state, payload) => {
  state.showSendMsgDiaglog = payload
}

const UPDATE_NURSE_STATUS_QUERY = (state, payload) => {
  state.fetchApproved = payload
}

const SET_CURRENT_ADMIN = (state, payload) => {
  state.currentAdmin = payload
}

const UPDATE_CURRENT_USER_EMAIL = (state, payload) => {
  state.currentUser.email = payload
  localStorage.setItem('currentUser', JSON.stringify(state.currentUser))
}

const UPDATE_INCOME_TRACE = (state, payload) => {
  state.income_trace = payload
}

const UPDATE_COUNT = (state, payload) => {
  state.count = payload
}

const UPDATE_ACCOUNT_STATUS=(state,payload)=>{
  state.account_status=payload

}
export {
  LOGOUT,
  ISLOGGEDIN,
  SHOW_DRAWER,
  REST_USER_LIST,
  UPDATE_IS_SEARCH,
  UPDATE_SELECTED_ID,
  UPDATE_DATA_LIST_ITEMS,
  UPDATE_LOADING_DATA_STATUS,
  UPDATE_DATA_LIST_SEARCH_ITEMS,
  UPDATE_SELECTED_LIST_ITEM_DATA,
  RESET_SELECTED_ITEM_DETAILS,
  UPDATE_SELECTED_BOOKING,
  UPDATE_DASHBOARD_TITLE,
  SHOW_SEND_MSG_DIALOAG,
  UPDATE_SEARCH_LIST,
  UPDATE_LIST_TYPE,
  SHOW_DIALOG,
  UPDATE_INTERNET_STATE,
  UPDATE_NURSE_STATUS_QUERY,
  UPDATE_CURRENT_USER_EMAIL,
  SET_CURRENT_ADMIN,
  UPDATE_INCOME_TRACE,
  UPDATE_COUNT,
  UPDATE_ACCOUNT_STATUS
}
