<template>
  <div class="profile">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-img v-if="profilePicture" :src="profilePicture" dark />
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ customer.firstname }}
                  {{ customer.lastname }}</v-list-item-title>
                <v-list-item-subtitle>Names</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  customer.phonenumber | formatPhoneNumber
                }}</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon ripple>
                <v-icon @click="sendMessage()">mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item v-if="customer.alternate_number">
              <v-list-item-action />

              <v-list-item-content>
                <v-list-item-title>{{
                  customer.alternate_number | formatPhoneNumber
                }}</v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-divider inset />

            <v-list-item v-if="customer.email">
              <v-list-item-icon>
                <v-icon color="primary"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ customer.email }}</v-list-item-title>
                <v-list-item-subtitle>Personal</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-list-item v-if="customer.address">
              <v-list-item-icon>
                <v-icon color="primary"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ customer.address }}</v-list-item-title>
                <v-list-item-subtitle v-if="customer.contry">
                  {{ customer.contry }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  Uganda, East Africa
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer />
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>

          <v-expand-transition>
            <div v-show="show">
              <v-divider />

              <v-card-text>
                <v-list two-line subheader>
                  <v-subheader>Basic customer profile</v-subheader>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Children</v-list-item-title>
                      <v-list-item-subtitle>{{
                        customer.children
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Sex</v-list-item-title>
                      <v-list-item-subtitle>{{
                        customer.gender
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Activation Code</v-list-item-title>
                      <v-list-item-subtitle>{{
                        customer.activation_code
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Join date</v-list-item-title>
                      <v-list-item-subtitle>{{
                        customer.createdAt | setDateFormat("DD-MM-YYYY")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </v-expand-transition>

          <add-customer-dialog v-model="showAddCustomerDialog" title="Edit Customer" :is-editing="true"
            :customer="customer" />

          <add-job-card v-model="showAddJobCardDialog" title="Add Job Card" :is-editing="false" />

          <message-dialog v-model="showMessageDialog" :fcmToken="customer.fcm_token" />

          <v-card-actions>
            <v-btn text @click="showAddCustomerDialog = !showAddCustomerDialog">
              Edit Profile</v-btn>

            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on"> Customer Action </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-title @click="customerAction(item.title)">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
          <v-dialog v-model="dialog" max-width="400">
            <v-card>
              <v-card-title class="headline"> Confirm upload? </v-card-title>

              <v-card-text> Save Profile Picture? </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn color="green darken-1" text @click="dialog = false">
                  Cancel
                </v-btn>

                <v-btn color="green darken-1" text @click="uploadProfilePic()">
                  Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>

      <v-col cols="9" class="customer-data">
        <div class="d-flex flex-column mb-6">
          <job-card-data-table :customer-id="listItem.id" :loading="loading" title="Customer Jobs" />
        </div>
        <div class="d-flex flex-column mb-6">
          <customer-order :data="getCustomerOrders" :count="getCount" title="My Orders" />
        </div>
      </v-col>
      <delete-dialog-component title="Delete customer?" dataType="customer" :showDialog="deleteDialog"/>

    </v-row>
    <add-order-card :value="showAddOrderCard" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddCustomerDialog from "../AddCustomerDialog";
import * as utils from "../../../helpers";
import AddJobCard from "./AddJobCard";
import MessageDialog from "../../common/MessageDialog.vue";
import JobCardDataTable from "./JobCardDataTable";
import CustomerOrder from "./CustomerOrder.vue";
import AddOrderCard from "./AddOrderCard.vue";
import { size } from "lodash";
import DeleteDialogComponent from '../../shared/DeleteDialogComponent.vue';
export default {
  name: "Profile",
  components: {
    AddCustomerDialog,
    MessageDialog,
    "add-job-card": AddJobCard,
    "job-card-data-table": JobCardDataTable,
    "customer-order": CustomerOrder,
    AddOrderCard,
    DeleteDialogComponent,
  },
  data: () => ({
    show: false,
    showAddJobCardDialog: false,
    showAddCustomerDialog: false,
    showCommDialog: false,
    imageName: "",
    imageUrl: "",
    imageFile: "",
    dialog: false,
    showMessageDialog: false,
    loading: true,
    showAddOrderCard: false,
    deleteDialog: false,
    items: [{ title: "Add Job Card" }, { title: "Add Order" }, { title: "Delete Customer" }],
  }),
  computed: {
    ...mapGetters([
      "listItem",
      "showSendMsgDiaglog",
      "getCustomerOrders",
      "getCount",
    ]),
    profilePicture() {
      return this.customer.photo_url;
    },
    customer() {
      return this.listItem;
    },
    isMedic() {
      return this.customer.user_type === "nurse";
    },
  },
  methods: {
    customerAction(action) {
      if (action === "Add Job Card") {
        this.showAddJobCardDialog = !this.showAddJobCardDialog;
      } else if (action === "Add Order") {
        this.showAddOrderCard = !this.showAddOrderCard;
      } else if (action === "Delete Customer") {
        this.$store.commit('UPDATE_DELETE_CUSTOMER_DIALOG_STATUS', true)
      }
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0];
          this.dialog = !this.dialog;
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    async uploadProfilePic() {
      this.dialog = !this.dialog;
      if (!this.imageFile) {
        return;
      }

      const imageData = await utils.fileProcessor(
        this.customer.user_id,
        this.imageFile
      );
      if (size(imageData)) {
        let payload = {
          imageFile: imageData.data.image.data,
          imageName: imageData.data.image.filename,
          userId: this.customer.user_id,
          url: `/customer/${this.customer.user_id}`,
        };
        try {
          const response = await this.$store.dispatch("uploadPicture", payload);
          if (response) {
            this.$toast.success("Profile image set", {
              color: "success",
              queueable: true,
              timeout: 5000,
            });
          }
        } catch (e) {
          this.$toast.error("Error uploading nurse photo");
          throw Error(e);
        }
      }
    },
    sendMessage() {
      if (!this.customer.fcm_token) {
        this.$toast.warning(
          "Cannot send message to user without firebase token",
          {
            color: "warning",
            timeout: 3000,
          }
        );
        return;
      }
      this.showMessageDialog = !this.showMessageDialog;
    },
  },
};
</script>
<style scoped>
.profile {
  overflow-x: hidden;
}
.customer-data {
  height: auto;
  overflow-y: scroll;
}
</style>