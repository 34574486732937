export const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 2000,
  autoClose: true,
  windowTitle: window.document.title,
}

// styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.3.14/vuetify.min.css',
//     'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.3.14/vuetify.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css',
//     '../styles/order-detail.css',
//   ],
