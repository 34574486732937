<template>
  <v-col class="container">
    <v-row>
      <v-col cols="3">
        <v-card class="mx-auto">
          <v-img
            class="white--text align-end"
            height="200px"
            src="https://picsum.photos/350/165?random"
          >
            <v-card-title>Add new care tips</v-card-title>
          </v-img>
          <div class="compose">
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-container>
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-text-field
                    v-model="careTip.title"
                    :counter="50"
                    :rules="rules"
                    label="Title"
                    required
                  />

                  <v-text-field
                    v-model="careTip.subtitle"
                    :rules="rules"
                    :counter="100"
                    label="Sub title"
                    required
                  />
                  <span class="desc"
                    >Short and precise for push notification</span
                  >
                  <v-select
                    v-model="careTip.category"
                    :items="items"
                    label="Category"
                    hint="To which category does it apply"
                  />
                  <v-textarea
                    v-model="careTip.details"
                    :rules="rules"
                    counter
                    label="Description"
                    required
                  />

                  <v-btn color="error" class="mr-4" @click="reset">
                    Reset Form
                  </v-btn>

                  <v-btn class="mr-4" color="primary" @click="save">
                    Save tip
                  </v-btn>
                </v-form>
              </v-container>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <v-col cols="5" class="health-tips">
        <v-card cols="12">
          <template v-for="(item, index) in careTipsList">
            <v-list-item :key="index + 'C'" @click="getSelectedIdInfo(item)">
              <v-list-item-avatar>
                <v-avatar :color="color()" size="48">
                  <span class="white--text headline">{{
                    initials(item.title) | capitalize
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.subtitle }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip outlined :color="color()" label x-small>
                    {{ item.category }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  text
                  small
                  @click="push(item)"
                >
                  <v-btn icon color="pink">
                    {{ item.count }}
                    <v-icon x-small> mdi-bell-ring </v-icon>
                  </v-btn>
                  Push
                  <v-icon dark right> mdi-send </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index + 'C0'" />
          </template>
          <infinite-loading
            :identifier="identifier"
            :distance="3500"
            @infinite="loadData"
          >
            <div slot="spinner" class="md-layout md-alignment-top-center">
              <v-progress-circular indeterminate color="gray" />
            </div>
            <div slot="no-results" class="infinite-results-prompt">
              <v-chip class="ma-2">
                <span>No data available</span>
              </v-chip>
            </div>
            <div slot="no-more" class="infinite-no-more-prompt">
              <v-chip class="ma-2">
                <span>No more data available</span>
              </v-chip>
            </div>
          </infinite-loading>
        </v-card>
      </v-col>
      <!-- <v-col cols="4">
        <v-card class="mx-auto">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                Health Care Tips Stats
              </v-list-item-title>
              <v-list-item-subtitle
                >Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <v-row align="center">
              <v-col class="text-h2" cols="6"> 23&deg;C </v-col>
              <v-col cols="6">
                <v-img
                  src="https://cdn.vuetifyjs.com/images/cards/sun.png"
                  alt="Sunny image"
                  width="92"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-send</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>23 km/h</v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cloud-download</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>48%</v-list-item-subtitle>
          </v-list-item>

          <v-slider
            v-model="time"
            :max="6"
            :tick-labels="labels"
            class="mx-4"
            ticks
          ></v-slider>

          <v-list class="transparent">
            <v-list-item v-for="item in forecast" :key="item.day">
              <v-list-item-title>{{ item.day }}</v-list-item-title>

              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-subtitle class="text-right">
                {{ item.temp }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text> Full Report </v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as Utils from "@/helpers";
import InfiniteLoading from "vue-infinite-loading";
import infiniteIdentifier from "@/mixin/infiniteScrollId";

export default {
  name: "CareTips",

  components: {
    InfiniteLoading,
  },
  mixins: [infiniteIdentifier],

  data() {
    return {
      valid: true,
      rules: [(v) => !!v || "Field is required"],
      lazy: false,
      items: ["General", "Baby", "Pregnancy", "Postnatal", "Depression"],
      careTip: {
        title: "",
        subtitle: "",
        details: "",
        category: "",
        count: 0
      },
      shouldUpdate: false,
      labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
      time: 0,
      forecast: [
        {
          day: "Tuesday",
          icon: "mdi-white-balance-sunny",
          temp: "24\xB0/12\xB0",
        },
        {
          day: "Wednesday",
          icon: "mdi-white-balance-sunny",
          temp: "22\xB0/14\xB0",
        },
        { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
      ],
    };
  },
  computed: {
    ...mapGetters(["careTipsList"]),
  },
  methods: {
    ...mapActions(["loadCareTips", "saveHealthTip"]),

    async save() {
      if (!this.valid) {
        this.$toast.error("Please fill in the required fields");
        this.$refs.form.resetValidation();
        return;
      }
      if (this.shouldUpdate) {
        this.updateCount(this.careTip);
        return;
      }
      this.$toast.success("Saving health tip");

      try {
        const result = await this.saveHealthTip(this.careTip);
        
        if (result.status === 201) {
          this.$toast.success("Health tip saved successfully");
        }
        this.reset();
      } catch (err) {
        this.$toast.error("Error saving data.");
        throw Error(err);
      }
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async loadData($state) {
      try {
        await this.loadCareTips($state);
      } catch (err) {
        throw Error(err);
      }
    },
    getSelectedIdInfo(message) {
      this.$store.commit("UPDATE_SELECTED_ID", message.id);
      this.$store.commit("UPDATE_SELECTED_LIST_ITEM_DATA", message);
      Object.assign(this.careTip, message);
      this.careTip.id = message.id
      this.shouldUpdate = !this.shouldUpdate;
    },
    color() {
      return Utils.Helper.avarterColor();
    },
    initials(title) {
      return Utils.Helper.initialGenerate(title);
    },
    async push(item) {
      const payload = {
        category: "update",
        title: item.title,
        click_action: "com.tehecaapp.home.MainActivity",
        message: item.subtitle,
        label: "update",
        topic: "global",
        receiver: "global",
        messageType: "all",
      };
      const selectedMessage = Object.assign({}, item);
      try {
        const result = await this.$store.dispatch(
          "sendPushNotification",
          payload
        );
        if (result.status === 200) {
          this.$toast.success("Notification  sent successfully", {
            color: "primary",
            dismissable: true,
            queueable: true,
          });

          this.updateCount(selectedMessage);
        }
      } catch (err) {
        this.$toast.error("Error sending push notification");

        throw Error(err);
      }
    },
    async updateCount(item) {
      if (!this.shouldUpdate) {
        var c = item.count++;
        item.count = c;
      }
      try {
        const result = await this.$store.dispatch("updateCount", item);
        if (result.status === 200) {
          this.$toast.success("Update successful", {
            color: "blue",
            dismissable: true,
            queueable: true,
          });
        }
      } catch (err) {
        this.$toast.error("Error updating health tip");

        throw Error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.desc {
  position: relative;
  top: -5px;
  text-emphasis: none;
  color: gray;
}
.health-tips {
  height: 90vh;
  overflow: scroll;
}
</style>
