import { size } from 'lodash'
import { api } from '../../../utils/api'

/**
 * Calls the action to get shop data for
 * @method getShopOrder
 * @param {Object} rootState
 * @param {Object} payload
 * @param {Object} dispatch
 */
const getShopOrder = async (
  { state, rootState, dispatch, commit },
  payload
) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/portal/orders'
  const options = {
    params: {
      offset: size(state.orderList),
      limit: rootState.limitRequestSize,
      ...payload,
    },
  }

  try {
    const result = await api.get(url, options)
    if (result) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (error) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    if (error.response.data.reason) {
      dispatch('removeToken')
    }
    throw error
  }
}
/**
 * Create shop order profile
 * @param {*} param0
 * @param {*} payload
 */
const createOrder = async ({ commit, rootState }, payload) => {
  const url = '/portal/orders/'
  try {
    const result = await api.post(url, payload)
    if (result) {
      let data = [result.data]
      const list = [...rootState.dataListItems, ...data]
      commit('UPDATE_ORDER_LIST', list)
      return result
    }
  } catch (e) {
    throw e
  }
}
/**
 * Action to update the shop order
 * @param commit
 * @param rootState
 * @param payload
 * @returns {Promise<*>}
 */
const updateOrder = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/orders/${payload.id}`
  try {
    const result = await api.put(url, payload.data)
    if (result) {
      const order = result.data.data
      commit('UPDATE_SELECTED_ORDER', order)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}
/**
 * Get one shop order
 * @param commit
 * @param rootState
 * @returns {Promise<*>}
 */
const getOrder = async ({ commit, rootState }) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/orders/${rootState.selectedId}`
  try {
    const result = await api.get(url)
    if (result) {
      return result
    }
  } catch (e) {
    throw e
  }
}

const fetchSelectedOrder = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  if (payload != null) {
    const url = `/portal/orders/${payload.id}`
    try {
      const result = await api.get(url)
      if (result) {
        const order = result.data.data
        commit('UPDATE_SELECTED_ORDER', order)
        commit('UPDATE_LOADING_DATA_STATUS', false)
        return result
      }
    } catch (e) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      throw e
    }
  }
}

const fetchSelectedCustomer = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  if (payload != null) {
    const url = `/customer/${payload.id}`
    try {
      const result = await api.get(url)
      if (result) {
        const user = result.data.data
        commit('UPDATE_SELECTED_CUSTOMER', user)
        commit('UPDATE_LOADING_DATA_STATUS', false)
        return result
      }
    } catch (e) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      throw e
    }
  }
}
export {
  getShopOrder,
  createOrder,
  updateOrder,
  getOrder,
  fetchSelectedOrder,
  fetchSelectedCustomer,
}
