import {
    setDateFormat,
    formatPhoneNumber,
    capitalize
} from './filters'

export default {
    install (Vue) {
        Vue.filter('setDateFormat', setDateFormat)
        Vue.filter('formatPhoneNumber', formatPhoneNumber)
        Vue.filter('capitalize', capitalize)
    }
}
