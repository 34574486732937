<template>
  <v-dialog v-model="orderDialog" width="600px" persistent>
    <v-card>
      <v-card-title class="primary">
        <p class="white--text">Customer Income Trace</p>
      </v-card-title>
      <v-container>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-row class="mx-2">
            <v-col cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="order.txn_date"
                    label="Order date"
                    :rules="fieldRules"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="order.txn_date"
                  date-format="DD-MM-YYYY"
                  @input="menu = false"
                />
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="order.product"
                :items="products"
                :rules="fieldRules"
                label="Product/Service"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="order.lead_source"
                :items="source"
                :rules="fieldRules"
                label="Lead source"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="order.station"
                :items="station"
                :rules="fieldRules"
                label="Station"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="order.payment_method"
                :items="methods"
                :rules="fieldRules"
                label="Payment method"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="order.status"
                :items="status"
                :rules="fieldRules"
                label="Payment status"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="order.amount"
                label="Order amount"
                type="number"
                :rules="fieldRules"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="order.doc_id"
                label="Reciept number"
                type="text"
                :rules="fieldRules"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="order.summary"
                label="Naration"
                type="text"
                :messages="['Optional, special notes about this order']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions right>
        <v-btn text @click="handleClose()"> Close </v-btn>
        <v-btn v-if="isEditing" text color="orange" @click="handleUpdate">
          Update
        </v-btn>
        <v-btn v-else text color="primary" @click="handleSave"> Save </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="getLoadingState" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Please wait...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "AddOrderCard",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lazy: false,
      valid: false,
      menu: false,
      orderDialog: false,
      fieldRules: [(v) => !!v || "Field is required"],
      products: [
        "Prepared mama",
        "Patient care",
        "Postnatal care",
        "Value kit",
        "Teheca shop",
      ],
      source: [
        "Teheca App",
        "Facebook",
        "WhatsApp",
        "Referal",
        "SafeBoda",
        "Jumia",
        "Returning",
        "Website",
      ],
      station: [
        "Kikubo",
        "Mutungo",
        "Head Office",
        "Nassana",
        "Sendy",
        "Jumia",
      ],
      methods: [
        "Bank Deposit",
        "Airtel Account",
        "Mobile Money",
        "Beyonic",
        "SafeBoda",
        "Cheque",
        "Airtel Account",
        "Cash",
      ],
      status: ["Paid", "UnPaid", "Pending"],
      order: {
        product: "",
        station: "",
        amount: 0,
        doc_id: "",
        lead_source: "",
        payment_method: "",
        summary: "",
        txn_date: "",
        user_id: "",
        status: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getLoadingState", "getCurrentUser", "listItem"]),

  },
  watch: {
    value: function (val) {
      if (this.isEditing) {
        this.order = Object.assign({}, this.payload);
        this.formatServerDate();
      }
      this.orderDialog = val
    },
  },
  methods: {
    formatServerDate() {
      this.order.txn_date = this.order.txn_date
        ? moment(new Date(this.order.txn_date)).format("YYYY-MM-DD")
        : moment();
    },
    async handleSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.order.created_by = this.getCurrentUser.id;
        this.order.user_id = this.listItem.id;
        const result = await this.$store.dispatch(
          "createCustomerOrder",
          this.order
        );
        if (result.data.status === "success") {
          this.$toast.success("Order successfully created", {
            color: "primary",
            queueable: true,
            timeout: 5000,
          });

          this.handleClose();
        } else if (response.data.status === "failed") {
          this.$toast.error(response.data.error);
        }
      } catch (e) {
        this.$toast.error("An error occurred, please try again");
      }
    },
    async handleUpdate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        const result = await this.$store.dispatch(
          "updateCustomerOrder",
          this.order
        );
        if (result.data.status === "success") {
          this.$toast.success("Order successfully updated", {
            color: "primary",
            queueable: true,
            timeout: 5000,
          });

          this.handleClose();
        } else if (response.data.status === "failed") {
          this.$toast.error(response.data.error);
        }
      } catch (e) {
        this.$toast.error("An error occurred, please try again");
      }
    },
    handleClose() {
      this.orderDialog = false;
    },
  },
};
</script>

<style></style>