<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <v-card>
      <v-card-title class="primary">
        <p class="white--text">
          {{ jobTitle }}
        </p>
      </v-card-title>
      <v-container>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-row class="mx-2">
            <v-col cols="12">
              <h3>Choose service package</h3>
              <v-radio-group v-model="jobCard.service" :rules="nameRules" row>
                <v-radio label="Postnatal Care " value="Postnatal Care" />
                <v-radio label="In home care" value="In home care" />
                <v-radio label="In hospital care" value="In hospital care" />
                <v-radio label="Checkup" value="checkup" />
              </v-radio-group>
            </v-col>

            <v-col cols="6">
              <v-select v-model="jobCard.relation" :items="relation" attach chips item-color="primary" :light="true"
                single label="Relationship to patient" @change="onPhysioChange"
                prepend-icon="mdi-human-greeting-proximity" />
            </v-col>
            <v-col cols="6">
              <v-select v-model="jobCard.age_group" :items="ageGroup" attach chips :flat="true" item-color="primary"
                :light="true" single label="Patient's Age group" @change="onPhysioChange" />
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="jobCard.gender" :rules="nameRules" row label="Patient's Gender">
                <v-radio label="Male " value="Male" />
                <v-radio label="Femal" value="Femal" />
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="jobCard.start_date" label="Booking Date * " prepend-icon="mdi-calendar-today"
                    :rules="nameRules" readonly v-on="on" />
                </template>
                <v-date-picker v-model="jobCard.start_date" date-format="DD-MM-YYYY" @input="menu = false" />
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="jobCard.facility" label="Facility" prepend-icon="mdi-pencil" :rules="nameRules"
                :messages="[
                  'Hospital name, organisation or home',
                ]" />
            </v-col>


            <v-col v-if="!isEditing" cols="6">
              <v-select v-model="jobCard.status" :items="statusItems" label="Job Status" prepend-icon="mdi-list-status" />
            </v-col>
            <v-col cols="6">
              <v-combobox v-model="jobCard.medical_condition" :items="diseases" single attach chips :flat="true"
                :rules="nameRules" prepend-icon="mdi-view-list" item-color="primary" :light="true"
                label="Main Patient Medical Disease" @change="onPhysioChange" />
            </v-col>
            <v-col cols="6">
              <v-select v-model="jobCard.pysio_services" :items="physio" attach chips :flat="true" item-color="primary"
                :light="true" :clearable="clearable" :deletable-chips="clearableChips" multiple
                label="Physiotherapy Activities" @change="onPhysioChange" prepend-icon="mdi-view-list" />
            </v-col>
            <v-col cols="12">
              <v-select v-model="jobCard.care_services" :items="pbsc" attach chips :flat="true" item-color="primary"
                :light="true" :clearable="clearable" :deletable-chips="clearableChips" multiple
                label="Expected Bedside Care Tasks" prepend-icon="mdi-view-list" />
            </v-col>

            <!-- <v-col cols="12">
              <v-textarea
                v-model="jobCard.expectations"
                label="Care Description"
                type="text"
                :messages="[
                  'Describe what the client expects from the service, be as detailed as possible',
                ]"
                prepend-icon="mdi-pencil"
              />
            </v-col> -->
            <!-- <v-col cols="12">
              <v-textarea
                v-model="jobCard.condition"
                label="Clients Condition"
                type="text"
                :messages="[
                  'Medical condition or health condition, be as detailed as possible',
                ]"
                prepend-icon="mdi-pencil"
              ></v-textarea>
            </v-col> -->
            <v-col cols="12">
              <v-textarea v-model="jobCard.thc_description" label="Care Narration" type="text" :messages="[
                'Describe what you will be doing for the client, be as detailed as possible',
              ]" prepend-icon="mdi-pencil" />
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="jobCard.description" label="Any special medical care instructions"
                prepend-icon="mdi-pencil" />
            </v-col>
            <!-- <v-col cols="6">
              <h3>Have Doctor/Hospital?</h3>
              <v-radio-group
                v-model="jobCard.has_doctor"
                :rules="nameRules"
                row
              >
                <v-radio label="Yes" value="yes" />
                <v-radio label="No" value="no" />
              </v-radio-group>
            </v-col> -->
            <v-col cols="12">
              <vuetify-google-autocomplete :enable-geolocation="true" :place-name="true" :required="true"
                :rules="nameRules" @placechanged="getAddressData" country="UG" id="address" label="Patient's adddress"
                placeholder="Start typing" prepend-icon="mdi-map-marker" types="address" :messages="[
                  'Where the service will be offered from',
                ]" v-model="jobCard.address" />
            </v-col>

            <v-col cols="12">
              <v-card class="px-4">
                <v-card-subtitle>
                  <h3>Care Finance *</h3>
                </v-card-subtitle>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="jobCard.invoice_number" label="Invoice Number" prepend-icon="mdi-pencil"
                      type="number" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="jobCard.invoice_amount" label="Invoice Amount" prepend-icon="mdi-currency-usd"
                      type="number" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="jobCard.receipt_number" label="Receipt Number" prepend-icon="mdi-pencil"
                      type="number" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="jobCard.receipt_amount" label="Receipt Amount" prepend-icon="mdi-currency-usd"
                      type="number" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols-lg="6" class="ml-4">
                    <v-text-field v-model="jobCard.days" label="Days" type="number" />
                  </v-col>
                  <v-col cols-lg="6">
                    <v-text-field v-model="jobCard.nights" label="Nights" type="number" />
                  </v-col>
                  <v-col cols="12">
                    <v-menu v-model="endmenu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="jobCard.end_date" label="End Date" prepend-icon="mdi-calendar-today"
                          readonly v-on="on" />
                      </template>
                      <v-date-picker v-model="jobCard.end_date" date-format="DD-MM-YYYY" @input="menu = false" />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
<v-col></v-col>
          </v-row>
        </v-form>
      </v-container>
      
      <v-divider />
      <v-card-actions>
        <v-btn text @click="dialog = false"> Close </v-btn>
        <v-btn v-if="isEditing" text color="orange" @click="handleJobUpdate">
          Update
        </v-btn>
        <v-btn v-else text color="primary" @click="handleSave"> Save </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="getLoadingState" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Please wait...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AddJobCard",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    job: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      lazy: false,
      valid: false,
      clearable: true,
      menu: false,
      endmenu: false,
      isSaving: false,
      checked: false,
      clearableChips: true,
      medicationChecked: false,
      physiotherapy: false,
      daySelected: false,
      nightSelected: false,
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      nameRules: [(v) => !!v || "Field is required"],
      phoneRules: [(v) => !!v || "Phone number is required"],
      relation: [
        "Spouse",
        "Wife",
        "Husband",
        "Partner",
        "Friend",
        "Uncle",
        "Aunt",
        "Mother",
        "Father",
        "Niece",
        "Nephew",
        "Formyself",
        "Brother",
        "Sister",
        "Others"
      ],
      ageGroup: [
        "0-1",
        "1-5",
        "5-12",
        "12-18",
        "18-25",
        "25-30",
        "30-40",
        "40-50",
        "Above 50",
      ],
      diseases: ["Cancer (breast, cervical, prostate, liver, etc.)", "HIV/AIDS", "Tuberculosis", "Diabetes", "Hypertension", "Cardiovascular diseases", "Respiratory infections", "Chronic obstructive pulmonary disease (COPD)", "Malnutrition", "Pneumonia", "Mental Health Disorders", "Stroke", "Asthma", "Malaria", "Post Surgery", "Fractures", "C-section", "Premature", "Normal delivery", "Others(include in care narrative)"],
      jobCard: {
        phonenumber: "",
        start_date: "",
        end_date: "",
        patient_name: "",
        care_services: [],
        medication_application: false,
        description: "",
        pysio_services: [],
        service: "",
        address: " ",
        latitude: 0.0,
        longitude: 0.0,
        invoice_amount: "",
        receipt_amount: "",
        days: 0,
        nights: 0,
        shifts: [],
        expectations: "",
        thc_description: "",
        condition: "",
        duration: 0,
        relation: "",
        age_group: "",
        has_doctor: "",
        facility: "",
        medical_condition: "",
        receipt_number: null,
        invoice_number: null
      },
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedJob",
      "listItem",
      "getLoadingState",
      "isInternetConnected",
      "getCurrentAdmin",
    ]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    saving: {
      get() {
        return this.isSaving;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    jobTitle: {
      get() {
        return this.title;
      },
      set(title) {
        this.$emit("input", title);
      },
    },
    submitAction: {
      get() {
        return this.isEditing;
      },
      set(isEditing) {
        this.$emit("input", isEditing);
      },
    },
    pbsc() {
      return [
        "All Services",
        "Monitoring vital signs (temperature, blood pressure, heart rate, respiratory rate)",
        "Administering medications",
        "Assisting with patient hygiene (bathing, grooming, oral care)",
        "Changing wound dressings",
        "Assisting with mobility and transferring patients",
        "Collecting specimens for laboratory testing (blood, urine, etc.)",
        "Assisting with feeding and nutrition support",
        "Providing emotional support and comfort to patients",
        "Documenting patient information and maintaining accurate records",
        "Collaborating with the healthcare team for patient care planning and coordination",
        "Assisting with catheter insertion and care",
        "Monitoring fluid intake and output",
        "Assisting with pain management",
        "Assisting with respiratory therapy (oxygen administration, inhalation treatments)",
        "Performing sterile procedures (catheterizations, wound irrigation)",
        "Monitoring and managing intravenous (IV) lines",
        "Assisting with patient positioning and turning",
        "Conducting patient assessments",
        "Assisting with diagnostic procedures (X-rays, ultrasounds)",
        "Assisting with the administration of blood transfusions",
        "Providing education to patients and their families",
        "Assisting with end-of-life care and supporting patients and families emotionally",
        "Performing nasogastric tube insertion and management",
        "Monitoring and managing nasogastric tube feeding",
        "Assisting with tracheostomy care",
        "Administering oxygen therapy",
        "Performing cardiopulmonary resuscitation (CPR)",
        "Assisting with wound debridement",
        "Assisting with the use of medical equipment (ventilators, cardiac monitors)",
        "Assisting with surgical procedures and pre- and post-operative care",
        "Conducting neurological assessments (Glasgow Coma Scale, pupillary response)",
        "Assisting with the management of diabetes (insulin administration, glucose monitoring)",
        "Providing emotional support and counseling to patients and families",
        "Assisting with the management of seizures (administering anti-seizure medications)",
        "Assisting with the care of patients on ventilators",
        "Assisting with chest tube management",
        "Monitoring and managing epidural catheters",
        "Assisting with fecal management systems (colostomy/ileostomy care)",
        "Assisting with the management of intracranial pressure (ICP)",
        "Assisting with the insertion and care of peripherally inserted central catheters (PICC lines)",
        "Assisting with the care of patients with infectious diseases (isolation precautions)",
        "Providing wound care and dressing changes for pressure ulcers",
        "Assisting with the management of cardiac conditions (administering cardiac medications, monitoring EKGs)",
        "Performing phlebotomy for blood sampling",
        "Assisting with the management of respiratory conditions (administering nebulizer treatments)",
        "Assisting with the care of patients with mental health conditions",
        "Monitoring and managing urinary catheters",
        "Assisting with patient transportation within the healthcare facility",
        "Providing support and education to breastfeeding mothers",
        "Assisting with the care of newborns and infants in the neonatal intensive care unit (NICU)",
        "Others(Explain in care narration)"
      ];
    },
    physio() {
      return ["Exercising", "Walking", "Stretching", "Massaging"];
    },
    statusItems() {
      return ["pending", "ongoing", "canceled"];
    },
    shiftList() {
      return ["Day", "Night"];
    },
    customerId() {
      return this.listItem.id;
    },
    customerName() {
      return `${this.listItem.firstname} ${this.listItem.lastname}`;
    },

  },
  watch: {
    dialog: function () {
      this.jobCard = Object.assign({}, this.job);
      if (this.isEditing) {
        this.getDataFromServer();
      }
    },
  },
  created() {
    if (this.job) {
      this.medicationChecked = this.job.medication_application;
    }
  },

  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.jobCard.address = addressData.name;
      this.jobCard.latitude = addressData.latitude;
      this.jobCard.longitude = addressData.longitude;
    },
    getDataFromServer() {
      this.jobCard.start_date = this.job.start_date

      this.jobCard.end_date = this.job.end_date

    },
    async handleSave() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Some fields are not filled");
        return;
      }

      if (this.customerId) {
        this.saving = !this.saving;

        if (this.isInternetConnected) {
          try {
            const payload = {
              ...this.jobCard,
              client_name: this.customerName,
              contact: this.listItem.phonenumber,
              medication_application: this.checked,
              pysiotherapy: this.physiotherapy,
              status: "pending",
              invoice_amount: parseFloat(this.jobCard.invoice_amount),
              receipt_amount: parseFloat(this.jobCard.receipt_amount),
              UserId: this.customerId,
              DashboardUserId: this.getCurrentAdmin.id,
              created_by: this.getCurrentAdmin.id,
              requested_via: "portal",
              requested_by: this.jobCard.relation,
              has_doctor: this.jobCard.has_doctor == "yes" ? true : false,
              duration: this.jobCard.days,
            };

            const response = await this.$store.dispatch(
              "createJobCard",
              payload
            );
            if (response.data.status === "success") {
              this.$toast.success("Job card successfully created", {
                color: "primary",
                queueable: true,
                timeout: 5000,
              });

              this.$store.dispatch("getCustomerJobList", {
                id: this.customerId,
              });
              this.dialog = !this.dialog;
              this.isSaving = false;
            } else if (response.data.status === "failed") {
              this.$toast.error(response.data.error);
            }
          } catch (e) {
            this.$toast.error(e.response.data.error);
          }
        } else {
          this.$toast.error("No internet connection");
        }
      }
    },
    async handleJobUpdate() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Some fields are not filled");
      }

      const payload = {
        id: this.getSelectedJob.id,
        data: { ...this.jobCard },
      };

      if (this.isInternetConnected) {
        try {
          const result = await this.$store.dispatch("updateJobCard", payload);
          if (result.data.status === "success") {
            this.dialog = !this.dialog;
            this.isSaving = false;
            return result;
          } else if (result.data.status === "failed") {
            this.$toast.error(response.data.error);
          }
        } catch (e) {
          this.$toast.error(e.response.data.error);
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
    captureValue(value) {
      this.checked = value !== null;
    },
    onPhysioChange(value) {
      this.physiotherapy = value.length > 0;
    },
    onShiftChange(value) {
      if (value.length > 0) {
        this.daySelected = !!value.includes("Day");
        this.nightSelected = !!value.includes("Night");
      }
    },
  },
};
</script>
