/**
 * Mutation to update the list of booking
 * @param state
 * @param payload
 */
const UPDATE_BOOKING_LIST = (state, payload) => {
    if (payload.status === 'active') {
        state.activeBookings = payload.bookings
    }
    if (payload.status === 'new') {
        state.newBookings = payload.bookings
    }
    if (payload.status === 'completed') {
        state.completedBookings = payload.bookings
    }
}

export {
    UPDATE_BOOKING_LIST
}
