<template>
  <v-card class="main">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-text-field v-model="search" label="Search" single-line hide-details />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="bookingItems"
      class="elevation-2 data"
      :search="search"
      :server-items-length="total"
      :loading="showLoading"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-btn text small :color="getColor(item.status)">
          {{ item.status }}
        </v-btn>
      </template>

      <template v-slot:[`item.clientStatus`]="{ item }">
        <v-btn text small :color="getColor(item.clientStatus)">
          {{ item.clientStatus }}
        </v-btn>
      </template>
      <template v-slot:[`item.serviceStartDate`]="{ item }">
        <p class="text-justify">
          {{ item.serviceStartDate }}
        </p>
      </template>
      <template v-slot:[`item.motherNames`]="{ item }">
        <p class="text-justify">
          {{ item.motherNames }}
        </p>
      </template>

      <template v-slot:[`item.packagePrice`]="{ item }" class="amount">
        <p class="text-justify">
          {{ formatAmount(item.packagePrice) }}
        </p>
      </template>
      <template v-slot:[`item.process`]="{ item }">
        <v-btn small color="primary" @click="getOrderDetails(item)">
          Details
        </v-btn>
      </template>
    </v-data-table>
    <booking-dialog />
  </v-card>
</template>
<script>
import BookingDialogs from "../section/BookingDialog";
export default {
  name: "DataTables",
  components: {
    "booking-dialog": BookingDialogs,
  },
  props: {
    totalBookings: {
      type: Number,
    },
    requests: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Care Request",
    },
  },
  data() {
    return {
      search: "",
      showDialog: false,
      headers: [
        {
          text: "Names",
          value: `client`,
        },

        {
          text: "Phonenumber",
          value: "primaryPhoneNumber",
        },

        {
          text: "Service package",
          align: "left",
          sortable: false,
          value: "service",
        },
        {
          text: "Service date",
          value: "serviceStartDate",
        },
        {
          text: "Amount(Ugx)",
          value: "totalCost",
        },
        {
          text: "Service address",
          value: "dropoff",
        },
        {
          text: "Order state",
          value: "status",
        },
        {
          text: "Client state",
          value: "clientStatus",
        },
        { text: "Manage", value: "process", sortable: false },
      ],
    };
  },
  computed: {
    total: {
      get() {
        return this.totalBookings;
      },
      set(totalBookings) {
        return totalBookings;
      },
    },
    bookingItems() {
      return this.requests;
    },
    showLoading() {
      return this.loading;
    },
  },
  methods: {
    getColor(value) {
      if (value === "Cancelled") return "red";
      else if (value === "Pending") return "orange";
      else if (value === "Request") return "blue";
      else return "green";
    },
    getOrderDetails(booking) {
      this.$store.commit("UPDATE_SELECTED_BOOKING", booking);
      this.$store.commit("SHOW_DIALOG", true);
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  .text-justify {
    position: relative;
    top: 5px;
  }
  .amount {
    font-style: bold;
  }
  .v-btn {
    margin-left: -15px;
  }
}
</style>
