<template>
  <v-card class="order-card">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="incomeTrace"
      class="elevation-2"
      :search="search"
      :loading="getLoadingState"
      :itemsPerPage="5"
      loading-text="Loading orders cards... Please wait"
    >
      <template v-slot:item.txn_date="{ item }">
        <p class="mt-4">
          {{ item.txn_date | setDateFormat("DD-MM-YYYY") }}
        </p>
      </template>

      <template v-slot:item.station="{ item }">
        <v-btn outlined x-small :color="getColor(item.station)">
          {{ item.station }}
        </v-btn>
      </template>
      <template v-slot:item.lead_source="{ item }">
        <v-btn x-small outlined depressed :color="leadSource(item.lead_source)">
          {{ item.lead_source }}
        </v-btn>
      </template>
      <template v-slot:item.amount="{ item }">
        <v-btn text color="black" class="special-font">
          {{ formatAmount(item.amount) }}
        </v-btn>
      </template>

      <template v-slot:item.status="{ item }">
        <v-btn
          outlined
          x-small
          :color="paymentColor(item.status)"
          class="special-font"
        >
          {{ item.status }}
        </v-btn>
      </template>

      <template v-slot:item.product="{ item }">
        <v-btn
          x-small
          outlined
          :color="productColor(item.product)"
          class="special-font"
        >
          {{ item.product }}
        </v-btn>
      </template>

      <template v-slot:item.process="{ item }">
        <v-btn
          x-small
          outlined
          color="black"
          @click="manage(item)"
          class="special-font"
        >
          <v-icon left>mdi-pencil </v-icon>
          Edit
        </v-btn>
      </template>
    </v-data-table>
    <add-order-card
      :isEditing="isEditing"
      :value="showOrderDialog"
      :payload="selectedOrder"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, formatCurrency } from "../../../helpers";
import AddOrderCard from "./AddOrderCard.vue";

export default {
  name: "CustomerOrder",
  components: {
    AddOrderCard,
  },
  props: {
    title: {
      type: String,
      default: "Income trace",
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 5,
      showOrderDialog: false,
      headers: [
        {
          text: "Date of transaction",
          value: "txn_date",
          sortable: true,
        },

        {
          text: "Reciept No",
          value: "doc_id",
          sortable: true,
        },

        {
          text: "Product",
          value: "product",
        },
        {
          text: "Station",
          value: "station",
        },
        {
          text: "Source",
          value: "lead_source",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Pay Method",
          value: "payment_method",
        },
        {
          text: "Status",
          value: "status",
        },
        { text: "Manage", value: "process", sortable: false },
      ],
      isEditing: false,
      showAddReview: false,
      orderPayload: {},
    };
  },
  computed: {
    ...mapGetters(["getLoadingState"]),
    incomeTrace() {
      return this.data;
    },
    length() {
      return Math.ceil(this.count / this.itemsPerPage);
    },
    selectedOrder() {
      return this.orderPayload;
    },
  },
  methods: {
    fetchPrevious() {
      this.page = this.page--;
    },
    fetchNext() {
      this.page = this.page++;
    },

    paymentColor(status) {
      if (status === "paid") return "light-green";
      else if (status === "unpaid") return "red";
      else return "orange";
    },
    getColor(value) {
      const item = value.toLowerCase();
      if (item == "kikubo") return "green";
      else if (item == "mutungo") return "pink";
      else if (item == "head office") return "blue";
      else if (item == "sendy") return "black";
      else if (item == "jumia") return "orange";
      else return "orange";
    },
    leadSource(value) {
      const item = value.toLowerCase();
      if (item == "facebook") return "blue";
      else if (item == "whatsapp") return "teal";
      else if (item == "refferal") return "pink";
      else if (item == "safeboda" || item == "safeboda") return "orange";
      else return "orange";
    },
    productColor(value) {
      const item = value.toUpperCase();
      if (item == "VALUE KIT") return "green";
      else if (item == "PREPARED MAMA") return "navy-blue";
      else if (item == "POSTNATAL CARE") return "purple";
      else if (item == "PATIENT CARE") return "cyan";
      else if (item == "SHOP") return "red";
      else return "orange";
    },
    formatDate(value) {
      return formatDate(value);
    },
    formatAmount(amount) {
      return formatCurrency(amount);
    },
    manage(row) {
      this.isEditing = !this.isEditing;
      this.showOrderDialog = !this.showOrderDialog;
      this.orderPayload = row;
    },
    pageCount(event) {
      return this.count;
    },
  },
};
</script>

<style></style>