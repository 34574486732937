import moment from 'moment'
import { formatLocalTime } from './localTimeFormatter'
import { Helper } from './helper'
import { fileProcessor } from './fileProcessor'
import * as orderStates from './orderStates'

const formatCurrency = amount => amount ? `UGX ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'UGX missing'

const formatDate = (date) => {
    if (!date) {
        return new Date().format('m-d-Y h:i:s')
    }
    return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
}

export {
    formatLocalTime,
    Helper,
    fileProcessor,
    formatCurrency,
    orderStates,
    formatDate
}
