<template>
  <div>
    <core-toolbar />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { AppBar } from "../components/core";

export default {
  name: "Index",
  components: {
    "core-toolbar": AppBar,
  },
  data() {
    return {
      msg: "Index",
    };
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding-top: 2%;
  padding-left: -2.5%;
}
</style>
