/**
 * Hold customer list
 * @param {Object} state
 * @param {Array} payload
 */
const UPDATE_CUSTOMER_LIST = (state, payload) => {
    state.customerList = payload
}
/**
 * Updates the customer income trace entry
 * @param {Object} state 
 * @param {Array} payload 
 */
const UPDATE_CUSTOMER_ORDERS = (state, payload) => {
    state.customerOrders = payload
}
/**
 * updates the number of customer order 
 * @param {OBJECT} state 
 * @param {NUMBER} payload 
 */
const UPDATE_ORDER_COUNT = (state, payload) => {
    state.customerOrderCount = payload
}

const UPDATE_DELETE_CUSTOMER_DIALOG_STATUS = (state, payload) => {
    state.deleteCustomerDialogStatus = payload
}

const ACTIVE_NURSE_APP = (state, payload) => {
    state.nurseActiveTab = payload
}
export {
    UPDATE_CUSTOMER_LIST,
    UPDATE_CUSTOMER_ORDERS,
    UPDATE_ORDER_COUNT,
    UPDATE_DELETE_CUSTOMER_DIALOG_STATUS,
    ACTIVE_NURSE_APP

}
