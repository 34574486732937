/* eslint-disable no-unused-vars */
import { size } from 'lodash'
import { api } from '@/utils/api'

/**
 * Calls the action to get user data for nurses
 * @param {*} param0
 * @param {*} payload
 */
const getNurseLists = async ({ rootState, dispatch }, payload) => {
  const config = {
    config: {
      offset: size(rootState.dataListItems),
      limit: rootState.limitRequestSize,
    },
    url: 'careteam',
    ...payload,
  }
  await dispatch('getListItems', config)
}

const getApprovedNurseList = async ({ rootState, dispatch }, payload) => {
  const config = {
    config: {
      offset: size(rootState.dataListItems),
      limit: rootState.limitRequestSize,
    },
    url: 'careteam?status=true',
    ...payload,
  }
  await dispatch('getListItems', config)
}

const getUnapprovedNurseList = async ({ rootState, dispatch }, payload) => {
  const config = {
    config: {
      offset: size(rootState.dataListItems),
      limit: rootState.limitRequestSize,
    },
    url: 'careteam?status=false',
    approved: false,
    ...payload,
  }
  await dispatch('getListItems', config)
}

/**
 * Get firebase nrses
 * @param {*} param0
 */
const getFireBaseNurses = async ({ commit, rootState }) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/nurses'
  try {
    const result = await api.get(url)
    if (result) {
      let list = [...rootState.dataListItems, ...result.data.data]
      commit('UPDATE_DATA_LIST_ITEMS', list)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const getAllNurses = async ({ commit }) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/careteam'
  try {
    const result = await api.get(url)
    if (result) {
      const list = result.data.data
      commit('UPDATE_LOADING_DATA_STATUS', false)
      commit('UPDATE_NURSE_LIST', list)
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

/**
 * Create nurse profiles
 * @param {*} param0
 * @param {*} payload
 */
const createNurseProfile = async ({ commit, rootState }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/careteam'
  const workUrl = '/nurse/work_history/'
  const academicUrl = '/nurse/academic_history/'

  const workInfo = {}
  const academicInfo ={}
  try {
    const result = await api.post(url, payload)

    if (result) {
     
      workInfo.nurseId = result.data.data.id
      academicInfo.nurseId = result.data.data.id
      workInfo.user_id = result.data.data.user_id
      academicInfo.user_id = result.data.data.user_id
      // const response = await api.post(workUrl + workInfo.user_id, workInfo)
      // const res = await api.post( academicUrl + academicInfo.user_id, academicInfo)
      let data = [result.data.data]
      const list = [...rootState.dataListItems, ...data]
      commit('UPDATE_DATA_LIST_ITEMS', list)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

// save ac

/**
 * Action to update the nurse profile
 * @param commit
 * @param rootState
 * @param payload
 * @returns {Promise<*>}
 */
const updateNurseProfile = async ({ commit, rootState, dispatch }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/careteam/${rootState.selectedId}`
  let body = { ...payload, firebaseId: rootState.listItem.firebaseId }
  try {
    const result = await api.put(url, body)
    if (result) {
      const { dataType } = result.data
      commit('UPDATE_SELECTED_LIST_ITEM_DATA', dataType)

      if (rootState.fetchApproved === true) {
        dispatch('getApprovedNurseList')
      } else {
        dispatch('getUnapprovedNurseList')
      }

      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}
/**
 *
 * @param {*} param0
 * @param {*} payload
 * @returns
 */
const updateNurseStatus = async ({ commit, rootState, dispatch }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = rootState.selectedId
    ? `/careteam/${rootState.selectedId}/approve`
    : `/careteam/${payload.profileId}/approve`
  let body = {
    ...payload,
    firebaseId: rootState.listItem.firebaseId,
    phone_number: rootState.listItem.phone_number,
    email: rootState.listItem.email,
  }
  try {
    const result = await api.put(url, body)
    if (result) {
      const { dataType } = result.data
      commit('UPDATE_SELECTED_LIST_ITEM_DATA', dataType)

      if (rootState.fetchApproved === true) {
        dispatch('getApprovedNurseList')
      } else {
        dispatch('getUnapprovedNurseList')
      }

      commit('UPDATE_LOADING_DATA_STATUS', false)

      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

/**
 * Get one nurse
 * @param commit
 * @param rootState
 * @returns {Promise<*>}
 */
const getNurseDetails = async ({ commit, rootState }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)

  const url = rootState.selectedId
    ? `/careteam/${rootState.selectedId}`
    : `/careteam/${payload}`
  try {
    const result = await api.get(url)
    if (result) {
      let { data } = result
      commit('UPDATE_SELECTED_LIST_ITEM_DATA', data.data)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const createNurseJob = async ({ state, commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/portal/jobcard/nurse'
  try {
    const result = await api.post(url, payload)
    if (result) {
      let data = [result.data]
      const list = [...state.nurseJobList, ...data]
      commit('UPDATE_NURSE_JOB_LIST', list)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const getNurseJobs = async ({ state, rootState, commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/nurse/${payload.id}`
  const options = {
    params: {
      offset: size(state.nurseJobList),
      limit: rootState.limitRequestSize,
    },
  }

  try {
    const result = await api.get(url, options)
    if (result) {
      const { data } = result.data

      commit('UPDATE_NURSE_JOB_LIST', data)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const updateNurseJob = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/nurse/${payload.id}`

  try {
    const result = await api.put(url, payload.data)
    if (result) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const fetchNurseReviews = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/review/${payload}/nurse`

  try {
    const result = await api.get(url)
    if (size(result.data)) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      commit('UPDATE_NURSE_REVIEWS', result.data.data)

      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const searchCustomer = async ({}, payload) => {
  const options = {
    params: payload,
  }

  try {
    return await api.get('/portal/search', options)
  } catch (e) {
    throw e
  }
}

const saveReview = async ({ state, commit }, payload) => {
  try {
    const result = await api.post('/review', payload)
    if (result.status === 'success') {
      const { data } = result.data
      const reviews = [...state.nurseReviews, [...data]]
      commit('UPDATE_NURSE_REVIEWS', reviews)
    }
    return result
  } catch (e) {
    throw e
  }
}


/**
 * This action deletes the 
 * @param {*} param0 
 * @param {*} payload 
 */
const deleteNurse = async ({ commit, rootState }, payload) => {
  try {
    return await api.delete(`/resume/profile/${payload.id}`)

  } catch (error) {
    throw Error(error)
  }

}

export {
  getNurseLists,
  createNurseProfile,
  updateNurseProfile,
  getNurseDetails,
  getFireBaseNurses,
  getAllNurses,
  createNurseJob,
  getNurseJobs,
  updateNurseJob,
  updateNurseStatus,
  getApprovedNurseList,
  getUnapprovedNurseList,
  fetchNurseReviews,
  searchCustomer,
  saveReview,
  deleteNurse
}
