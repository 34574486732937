
const getMessageList = state => state.messageList

/**
 * Hold the current list of care tips
 * @param {*} status
 */
const careTipsList = state => state.careTipsLists

export {
    getMessageList,
    careTipsList
}
