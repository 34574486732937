const state = {
  confirmedOrders: [],
  receivedOrders: [],
  pendingOrders: [],
  cancelledOrders: [],
  selectedOrder: {},
  selectedCustomer: {},
}

export { state }
