/*eslint no-unused-vars: "error"*/
import { api } from '@/utils/api'
import { size } from 'lodash'

/**
 * Update the selected booking
 * @param payload
 * @returns {Promise<void>}
 */
const updateBooking = async (payload) => {
  try {
    const result = await api.put(`/booking/${payload.docId}`, payload)
    if (result) {
      return result
    }
  } catch (error) {
    throw error
  }
}

/**
 * Calls the action to get booking data
 * @param {*} param0
 * @param {*} payload
 */
const getBookings = async ({ state, rootState, dispatch }, payload) => {
  const url = '/booking'
  const options = {
    params: {
      offset: size(state.bookingsList),
      limit: rootState.limitRequestSize,
      ...payload,
    },
  }
  try {
    const result = await api.get(url, options)
    if (result) {
      // const data = result.data.data
      // const list = [...state.bookingsList, ...data]
      // commit('UPDATE_BOOKING_LIST', list)
      return result
    }
  } catch (error) {
    if (error.response.data.reason) {
      dispatch('removeToken')
    }
    throw error
  }
}

/**
 * Load booking details.
 * @param {*} param0
 * @param {*} payload
 */
const getOneBookings = async ({ commit }, payload) => {
  try {
    const result = await api.get(`/booking/${payload.id}`)
    if (result) {
      commit('UPDATE_SELECTED_BOOKING', payload)
      return result
    }
  } catch (error) {
    throw error
  }
}

/**
 * Prints current job card in view
 * @param {*} param0 
 * @param {Object} payload 
 * @returns 
 */
const printJobCard = async ({ commit }, payload) => {
  try {

    return await api.get(`/job/download/${payload.id}`, { responseType: 'blob' })

  } catch (error) {
    throw error
  }
}

const searchJob = async ({ commit }, payload) =>  {
  const options = {
    params: payload,
  }

  try {
    return await api.get('/portal/jobcard/search', options)
  } catch (e) {
    throw e
  }
}

export { getBookings, updateBooking, getOneBookings, printJobCard, searchJob }
