/**
 * resets the infinite scroll identifier
 */
const infiniteIdentifier = {
    data () {
        return {
            identifier: +new Date()
        }
    },
    methods: {
        resetIdentifier () {
            this.identifier += 1
        }
    }
}

export default infiniteIdentifier
