<template>
  <v-app class="body">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      online: false,
    };
  },
  methods: {
    amIOnline(value) {
      this.$store.commit("UPDATE_INTERNET_STATE", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/variables.scss";

html {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gotham-Medium;
  font-weight: 700;
}

div,
p,
span,
section,
a,
table {
  font-family: Gotham-Book;
}

.body {
  font-family: Gotham-Book;
  font-weight: 400;
  max-height: 100vh;
  overflow: hidden;
}

.body::-webkit-scrollbar {
  display: none;
}
</style>
