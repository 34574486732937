
export class Helper {
    static initialGenerate (username) {
        if (username != null) {
            return username.split(' ').map((n) => n[0]).slice(0, 2).join('') 
        }
    }

    static avarterColor () {
        const colors = [
            '#ef476f',
            '#ffd166',
            '#06d6a0',
            '#118ab2',
            '#073b4c',
            '#43aa8b',
            '#f8961e',
            '#f94144',
            '#f3722c'
        ]

        const position = Math.floor(Math.random() * colors.length)
        return colors[position]
    }

    static isValidUrl (url) {
        const pattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
        return pattern.test(url)
    }
}
