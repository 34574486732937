<template>
  <v-dialog v-model="showDialog" width="500px" persistent>
    <v-card>
      <v-card-title class="primary">
        <p class="white--text">
          {{ dialogTitle }}
        </p>
      </v-card-title>
      <v-container>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-row class="mx-2">
            <v-col v-if="!isEditing && !isAddJob" cols="12">
              <v-autocomplete
                :items="entries"
                item-text="first_name"
                item-value="id"
                :loading="loading"
                :search-input.sync="searchInput"
                cache-items
                clearable
                placeholder="Search nurse"
                :rules="nameRules"
                @change="(value) => getSelectedNurse(value)"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for nurse by name
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.first_name }}
                      {{ item.last_name }}</v-list-item-title
                    >

                    <v-list-item-subtitle
                      v-text="item.firstname"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ item.care_number }}
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col  cols="12">
              <v-autocomplete
                  :items="entries"
                  item-text="care_card_number"
                  item-value="id"
                  :loading="loading"
                  :search-input.sync="jobSearch"
                  cache-items
                  clearable
                  placeholder="Search jobs"
                  :rules="nameRules"
                  @change="(value) => getJobs(value)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Search for customer care job
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.care_card_number }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.client_name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="nurseJob.duration"
                label="Service duration(days)"
                type="number"
                maxlength="2"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="nurseJob.rate"
                label="Daily rate"
                type="number"
                :rules="phoneRules"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="nurseJob.amount"
                label="Total amount"
                type="number"
                :rules="phoneRules"
                :value="nurseJob.amount"
                disabled
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="nurseJob.start_date"
                    label="Start Date *"
                    prepend-icon="mdi-calendar-today"
                    :rules="nameRules"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="nurseJob.start_date"
                  no-title
                  scrollable
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="nurseJob.end_date"
                    label="End Date *"
                    prepend-icon="mdi-calendar"
                    :rules="nameRules"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="nurseJob.end_date"
                  no-title
                  scrollable
                  :value="endDate"
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="nurseJob.status"
                :items="statusItems"
                label="Job Status"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="nurseJob.payment_status"
                :items="paymentOption"
                label="Payment Status"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions right>
        <v-btn
          outlined
          :disabled="loading"
          @click="deleteNurseJob()"
          color="red"
        >
          Delete
        </v-btn>
        <v-btn text @click="closeDialog()"> Cancel </v-btn>
        <v-btn
          v-if="isEditing"
          text
          :disabled="isSaving"
          color="orange"
          @click="handleJobUpdate"
        >
          Update
        </v-btn>
        <v-btn
          v-else
          text
          :disabled="loading"
          color="primary"
          @click="handleSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "AssignNurseDialog",
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    job: {
      type: Object,
      default: () => {},
    },
    nurse: {
      type: Object,
      default: () => {},
    },
    isAddJob: {
       type: Boolean,
       default: true,
    }
  },

  data() {
    return {
      lazy: false,
      valid: false,
      clearable: true,
      menu: false,
      menu1: false,
      checked: true,
      isSaving: false,
      searchInput: null,
      searchedJobId: null, 
      jobSearch: null,
      entries: [],
      nameRules: [(v) => !!v || "Field is required"],
      phoneRules: [(v) => !!v || "Value is required"],
      nurseId: "",
      paymentOption: ["Paid", "UnPaid"],
      nurseJob: {
        start_date: "",
        end_date: "",
        days: "",
        nights: "",
        amount: 0.0,
        status: "",
        duration: 0,
        rate: 0.0,
        idempotency_key: "",
        payment_status: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingState",
      searches: "getDataListSearchItems",
      selectedJob: "getSelectedJob",
      listItem: "listItem"    }),
    startDate: {
      get() {
        return this.nurseJob.start_date | this.setDateFormat("DD-MM-YYYY");
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    jobTitle: {
      get() {
        return this.title;
      },
      set(title) {
        this.$emit("input", title);
      },
    },

    currentJob: {
      get() {
        
        return this.job;
      },
      set(job) {
        this.$emit("input", job);
      },
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    submitAction: {
      get() {
        return this.isEditing;
      },
      set(isEditing) {
        this.$emit("input", isEditing);
      },
    },
    endDate() {
      return moment(this.nurseJob.start_date).add(
        this.nurseJob.duration,
        "days"
      );
    },
    dialogTitle() {
      return this.isEditing
        ? `Updating ${this.nurse.first_name}'s Job `
        : this.jobTitle;
    },

    statusItems() {
      return ["pending", "ongoing", "canceled", "complete"];
    },
  },
  created() {
    this.createIdepontentKey();
  },
  watch: {
    showDialog() {
      if (this.isEditing) {
        this.getDataFromServer();
      }
    },
    searchInput(val) {
      this.searchItems(val);
      this.entries = this.searches;
    },
    "nurseJob.rate"(val) {
      this.totalAmount();
    },
    "nurseJob.duration"(val) {
      this.totalAmount();
      this.nurseJob.days = val;
      this.nurseJob.nights = val;
    },
    jobSearch(value) {
      this.searchJobs(value);
      this.entries = this.searches;
    }
  },

  methods: {
    getText(item) {
      return `${item.first_name} - ${item.last_name}(${item.care_number})`;
    },
    createIdepontentKey() {
      this.nurseJob.idempotency_key = this.$uuid.v4();
    },
    getSelectedNurse(value) {
      this.nurseId = value;
    },
    totalAmount() {
      this.nurseJob.amount = this.nurseJob.rate * this.nurseJob.duration;
    },
    parseDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getDataFromServer() {
      this.nurseJob = Object.assign(this.nurseJob, this.currentJob);
      // Format it and save to vue data property
      this.nurseJob.start_date = this.currentJob.start_date;
      this.nurseJob.end_date = this.currentJob.end_date;
    },

    closeDialog() {
      this.showDialog = !this.showDialog;
      this.createIdepontentKey();
    },
    async searchJobs(item) {
      const payload = {
        searchTerm: item,
      };

      try {
        const result = await this.$store.dispatch("searchJob", payload);
        if (result) {
          this.entries = result.data.data;
          return result.data.data;
        }
      } catch (e) {
        return e;
      }
    },
    getJobs(jobid) {
         this.searchedJobId= jobid;
    },
    async searchItems(item) {
      const payload = {
        searchTerm: item,
        type: "nurse",
      };

      try {
        const result = await this.$store.dispatch("search", payload);
        if (result) {
          this.entries = result.data.data;
          return result.data.data;
        }
      } catch (e) {
        return [];
      }
    },
    async handleSave() {
      let data = this.nurseJob;

      // if (!this.$refs.form.validate()) {
      //   this.$toast.error("Some fields are not filled");
      //   return;
      // }

      const payload = {
        ...data,
        nurseId: this.listItem.id,
        jobId: this.searchedJobId || this.selectedJob.id,
        amount: parseFloat(this.nurseJob.amount),

      };
        try {
          this.isSaving = !this.isSaving;

          const response = await this.$store.dispatch("createNurseJob",payload);

          if (response.data.status === "success") {
            this.$toast.success("Nurse job successfully created", {
              color: "primary",
              queueable: true,
              timeout: 5000,
            });
            this.closeDialog();
            this.createIdepontentKey();
                      Object.assign(this.nurseJob, {});

          } else {
            this.$toast.error("Nurse job already created");
          }
          Object.assign(this.nurseJob, {});
        } catch (e) {
          this.$toast.error("Error while creating nurse job");
          throw Error(e)
        }

    },
    async deleteNurseJob() {
      try {
        const payload = {
          id: this.currentJob.id,
        };

        const result = await this.$store.dispatch("deleteNurseJob", payload);
        if (result) {
          this.closeDialog();
          this.$toast.success("Job deleted successfully");

          return;
        }
      } catch (e) {
        this.$toast.error(
          "An error occurred while deleting, refresh page and try again"
        );
        throw Error(e);
      }
    },
    async handleJobUpdate() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Some fields are not filled");
      }

      const payload = {
        id: this.job.id,
        data: {
          ...this.nurseJob, ...{ 
            jobId: this.searchedJobId || this.selectedJob.id,
            amount: parseFloat(this.nurseJob.amount)}
        }
      };

      try {
        const result = await this.$store.dispatch("updateNurseJob", payload);
        if (result) {
          this.closeDialog();
          Object.assign(this.nurseJob, {});
          this.$toast.success("Nurse job status update successful");

          return result;
        } else {
          this.$toast.error("Nurse job status update failed");
        }
      } catch (e) {
         this.$toast.error("An error occured wheile updating nurse job status");
        return e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  position: relative;
  left: 20%;
}
</style>
