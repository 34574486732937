<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="6">
        <v-card class="mx-auto" max-width="500">
          <v-img
            class="white--text align-end"
            height="200px"
            src="https://picsum.photos/350/165?random"
          >
            <v-card-title class="compose-title">
              Compose push notification here
            </v-card-title>
          </v-img>
          <div class="compose">
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-container>
                <v-text-field id="myInput" v-model="term" :placeholder="hint" />
                <transition name="fade">
                  <v-card v-if="term" class="mx-auto" tile elevation="4">
                    <v-list shaped>
                      <v-list-item-group color="primary">
                        <v-list-item v-if="isSearch">
                          <a>Searching...</a>
                        </v-list-item>
                        <v-list-item v-if="noResults">
                          <a>User not found</a>
                        </v-list-item>
                        <v-list-item @click="addRecipient('all')">
                          <a>Send to all</a>
                        </v-list-item>
                        <v-list-item v-for="(user, i) in searchList" :key="i">
                          <v-list-item-content @click="addRecipient(user)">
                            <v-list-item-title>
                              {{ user.firstname }} {{ user.lastname }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </transition>
                <template>
                  <v-combobox
                    id="category"
                    v-model="message.category"
                    :items="items"
                    chips
                    clearable
                    label="Notification category"
                  />
                </template>
                <v-text-field
                  id="title"
                  v-model="message.title"
                  :rules="fieldRules"
                  :counter="50"
                  label="Notification Title"
                  required
                />
                <v-select
                  v-model="message.click_action"
                  :items="activities"
                  label="View to open in the app"
                  :value="activities[2]"
                  required
                />
                <v-textarea
                  id="body"
                  v-model="message.message"
                  hint="Message to be sent in the notification body"
                  name="message"
                  label="Notification Body"
                  :rules="fieldRules"
                  :counter="200"
                />
                <v-btn color="error" class="mr-4 reset-button" @click="reset">
                  Reset Form
                </v-btn>

                <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mr-4 validate-button"
                  @click="validate"
                >
                  Validate
                </v-btn>
              </v-container>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6" class="message-list">
        <v-card cols="6" max-width="800">
          <template v-for="(item, index) in getMessageList">
            <v-list-item :key="index + 'A'" @click="getSelectedIdInfo(item)">
              <v-list-item-avatar>
                <v-avatar :color="color()" size="48">
                  <span class="white--text headline">{{
                    initials(item.title) | capitalize
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.message }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.createdAt | setDateFormat("DD-MM-YYYY") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index" />
          </template>
          <infinite-loading
            :identifier="identifier"
            :distance="3500"
            @infinite="fetchSentNotifications"
          >
            <div slot="spinner" class="md-layout md-alignment-top-center">
              <v-progress-circular indeterminate color="gray" />
            </div>
            <div slot="no-results" class="infinite-results-prompt">
              <v-chip class="ma-2">
                <span>No data available</span>
              </v-chip>
            </div>
            <div slot="no-more" class="infinite-no-more-prompt">
              <v-chip class="ma-2">
                <span>No more data available</span>
              </v-chip>
            </div>
          </infinite-loading>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showConfirmDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">
            {{ message.title }}
          </v-card-title>

          <v-card-text>{{ message.message }}</v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="green darken-1"
              text
              @click="showConfirmDialog = false"
            >
              Edit
            </v-btn>

            <v-btn color="green darken-1" text @click="sendPushMessage">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import infiniteIdentifier from "@/mixin/infiniteScrollId";
import InfiniteLoading from "vue-infinite-loading";
import * as Utils from "@/helpers";
import { isEqual } from "lodash";

export default {
  name: "Notification",
  components: {
    InfiniteLoading,
  },
  mixins: [infiniteIdentifier],
  data() {
    return {
      sendTo: "",
      showConfirmDialog: false,
      valid: true,
      lazy: false,
      fieldRules: [
        (v) => !!v || "Field is required",
        (v) => v.length <= 100 || "You have exceeded the character length",
      ],
      message: {
        title: "",
        message: "",
        category: "",
        click_action: "",
      },
      topic: "global",
      items: ["update", "postAlert", "info"],
      activities: [
        "com.tehecaapp.forum.CreateHealthFeed",
        "com.tehecaapp.forum.UserPosts",
        "com.tehecaapp.home.MainActivity",
      ],
      isSearch: false,
      term: "",
      hint: "Enter name, email, phonenumber",
    };
  },
  computed: {
    ...mapGetters([
      "getMessageList",
      "getDataListSearchItems",
      "isInternetConnected",
    ]),
    searchList() {
      return this.getDataListSearchItems;
    },
    noResults() {
      return this.getDataListSearchItems === 0;
    },
  },
  watch: {
    term(val) {
      if (val.length >= 3) {
        this.isSearch = true;
        this.hasNoresult();
        this.searchUser(val);
      }
      if (!val) {
        this.isSearch = false;
      }
    },
    getDataListSearchItems(val) {
      this.hasNoresult();
    },
  },
  methods: {
    ...mapActions(["fetchData"]),

    getSelectedIdInfo(message) {
      this.$store.commit("UPDATE_SELECTED_ID", message.id);
      this.$store.commit("UPDATE_SELECTED_LIST_ITEM_DATA", message);
      Object.assign(this.message, message);
    },
    hasNoresult() {
      if (!this.getDataListSearchItems.length > 0) {
        this.isSearch = false;
      }
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.showConfirmDialog = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async fetchSentNotifications($state) {
      try {
        await this.fetchData($state);
      } catch (err) {
        throw Error(err);
      }
    },
    async sendPushMessage() {
      this.showConfirmDialog = false;
      const payload = {
        category: this.message.category,
        title: this.message.title,
        click_action: this.message.click_action,
        message: this.message.message,
        label: this.message.category,
        messageType: this.messageType,
        topic: this.topic,
        receiver: this.sendTo,
        documentid: "",
      };

      if (this.isInternetConnected) {
        try {
          const result = await this.$store.dispatch(
            "sendPushNotification",
            payload
          );

          if (result.data.status === "success") {
            this.$toast.success("Notification message sent successfully", {
              color: "primary",
              dismissable: true,
              queueable: true,
            });
          } else if (result.data.status === "failed") {
            this.$toast.error(result.data.error);
          }
          this.reset();
        } catch (ex) {
          this.$toast.error(ex.response.data.error);
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
    color() {
      return Utils.Helper.avarterColor();
    },
    initials(title) {
      return Utils.Helper.initialGenerate(title);
    },
    addRecipient(recipient) {
      this.term = "";
      if (isEqual(recipient, "all")) {
        this.messageType = "all";
        this.hint = `Sending message to ${recipient}`;
        this.sendTo = "global";
        return;
      }

      if (!recipient.fcm_token) {
        this.$toast.success("You cannot send this user a direct message", {
          color: "warning",
        });
        return;
      }
      this.hint = `Sending message to ${recipient.firstname} ${recipient.lastname}`;
      this.messageType = "single";
      this.sendTo = recipient.fcm_token;
    },

    async searchUser(term) {
      let payload = {
        type: "customer",
        searchTerm: term,
      };
      try {
        await this.$store.dispatch("search", payload);
      } catch (error) {
        throw Error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  padding: 3%;
}
.message-list {
  height: 85vh;
  overflow-y: scroll;
}
</style>
