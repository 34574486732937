/**
 * Retrive the token saved as in localstorage
 * @method token
 * @param  {Object} state vuex state object
 * @return {Object} Contains the country code, user role etc
 */
const getTehecaAuth = state => state.tehecaAuth

export {
    getTehecaAuth
}
