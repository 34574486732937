import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index'
import Login from '@/components/Login'
import Verification from '@/components/Verification'
import Nurses from '@/components/nurses/Nurses'
import Customers from '@/components/customers/Customers'
import ShopComponent from '@/components/shop/ShopComponent'
import Communication from '@/components/communication/Communication'
import Admin from '@/components/admin/Admin'
import NotFoundComponent from '@/components/core/NotFoundComponent'
import NurseContent from '../components/nurses/NurseContent'
import CustomerContent from '../components/customers/CustomerContent'
import ProfileDetailsSection from '../components/nurses/NurseProfileComponent'
import ProfileSection from '../components/customers/ProfileSection'
import Booking from '../components/bookings/Booking'
import SalesComponent from '../components/shop/SalesComponent'
import OrderContent from '../components/shop/OrderContent'
import OrderDetail from '../components/shop/OrderDetail'
import Finance from '../components/finance/Finance'
import FinanceContent from '@/components/finance/FinanceContent'
import Dashboard from '@/components/dashboard/Dashboard'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/nurses',
        name: 'Nurses',
        redirect: { name: 'NurseContent' },
        component: Nurses,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'list',
            name: 'NurseContent',
            component: NurseContent,
            meta: {
              parentName: 'Nurses',
              childPath: '/nurses/list',
            },
            children: [
              {
                path: ':id',
                name: 'ProfileDetailsSection',
                component: ProfileDetailsSection,
                meta: {
                  parentName: 'Nurses',
                  childPath: '/nurses/list',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/communication',
        name: 'Communication',
        component: Communication,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/bookings',
        name: 'Booking',
        component: Booking,
        meta: {
          requiresAuth: true,
        },
        children: [
          { path: '/bookings/:id', name: 'ViewBooking', component: Booking },
        ],
      },
      {
        path: '/shop',
        name: 'Shop',
        component: ShopComponent,
        redirect: { name: 'OrderContent' },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'sales',
            name: 'SalesComponent',
            component: SalesComponent,
            meta: {
              parentName: 'ShopComponent',
              childPath: '/shop/sales',
            },
          },
          {
            path: 'orders',
            name: 'OrderContent',
            component: OrderContent,
            meta: {
              parentName: 'ShopComponent',
              childPath: '/shop/orders',
            },
          },
        ],
      },
      {
        path: '/shop/orders/:id',
        name: 'OrderDetail',
        component: OrderDetail,
        meta: {
          parentName: 'ShopComponent',
        },
      },
      {
        path: '/customers',
        name: 'Customer',
        component: Customers,
        redirect: { name: 'CustomerContent' },
        meta: {
          parentName: 'NurseContent',
          requiresAuth: true,
        },
        children: [
          {
            path: 'list',
            name: 'CustomerContent',
            component: CustomerContent,
            meta: {
              parentName: 'CustomerContent',
              childPath: '/customers/list',
            },
            children: [
              {
                path: ':id',
                name: 'ProfileSection',
                component: ProfileSection,
                meta: {
                  parentName: 'CustomerContent',
                  childPath: '/customers/list',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
      },
      {
        path: '/finance',
        name: 'Finance',
        component: Finance,
        redirect: { name: 'FinanceContent' },
        meta: {
          parentName: 'Finance',
          requiresAuth: true,
        },
        children: [
          {
            path: 'list',
            name: 'FinanceContent',
            component: FinanceContent,
            meta: {
              parentName: 'FinanceContent',
              childPath: '/finance/list',
            },
          },
        ],
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          guest: true,
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification,
    meta: {
      guest: true,
    },
  },
  {
    path: '*',
    name: 'NotFoundComponent',
    component: NotFoundComponent,
    meta: {
      guest: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('tehecaAuth') == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath,
        },
      })
    } else {
      let auth = JSON.parse(localStorage.getItem('tehecaAuth'))
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (auth.user.role === 'ADMIN') {
          next()
        } else {
          next({ name: 'Home' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('tehecaAuth') == null) {
      next()
    } else {
      next({ name: 'Home' })
    }
  } else {
    next()
  }
})
export default router