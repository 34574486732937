<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="mx-auto" max-width="544" outlined>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title class="title">Verify email here</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>
              <div class="ma-auto position-relative" style="max-width: 300px">
                <v-otp-input
                  v-model="otp"
                  :disabled="loading"
                  @finish="onFinish"
                  type="number"
                ></v-otp-input>
                <v-overlay absolute :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </div>
              <div>Check your email({{ userEmail }}) for verification code</div>

              <v-snackbar
                v-model="snackbar"
                :color="snackbarColor"
                :timeout="2000"
              >
                {{ text }}
              </v-snackbar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script type="text/javascript">
import { mapGetters } from "vuex";
export default {
  name: "Verification",
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarColor: "default",
      otp: "",
      text: "",
      expectedOtp: "133707",
      code: "",
    };
  },
  computed: {
    ...mapGetters(["isInternetConnected", "getLoadingState", "getCurrentUser"]),
    userEmail() {
      return this.getCurrentUser.email;
    },
  },
  methods: {
    onFinish(rsp) {
      this.loading = true;
      this.code = rsp;
      this.sendVerification();
    },
    async sendVerification() {
      try {
        const payload = {
          data: {
            email: this.getCurrentUser.email,
            code: this.code,
          },
        };

        const result = await this.$store.dispatch("verify_code", payload);
        this.loading = !this.loading;
        if (result.data.status === "success") {
          this.snackbarColor = "success";
          this.text = `Email login verified ${this.snackbarColor}`;
          this.snackbar = true;
          this.$router.push({ name: "Home" });
        } else if (result.data.status === "failed") {
          this.snackbarColor = "error";
          this.text = `Invalid verification, please try again`;
          this.snackbar = true;
        }
      } catch (err) {
        this.loading = !this.loading;
        this.$toast.error("Invalid verification, please try again");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  text-align: center;
}
</style>