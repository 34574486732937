<template>
  <div class="order-content">
    <v-tabs
      v-model="tab"
      class="elevation-1"
      light
      :centered="centered"
      :grow="grow"
    >
      <v-tabs-slider />

      <v-tab>{{ pending }}</v-tab>
      <v-tab>{{ confirmed }}</v-tab>
      <v-tab>{{ received }}</v-tab>
      <v-tab>{{ cancelled }}</v-tab>

      <v-tab-item>
        <order-data-table
          :requests="getPendingOrders"
          :loading="loading"
          title="Pending Orders"
        />
      </v-tab-item>

      <v-tab-item>
        <order-data-table
          :requests="getConfirmedOrders"
          :loading="loading"
          title="Confirmed Orders"
        />
      </v-tab-item>

      <v-tab-item>
        <order-data-table
          :requests="getReceivedOrders"
          :loading="loading"
          title="Received Orders"
        />
      </v-tab-item>

      <v-tab-item>
        <order-data-table
          :requests="getCancelledOrders"
          :loading="loading"
          title="Cancelled Orders"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import OrderDataTable from "./OrderDataTable";
import { size } from "lodash";
import { mapGetters } from "vuex";
import { orderStates } from "../../helpers";
const { CONFIRMED, RECEIVED, PENDING, COMPLETED, CANCELLED } = orderStates;

export default {
  name: "OrderContent",
  components: {
    "order-data-table": OrderDataTable,
  },
  data() {
    return {
      title: "Customer Orders",
      loading: true,
      tab: null,
      icons: false,
      centered: false,
      grow: false,
      confirmed: CONFIRMED,
      received: RECEIVED,
      pending: PENDING,
      cancelled: CANCELLED,
    };
  },
  computed: {
    ...mapGetters([
      "getPendingOrders",
      "getConfirmedOrders",
      "getReceivedOrders",
      "getCancelledOrders",
    ]),
  },
  created() {
    this.fetchPendingOrderList();
    this.fetchConfirmedOrderList();
    this.fetchReceivedOrderList();
    this.fetchCancelledOrderList();
  },
  methods: {
    async fetchPendingOrderList() {
      const payload = {
        status: PENDING,
      };

      const orders = await this.getOrders(payload);
      if (size(orders)) {
        this.$store.commit("UPDATE_ORDER_LIST", {
          ...payload,
          ...{ orders },
        });
      }
    },
    async fetchConfirmedOrderList() {
      const payload = {
        status: CONFIRMED,
      };

      const orders = await this.getOrders(payload);
      if (size(orders)) {
        this.$store.commit("UPDATE_ORDER_LIST", {
          ...payload,
          ...{ orders },
        });
      }
    },
    async fetchReceivedOrderList() {
      const payload = {
        status: RECEIVED,
      };

      const orders = await this.getOrders(payload);
      if (size(orders)) {
        this.$store.commit("UPDATE_ORDER_LIST", {
          ...payload,
          ...{ orders },
        });
      }
    },
    async fetchCancelledOrderList() {
      const payload = {
        status: CANCELLED,
      };

      const orders = await this.getOrders(payload);
      if (size(orders)) {
        this.$store.commit("UPDATE_ORDER_LIST", {
          ...payload,
          ...{ orders },
        });
      }
    },
    async getOrders(payload) {
      try {
        const result = await this.$store.dispatch("getShopOrder", payload);
        if (result) {
          this.loading = false;
          return result.data.data;
        }
      } catch (e) {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
