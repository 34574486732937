<template>
  <v-dialog
    v-model="dialog"
    width="800px"
    persistent
  >
    <v-card
      :loading="getLoadingState"
      :disable="getLoadingState"
    >
      <v-card-title
        class="primary"
      >
        {{ formTitle }}
      </v-card-title>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field
                v-model="customer_profile.firstname"
                prepend-icon="mdi-account"
                label="First name"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="customer_profile.lastname"
                prepend-icon="mdi-account"
                label="last name"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="customer_profile.email"
                prepend-icon="mdi-mail"
                label="Email"
              />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer_profile.phonenumber"
                    type="tel"
                    prepend-icon="mdi-phone"
                    placeholder="(000) 000 - 0000"
                    label="Contact"
                    :rules="phoneRules"
                    :counter="14"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer_profile.alternate_number"
                    type="tel"
                    prepend-icon="mdi-phone"
                    placeholder="(000) 000 - 0000"
                    label="Other number"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="d-flex"
              cols="4"
            >
              <v-select
                v-model="order.order"
                :items="products"
                label="Ordered"
                prepend-icon="mdi-shopping"
              />
            </v-col>
            <v-col
              class="d-flex"
              cols="4"
            >
              <v-select
                v-model="customer_profile.gender"
                :items="gender"
                label="Gender"
              />
            </v-col>
            <v-col
              class="d-flex"
              cols="4"
            >
              <v-select
                v-model="customer_profile.children"
                :items="children"
                label="No. of children"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="customer_profile.company"
                prepend-icon="mdi-office"
                label="Company"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="customer_profile.occupation"
                label="Job title"
              />
            </v-col>
            <v-col cols="12">
              <vuetify-google-autocomplete
                id="address"
                v-model="customer_profile.address"
                prepend-icon="mdi-map-marker"
                placeholder="Start typing"
                label="Place of residence"
                :required="true"
                types="address"
                :enable-geolocation="true"
                :place-name="true"
                country="UG"
                :rules="nameRules"
                @placechanged="getAddressData"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-spacer />
      <v-card-actions right>
        <v-btn
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="isEditing"
          text
          color="orange"
          @click="update()"
          :disabled="getLoadingState"
        >
          Update
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="submit()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'AddCustomerDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        customer: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            lazy: false,
            valid: false,
            salute: ['Mr', 'Mrs', 'Ms', 'Dr'],
            emailRules: [v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
            nameRules: [v => !!v || 'Field is required'],
            phoneRules: [v => !!v || 'Phone number is required'],
            products: [
                'Prepared mama bag',
                'Mama kit value',
                'Patient care services',
                'Postnatal care services'
            ],
            order: {
                order: '',
                address: '',
                shippment_type: '',
                requested_by: '',
                category: ''
            },
            customer_profile: {
                email: ' ',
                firstname: '',
                lastname: '',
                phonenumber: '',
                address: '',
                contry: '',
                user_type: 'customer',
                signup_method: 'portal',
                children: 0,
                gender: '',
                alternate_number: '',
                verified: false,
                occupation: '',
                company: '',
                latitude: '',
                longitude: ''
            },
            children: [0, 1, 2, 3, 4, 5, 6],
            gender: ['Female', 'Male']
        }
    },
    computed: {
        ...mapGetters([
            'listItem',
            'getLoadingState',
            'isInternetConnected'
        ]),
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        formTitle: {
            get () {
                return this.title
            },
            set (title) {
                this.$emit('input', title)
            }
        },
        submitAction: {
            get () {
                return this.isEditing
            },
            set (isEditing) {
                this.$emit('input', isEditing)
            }
        },
        today () {
            let endDate = new Date(
                this.date.getFullYear(),
                this.date.getMonth() + 1,
                1
            )
            return endDate.toISOString().slice(0, 10)
        }
    },

    watch: {
        dialog: function () {
            this.customer_profile = Object.assign({}, this.customer)
            // if (this.isEditing) {
            //     this.getDataFromServer()
            // }
        }
    },
    methods: {
        getAddressData: function (addressData, placeResultData) {
            this.customer_profile.address = addressData.name
            this.customer_profile.latitude = addressData.latitude
            this.customer_profile.longitude = addressData.longitude
            this.order.address = addressData.name
        },
        async submit () {
            if (!this.$refs.form.validate()) {
                this.$toast.error('Some fields are not filled')
                return
            }

            if (this.isInternetConnected) {
              try {
                this.customer_profile.signup_method = 'portal'
                if (['Prepared mama bag', 'Mama kit value', 'Postnatal care services'].includes(this.order.order)) {
                  this.customer_profile.user_type = 'parent'
                } else {
                  this.customer_profile.user_type = 'others'
                }
                const payload = { ...this.customer_profile, ...{ order: this.order } }

                const response = await this.$store.dispatch(
                      'createCustomer',
                      payload
                  )

                  if (response.data.status === 'success') {
                      this.$toast.success('Customer profile created', {
                          color: 'primary',
                          queueable: true,
                          timeout: 5000
                      })
                      this.dialog = !this.dialog
                  } else if (response.data.status === 'failed') {
                    this.$toast.error(result.data.error)
                  }
              } catch (e) {
                  this.$toast.error(e.response.data.error)
              }
            } else {
              this.$toast.error('No internet connection')
            } 
        },
        async update () {
            if (!this.$refs.form.validate()) {
                this.$toast.error('Some fields are not filled')
                return
            }
            const payload = { ...this.customer_profile, ...{ order: this.order } }

            if (this.isInternetConnected) {
              try {
                  const response = await this.$store.dispatch(
                      'updateCustomerProfile',
                      payload
                  )
                  if (response.data.status === 'success') {
                      this.$toast.success('Customer profile updated', {
                          color: 'success',
                          queueable: true,
                          timeout: 5000
                      })
                      this.dialog = !this.dialog
                  } else if (response.data.status === 'failed') {
                    this.$toast.error(result.data.error)
                  }
              } catch (e) {
                  this.$toast.error(e.response.data.error)
              }
            } else {
              this.$toast.error('No internet connection')
            }
        }
    }
}
</script>
