<template>
  <v-tabs-items v-model="tab">
    <v-card class="userList list-width">
      <v-list three-line>
        <v-list-item-group v-model="item" color="primary" class="list-item">
          <template v-for="(item, index) in userListItems">
            <v-list-item :key="index" @click="getSelectedIdInfo(item)">
              <v-list-item-avatar>
                <v-img
                  v-if="displayPic(item)"
                  :src="displayPic(item)"
                  class="img-avarter"
                  @error="userInitial(item)"
                />
                <v-avatar v-else :color="color()">
                  <span class="white--text headline">{{
                    userInitial(item)
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="data-tags-title">
                  {{ fullNames(item) }}
                </v-list-item-title>
                <v-list-item-title class="data-tags-subtitle" />
                {{ phonenumber(item) | formatPhoneNumber }}
                <v-list-item-title class="data-tags-subtitle" />

                <p class="care-number" v-show="isNurse">
                  {{ item.care_number }}
                </p>
              </v-list-item-content>

              <v-tooltip>
                {{ fullNames(item) }}
              </v-tooltip>
              <v-btn v-show="isNurse" icon>
                <v-icon v-if="item.verified" color="primary lighten-1">
                  mdi-check
                </v-icon>
                <v-icon v-else color="red lighten-1">
                  mdi-account-alert
                </v-icon>
              </v-btn>
              <div v-show="!isNurse" class="ma-2">
                <v-icon color="primary" v-if="userType(item) === 'expecting'">
                  mdi-human-pregnant</v-icon
                >
                <v-icon color="orange" v-else-if="userType(item) === 'parent'">
                  mdi-account-child-circle</v-icon
                >

                <v-icon color="accent" v-else> mdi-web</v-icon>
              </div>
            </v-list-item>
          </template>
          <infinite-loading
            v-show="!isSearch"
            ref="infiniteLoading"
            :identifier="identifier"
            :distance="3500"
            @infinite="infiniteHandler"
            class="pt-8"
          >
            <div slot="spinner" class="md-layout md-alignment-top-center">
              <v-progress-circular indeterminate color="gray" />
            </div>
            <div slot="no-results" class="infinite-results-prompt">
              <v-chip class="ma-2">
                <span>No more data available</span>
              </v-chip>
            </div>
            <div slot="no-more" class="infinite-no-more-prompt">
              <v-chip class="ma-2">
                <span>No more data available</span>
              </v-chip>
            </div>
          </infinite-loading>
        </v-list-item-group>

        <v-chip v-show="hasNoData" class="ma-2 no-data">
          <span>No data available</span>
        </v-chip>

        <v-chip v-show="!isInternetConnected" class="ma-2 text-center">
          <span>No internet connection</span>
        </v-chip>
      </v-list>
    </v-card>
  </v-tabs-items>
</template>
<script>
import { mapGetters } from "vuex";
import infiniteIdentifier from "@/mixin/infiniteScrollId";
import InfiniteLoading from "vue-infinite-loading";
import * as Utils from "@/helpers";

export default {
  name: "UserLists",
  components: {
    InfiniteLoading,
  },
  mixins: [infiniteIdentifier],
  props: {
    infiniteHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      item: 1,
      users: [],
      tab: null,
      centered: true,
      grow: true,
    };
  },
  computed: {
    ...mapGetters([
      "getDataListSearchItems",
      "getSelectedId",
      "getIsSearch",
      "getDataListItems",
      "listType",
      "getCustomerList",
      "getNurseList",
      "isInternetConnected",
      "getNurseLoading",
    ]),
    userListItems() {
      return this.isSearch
        ? this.getDataListSearchItems
        : this.getDataListItems;
    },
    isSearch() {
      return this.getIsSearch;
    },
    hasNoData() {
      return this.userListItems === 0;
    },
    isNurse() {
      return this.listType === "nurse";
    },
  },

  methods: {
    displayPic(item) {
      return this.listType === "customer"
        ? item.photo_url
        : this.firebaseImage(item);
    },
    color() {
      return Utils.Helper.avarterColor();
    },
    userType(item) {
      return item.user_type !== null ? item.user_type : "explorer";
    },
    firebaseImage(item) {
      if (item.profile_picture) {
        return item.profile_picture;
      }
      return item.profilepicture;
    },
    fullNames(item) {
      let nurse = item.first_name + " " + item.last_name;
      let customer = item.firstname + " " + item.lastname;
      return this.listType === "customer" ? customer : nurse;
    },
    getSelectedIdInfo(item) {
      if (Object.keys(this.$route.meta).length) {
        let id = this.listType === "customer" ? item.user_id : item.id;
        this.$store.commit("UPDATE_SELECTED_ID", id);
        this.$store.commit("UPDATE_SELECTED_LIST_ITEM_DATA", item);

        this.$store.dispatch("getCustomerJobList", { id: item.id });
        this.$store.dispatch("getNurseJobs", { id: item.id });
        this.$store.dispatch("fetchNurseReviews", item.id);

        const path = this.$route.meta.childPath;
        this.$router.push({ path: `${path}/${id}` }).catch(err =>{});
      }
    },
    getApprovedNurses() {
      this.$store.dispatch("filterNurses", { approved: true });
    },
    getUnApprovedNurses() {
      this.$store.dispatch("filterNurses", { approved: false });
    },
    userInitial(item) {
      return Utils.Helper.initialGenerate(this.fullNames(item));
    },
    phonenumber(item) {
      return this.listType === "customer"
        ? item.phonenumber
        : item.phone_number;
    },
  },
};
</script>
<style lang="scss" scoped>
.common-list {
  .list-width {
    width: 350px;
  }
}
.userList {
  height: 90vh;
  overflow-y: scroll;
  position: relative;
  max-width: 300px;
  .list-item {
    .data-tags-title {
      font-size: 16px;
      color: #212121;

      @include media-breakpoint-up(xl) {
        font-size: 1.5em;
      }

      .img-avarter {
        margin-left: 40px;
        width: 70px;
        height: 70px;
      }
    }

    .data-tags-subtitle {
      font-size: 14px;
      font-family: Roboto, serif;
      font-style: bold;
      margin-bottom: 5px;
      color: #757575;
    }

    .message {
      margin-left: 1%;
      min-height: 50px;
      text-align: center;
      padding-top: 11px;
    }
  }
  .no-data {
    text-align: left;
  }
  .care-number {
    font-size: 15px;
    font-style: bold;
    color: $primary;
  }
}
</style>