<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="headline"> Send customer </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="message"
          :rules="rules"
          counter="250"
          hint="Keep the message short and precise"
          label="Message"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="green darken-1" text @click="hideDialog()"> Close </v-btn>
        <v-btn color="green darken-1" text @click="sendMesage()">
          Send message
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MessageDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fcmToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rules: [(v) => v.length <= 250 || "Max 250 characters"],
      message: "",
    };
  },
  computed: {
    ...mapGetters(["isInternetConnected"]),
    sendEnabled() {
      return this.message.length > 10;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    hideDialog() {
      this.dialog = !this.dialog;
    },
    async sendMesage() {
      const payload = {
        message: this.message,
        category: "update",
        title: "Teheca team sent you a message",
        click_action: "com.tehecaapp.home.MainActivity",
        label: "update",
        messageType: "single",
        receiver: this.fcmToken,
      };
      if (this.message === "" || this.message == null) {
        this.$toast.error("Message too short");
      } else if (this.fcmToken === "") {
        this.$toast.error("Cannot send message to user without firebase token");
      } else {
        if (this.isInternetConnected) {
          try {
            const result = await this.$store.dispatch(
              "sendPushNotification",
              payload
            );
            if (result.data.status === "success") {
              this.$toast.success("Message sent successfully", {
                color: "primary",
                dismissable: true,
              });
              this.hideDialog();
            }
          } catch (ex) {
            this.$toast.error("Error sending notification");
            throw Error(ex);
          }
        } else {
          this.$toast.error("No internet connection");
        }
      }
    },
  },
};
</script>

<style>
</style>
