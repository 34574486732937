
const state = {
    bookingsList: [],
    newBookings: [],
    activeBookings: [],
    completedBookings: []

}

export {
    state
}
