<template>
  <div class="c-profile">
    <profile />
  </div>
</template>
<script>
import { Profile } from "./sections";
export default {
  name: "ProfileSection",
  components: {
    Profile,
  },
};
</script>
<style lang="scss" scoped>
.c-profile {
  padding-top: 3rem;
}
</style>
