<template>
  <div class="top-bar">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      dark
      color="primary darken-2"
      :mini-variant="true"
    >
      <v-list>
        <v-list-item-group>
          <template v-for="item in items">
            <v-list-item :key="item.text" @click="goto(item)">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary darken-2"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ title }} Dashboard</span>
      </v-toolbar-title>
      <v-text-field
        v-model="term"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="searchPlaceHolder"
        class="hidden-sm-and-down"
        color="warning"
        :loading="loading"
      />
      <v-spacer />

      <v-card-actions>
        <v-spacer />
        <v-btn icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        <v-menu left bottom transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-chip outlined v-on="on">
              <v-btn icon large>
                <v-avatar size="32px" item>
                  <v-img scr="@/assets/logo.png" alt="Teheca" />
                </v-avatar> </v-btn
              >Account
            </v-chip>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in dropDownActions"
              :key="index"
              @click="item.action"
            >
              <v-list-item-action>
                {{ item.text }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuBar",
  data() {
    return {
      drawer: null,
      username: "",
      dialog: false,
      dropDownActions: [
        { text: "Log out", icon: "mdi-user-arrow-right", action: this.logout },
      ],
      term: "",
      loading: false,
      search: null,
      items: [
        { icon: "mdi-home", text: "Main", action: "/home", listType: "home" },
        {
          icon: "mdi-mother-nurse",
          text: "Nurse Profiles",
          listType: "nurse",
          action: { path: "/nurses" },
        },
        {
          icon: "mdi-calendar",
          text: "Bookings",
          listType: "bookings",
          action: { path: "/bookings" },
        },
        {
          icon: "mdi-account",
          text: "Customer Profiles",
          listType: "customer",
          action: { path: "/customers" },
        },
        {
          icon: "mdi-cart",
          text: "Shop",
          listType: "mamakits",
          action: { path: "/shop" },
        },
        {
          icon: "mdi-currency-usd",
          text: "Finance",
          listType: "Finance",
          action: { path: "/finance" },
        },
        {
          icon: "mdi-message",
          text: "Communication",
          listType: "comms",
          action: { path: "/communication" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["dashboardTitle", "listType", "getDataListItems"]),

    title() {
      return this.dashboardTitle || "Teheca";
    },
    searchPlaceHolder() {
      return `Start typing to search ${this.dashboardTitle.toLowerCase()} by name or phonenumber`;
    },
  },
  watch: {
    term(val) {
      if (val.length >= 3) {
        this.loading = true;
        this.$store.commit("UPDATE_LIST_TYPE", this.listType);
        this.querySelections(val);
      }
      if (val.length == 0) {
        this.$store.commit("UPDATE_IS_SEARCH", false);
        this.$store.commit("UPDATE_DATA_LIST_ITEMS", this.getDataListItems);
      }
    },
  },
  methods: {
    triggerClick(action) {
      return action;
    },
    logout() {
      this.$store.dispatch("removeToken");
      this.$toast.error("Log out succesful");
      this.$store.commit("LOGOUT");
      this.$router.push("/login");
    },
    goto(item) {
      this.resetList(item.listType);
      this.$router.push(item.action).catch(err => {});
      this.$store.commit("UPDATE_DASHBOARD_TITLE", item.text);
    },
    resetList(listType) {
      this.$store.commit("REST_USER_LIST");
      this.$store.commit("UPDATE_LIST_TYPE", listType);
      this.$store.commit("UPDATE_SELECTED_ID", "");
      this.$store.commit("UPDATE_IS_SEARCH", false);
    },
    async querySelections(val) {
      const payload = {
        type: this.listType,
        searchTerm: val,
      };

      try {
        const result = await this.$store.dispatch("search", payload);
        if (result) {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        throw Error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
