const getCustomerList = state => state.customerList
/**
 * list of customer orders
 * @param {Object} state 
 * @returns {Array} customerList
 *  */
const getCustomerOrders = state => state.customerOrders
/**
 * Holds total number of customer orders/income trace entries
 * @param {Object} state 
 * @returns {Number} customerOrderCount
 */
const getCustomerOrderCount = state => state.customerOrderCount

const getDeleteCustomerDialogStatus = state => state.deleteCustomerDialogStatus
const getSelectedNurseTab = state => state.nurseActiveTab

export {
    getCustomerList,
    getCustomerOrders,
    getCustomerOrderCount,
    getDeleteCustomerDialogStatus,
    getSelectedNurseTab
}
