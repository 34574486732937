<template>
  <v-card class="order-card">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="jobItems"
      class="elevation-2 data"
      :search="search"
      :loading="loading"
      loading-text="Loading job cards... Please wait"
    >
      <template v-slot:item.start_date="{ item }">
        <p class="mt-4">
          {{ item.start_date | setDateFormat("DD-MM-YYYY") }}
        </p>
      </template>
      <template v-slot:item.invoice_amount="{ item }">
        <p class="mt-4 special-font">
          {{ jobAmount(item.invoice_amount) }}
        </p>
      </template>
      <template v-slot:item.receipt_amount="{ item }">
        <p class="mt-4 special-font">
          {{ jobAmount(item.receipt_amount) }}
        </p>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn
          outlined
          small
          :color="getColor(item.status)"
          class="special-font"
        >
          {{ item.status | capitalize }}
        </v-btn>
      </template>

      <template v-slot:item.process="{ item }">
        <v-btn
          outlined
          small
          color="primary"
          @click="viewJob(item)"
          class="special-font"
        >
          <v-icon left>mdi-pencil </v-icon>
          Manage
        </v-btn>
      </template>
    </v-data-table>
    <job-dialog />
  </v-card>
</template>

<script>
import { formatDate, formatCurrency } from "../../../helpers";
import { mapGetters } from "vuex";
import JobDialog from "./JobDialog";

export default {
  name: "JobDataTable",
  components: {
    "job-dialog": JobDialog,
  },
  props: {
    jobs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "Job Cards",
    },
  },
  data() {
    return {
      search: "",
      job: {},
      headers: [
        {
          text: "Job Id",
          value: "id",
        },
         {
          text: "Job Card No.",
          value: "care_card_number",
        },
        {
          text: "Start Date",
          value: "start_date",
        },
        {
          text: "Client Name",
          value: "client_name",
        },

        {
          text: "Service",
          value: "service",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: "Invoice",
          value: "invoice_amount",
        },
        {
          text: "Amount Paid",
          value: "receipt_amount",
        },
        {
          text: "Job status",
          value: "status",
        },
        { text: "Manage Job", value: "process", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingState",
    }),
    jobItems() {
      return this.jobs;
    },
    showLoading() {
      return this.loading;
    },
  },
  methods: {
    jobAmount(amount) {
      return formatCurrency(amount);
    },
    getColor(value) {
      value = value.toLowerCase();
      if (value === "cancelled") return "red";
      else if (value === "pending") return "orange";
      else if (value === "ongoing") return "blue";
      else if (value === "completed") return "green";
      else return "blue";
    },
    formatDate(value) {
      return formatDate(value);
    },
    viewJob(item) {
      this.$store.commit("UPDATE_SELECTED_JOB", item);
      this.$store.dispatch("getJobCardNurses", { id: item.id });
      this.$store.commit("UPDATE_SHOW_JOB_DIALOG", true);
    },
  },
};
</script>
<style scoped lang="scss">
.special-font {
  font-weight: bold;
}
</style>
