const getBookingList = state => state.bookingsList
/**
 * Get the list of new bookings
 * @method newBookingsList()
 * @param {*} state
 */
const newBookingsList = state => state.newBookings
/**
 * Get the list of active bookings
 * @method activeBookingsList()
 * @param {*} state
 */
const activeBookingsList = state => state.activeBookings
/**
 * Get the list of completed bookings
 * @method completedBookingsList()
 * @param {*} state
 */
const completedBookingsList = state => state.completedBookings
export {
    getBookingList,
    newBookingsList,
    activeBookingsList,
    completedBookingsList
}
