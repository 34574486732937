<template>
  <div :loading="getLoadingState" :disabled="getLoadingState" class="nurse-profile">
    <div class="card-body mx-auto">
      <v-row>
        <v-col cols="4">
          <v-card-subtitle class="label"> Qualification </v-card-subtitle>
          <p class="text-field">
            {{ listItem.qualification }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Availability </v-card-subtitle>
          <p class="text-field">
            {{ listItem.availability }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Work status </v-card-subtitle>
          <p class="text-field">
            {{ listItem.work_status }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card-subtitle class="label"> School attended </v-card-subtitle>
          <p class="text-field">
            {{ listItem.school }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Graduation year </v-card-subtitle>
          <p class="text-field">
            {{ listItem.graduation_year }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Certificate No. </v-card-subtitle>
          <p class="text-field">
            {{ listItem.certificate_no }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card-subtitle class="label"> Residence </v-card-subtitle>
          <p class="text-field">
            {{ listItem.address }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> About me </v-card-subtitle>
          <p class="text-field">
            {{ listItem.about_me }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Signup date </v-card-subtitle>
          <p class="text-field">
            {{ joinDate(listItem.join_date) }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Profile state </v-card-subtitle>
          <dropdown class="text-field" :value="approved" :options="dropDownOptions" />
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Account status </v-card-subtitle>
          <div class="account-status">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :color="status == 'BLOCKED' ? 'error' : 'primary'" v-bind="attrs" v-on="on" outlined>
                  {{ status }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title @click="handleClick(item.title)">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card-subtitle class="label"> Referee name </v-card-subtitle>
          <p class="text-field">
            {{ listItem.ref_name }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Referee email </v-card-subtitle>
          <p class="text-field">
            {{ listItem.ref_email }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-card-subtitle class="label"> Referee contact </v-card-subtitle>
          <p class="text-field">
            {{ listItem.ref_phone_number | formatPhoneNumber }}
          </p>
        </v-col>
      </v-row>
    </div>
    <edit-profile-form v-model="showAddNurseDialog" title="Edit profile" :is-editing="true" :nurse="getCurrentNurse()" />
    <v-card-actions class="card-action">
      <v-btn color="success" text @click="editUser()"> Edit </v-btn>
      <v-btn color="error" text @click="confirmDelete()"> Delete </v-btn>
    </v-card-actions>

    <delete-dialog-component title="Delete Nurse Profile?" data-type="nurse" :value="deleteDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Dropdown from '../common/Dropdown'
import ProfileForm from './ProfileForm.vue'
import AddNurseCard from "./AddNurseCard.vue";
import moment from "moment";
import DeleteDialogComponent from '../shared/DeleteDialogComponent.vue';

export default {
  name: 'ProfileContent',
  components: {
    dropdown: Dropdown,
    "delete-dialog-component": DeleteDialogComponent,
    'edit-profile-form': AddNurseCard,
  },
  data() {
    return {
      items: [
        { title: 'ACTIVE' },
        { title: 'BLOCKED' },
        { title: 'SUSPENDED' },
      ],
      user: {},
      showAddNurseDialog: false,
      deleteDialog: false,
    }
  },

  computed: {
    ...mapGetters(['listItem', 'getLoadingState', 'getAccountStatus']),
    ...mapActions(['checkAccountStatus']),
    status() {
      if (this.listItem.blocked_user) {
        return this.listItem.blocked_user.status
      } else {
        return 'ACTIVE'
      }
    },
    selectedUser() {
      return this.listItem
    },
    active() {
      return this.selectedUser.verified === true ? 'Active' : 'Inactive'
    },
    dropDownStatusOptions() {
      let options = ['BLOCKED', 'SUSPENDED']

      return options
    },
    approved() {
      return this.selectedUser.verified === true
        ? 'Approved'
        : 'Pending approval'
    },
    dropDownOptions() {
      let options = []
      if (this.selectedUser.verified) {
        options = ['Unapprove']
      } else {
        options = ['Approve']
      }
      return options
    },
    displayName() {
      return this.selectedUser.first_name + '_' + this.selectedUser.last_name
    },
  },
  methods: {
    confirmDelete() {
      this.$store.commit('UPDATE_DELETE_CUSTOMER_DIALOG_STATUS', true)

    },
    async handleClick(item) {
      this.status = item
      await this.$store.dispatch('changeAccountStatus', {
        status: item,
        user_id: this.selectedUser.user_id,
        nurseId: this.selectedUser.id,
        update: false,
      })
      this.$toast.success('Nurse status changed', {
        color: 'success',
        queueable: true,
        timeout: 3000,
      })
    },
    joinDate(date) {
      return moment(date).format('LL')
    },
    getCurrentNurse() {
      return this.listItem
    },
    editUser() {
      this.showAddNurseDialog = !this.showAddNurseDialog
    },

    async deleteUser() {
      try {
        await this.$store.dispatch('deleteProfile', this.listItem)
        this.$toast.success('Nurse profile deleted', {
          color: 'info',
          queueable: true,
          timeout: 5000,
        })
        this.deleteDialog = !this.deleteDialog
      } catch (error) {
        this.$toast.error(e.response.data.error)
        throw Error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nurse-profile-content {
  margin-top: 1%;
  margin-left: 2%;
}

.account-status {
  margin-left: 5%;
}

.nurse-profile {
  height: auto;

  .card-action {
    justify-content: left;
  }

  .card-body {
    padding: 10px;

    .text-field {
      padding-left: 4%;
      font-size: small;
      color: #212121;

      @include media-breakpoint-up(xl) {
        font-size: large;
      }
    }

    .label {
      color: gray;
      font-size: large;

      @include media-breakpoint-up(xl) {
        font-size: x-large;
      }
    }
  }

}
</style>
