<template>
  <v-card>
    <v-card-title>
      {{ `${listItem.first_name} ${listItem.last_name}'s Rating ` }}
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="reviewData"
      :items-per-page="10"
      :loading="getNurseLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:item.customer_rating="{ item }" :align="center">
        <v-rating
          dense
          half-increments
          hover
          length="5"
          size="30"
          :value="item.customer_rating"
        ></v-rating>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <p class="mt-4">
          {{ item.createdAt | setDateFormat("DD-MM-YYYY") }}
        </p>
      </template>
    </v-data-table>
    <review-form :is-editing="true" title="Edit Nurse Review" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ReviewForm from "./ReviewForm.vue";

export default {
  name: "NurseReviewDataTable",
  props: {
    reviews: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    "review-form": ReviewForm,
  },
  data() {
    return {
      search: "",
      showAddReview: false,
      isEditing: false,
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Customer review",
          align: "start",
          sortable: false,
          value: "customer_review",
        },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "customer",
        },
        {
          text: "Customer Rating",
          align: "start",
          sortable: true,
          value: "customer_rating",
        },
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getNurseLoading", "listItem"]),
    reviewData() {
      return this.reviews;
    },
  },
  methods: {
    reviewDate(date) {
      return moment(date).format("LL");
    },
    selecteReview(item) {
      return item;
    },
    editReview() {
      this.showAddReview = !this.showAddReview;
    },
  },
};
</script>

<style>
</style>