<template>
  <div>
    <v-dialog
      v-model="showJobDialog"
      persistent
      max-width="700"
      class="job-card-dialog"
    >
      <v-card :loading="loading">
        <v-img
          height="150"
          src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        >
          <v-btn icon dark @click="dismissDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-img>

        <v-card-title class="mx-3"> Job Card </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-avatar color="primary" size="40">
              <v-icon color="white darken-2"> mdi-account </v-icon>
            </v-avatar>

            <v-list-item-content class="mx-3">
              <v-list-item-title id="checking">
                {{ job.client_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ job.contact }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-avatar color="primary" size="40">
              <v-icon color="white darken-2"> mdi-package </v-icon>
            </v-avatar>
            <v-list-item-content class="mx-3">
              <v-list-item-title> Service</v-list-item-title>
              <v-list-item-subtitle>
                {{ job.service }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-avatar color="primary" size="40">
              <v-icon color="white darken-2"> mdi-calendar </v-icon>
            </v-avatar>
            <v-list-item-content class="mx-3">
              <v-list-item-title> Start Date</v-list-item-title>
              <v-list-item-subtitle>
                {{ job.start_date | setDateFormat("DD-MM-YYYY") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="nurse-list d-flex flex-row">
            <v-avatar color="primary" size="40" class="flex-grow-0">
              <v-icon color="white darken-2"> mdi-mother-nurse </v-icon>
            </v-avatar>

            <div class="nurse-chips flex-grow-1">
              <div class="ml-3">Assigned nurse(s)</div>

              <div class="d-flex flex-row flex-wrap">
                <div v-for="(item, i) in nurses" :key="i">
                  <v-chip
                    class="ma-2"
                    color="primary"
                    small
                    @click="goToNurse(item.nurse.id)"
                  >
                    <v-icon left small> mdi-account-circle-outline </v-icon>
                    <span>
                      {{ `${item.nurse.first_name}  ${item.nurse.last_name}` }}
                    </span>
                  </v-chip>
                </div>
              </div>
            </div>

            <v-btn icon @click="openNurseDialog()" class="flex-grow-0">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-avatar color="primary" size="40">
              <v-icon color="white darken-2"> mdi-human-pregnant </v-icon>
            </v-avatar>
            <v-list-item-content class="mx-3">
              <v-list-item-title> Care Services</v-list-item-title>
              <v-list-item-subtitle v-if="job.care_services">
                {{ job.care_services.toString() }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-btn color="red" outlined small @click="deleteJob()">
            Delete Job
          </v-btn>
          <v-btn
            outlined
            small
            color="red"
            @click="updateJob('cancelled')"
            v-if="showComplete"
          >
            Cancel Job
          </v-btn>
          <v-btn
            color="orange"
            outlined
            small
            @click="updateJob('ongoing')"
            v-if="showOngoing"
          >
            Start Job
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            @click="updateJob('completed')"
            v-if="showComplete"
          >
            Complete Job
          </v-btn>

          <v-btn
            color="blue"
            outlined
            small
            @click="doPrint()"
            v-if="!showComplete"
          >
            <v-icon class="mr-2"> mdi-printer </v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
      <assign-nurse-dialog
        v-model="show"
        :is-editing="false"
        title="Create a Nurse Job"
        :job="getSelectedJob"
      />
    </v-dialog>

    <div id="printMe" v-show="false">
      <v-container>
        <v-row class="mx-2 my-5">
          <v-col cols="12">
            <img
              src="@/assets/logo.png"
              alt="Teheca Logo"
              width="100"
              height="100"
            />
          </v-col>

          <v-col cols="12" class="mb-5">
            <h1>Job Card</h1>
          </v-col>

          <v-col cols="6">
            <h4>{{ job.client_name }}</h4>
            <p>{{ job.address }}</p>
          </v-col>

          <v-col cols="6" v-if="job.patient_name">
            <h6>Patient name</h6>
            <p>{{ job.patient_name }}</p>
          </v-col>

          <v-col cols="12">
            <v-divider />
          </v-col>

          <v-col cols="6">
            <h6>Service</h6>
            <p>{{ job.service }}</p>
          </v-col>

          <v-col cols="6">
            <h6>Start date</h6>
            <p>{{ job.start_date | setDateFormat("DD MMM, YYYY") }}</p>
          </v-col>

          <v-col cols="12">
            <h6>Assigned nurses</h6>
            <p>
              <span v-for="(item, i) in nurses" :key="i">
                {{ ` ${item.nurse.first_name}  ${item.nurse.last_name}` }},
              </span>
            </p>
          </v-col>

          <v-col cols="12">
            <h6>Care services</h6>
            <p v-if="job.care_services">
              {{ job.care_services.toString() }}
            </p>
            <p v-else>--</p>
          </v-col>
          <v-col cols="12">
            <h6>Physiotherapy services</h6>
            <p v-if="job.pysio_services">
              {{ job.pysio_services.toString() }}
            </p>
            <p v-else>--</p>
          </v-col>

          <v-col cols="6">
            <h6>Invoice amount</h6>
            <p v-if="job.invoice_amount">
              {{ formatCurrency(job.invoice_amount) }}
            </p>
          </v-col>

          <v-col cols="6">
            <h6>Receipt amount</h6>
            <p v-if="job.receipt_amount">
              {{ formatCurrency(job.receipt_amount) }}
            </p>
          </v-col>

          <v-col cols="6">
            <h6>Shift(s)</h6>
            <p v-if="job.shifts">
              {{ job.shifts.toString() }}
            </p>
          </v-col>

          <v-col cols="6">
            <h6>Duration</h6>
            <p>
              <span v-if="job.days"> {{ job.days }} days </span>

              <span v-if="job.nights"> ,{{ job.nights }} nights </span>
            </p>
          </v-col>

          <v-col cols="12">
            <v-divider />
          </v-col>

          <v-col cols="6">
            <p class="justify-end text-caption text-400">
              <em
                >Created on:
                {{ job.createdAt | setDateFormat("DD-MM-YYYY") }}</em
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AssignNurseDialog from "./AssignNurseDialog";
import { formatCurrency } from "../../../helpers";

export default {
  name: "JobDialog",
  components: {
    "assign-nurse-dialog": AssignNurseDialog,
  },
  data() {
    return {
      loading: false,
      show: false,
      selection: 1,
      expand: true,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedJob",
      "getJobCardNurses",
      "showJobDialog",
      "getLoadingState",
      "isInternetConnected",
    ]),
    job() {
      return this.getSelectedJob;
    },
    isPending() {
      return this.getSelectedJob.status == "pending";
    },
    isOngoing() {
      return this.getSelectedJob.status == "ongoing";
    },
    showComplete() {
      return this.isPending || this.isOngoing;
    },
    showOngoing() {
      return this.isPending;
    },
    nurses() {
      return this.getJobCardNurses ? this.getJobCardNurses : [];
    },
  },
  methods: {
    dismissDialog() {
      this.$store.commit("UPDATE_SHOW_JOB_DIALOG", false);
      this.$store.commit("UPDATE_SELECTED_JOB", {});
    },
    openNurseDialog() {
      this.show = !this.show;
      this.$store.dispatch("getAllNurses");
    },
    getColor(value) {
      if (value.toUpperCase() == "ACTIVE") return "green";
      else if (value.toUpperCase() == "PENDING") return "orange";
      else if (value.toUpperCase() == "COMPLETED") return "blue";
      else return "red";
    },
    goToNurse(id) {
      this.$router.push({ path: `/nurses/list/${id}` });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    async deleteJob() {
      const payload = {
        id: this.getSelectedJob.id,
      };
      try {
        const result = await this.$store.dispatch("deleteJob", payload);
        if (result) {
          this.dismissDialog();
          this.$toast.success("Job deleted successfully");
        }
      } catch (e) {
        this.$toast.error("Error deleting job, please refresh and try again");
        return e;
      }
    },

    async updateJob(status) {
      const payload = {
        id: this.getSelectedJob.id,
        data: { status: status },
      };

      if (this.isInternetConnected) {
        try {
          const result = await this.$store.dispatch("updateJobCard", payload);
          if (result) {
            this.dismissDialog();
            return result;
          }
        } catch (e) {
          this.$toast.error(e.response.data.error);
          return e;
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
    async doPrint() {
      const result = await this.$store.dispatch("printJobCard", {
        id: this.getSelectedJob.id,
      });
      if (result) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `care_card_${this.getSelectedJob.id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.job-card-dialog {
  position: relative;
  right: 10%;
  .cost {
    font-weight: bold;
    color: #000;
  }

  #checking:hover {
    cursor: pointer;
  }
}

#printMe {
  width: 90%;
}
</style>
