/* eslint-disable no-unused-vars */
import { api } from '@/utils/api'
import { size } from 'lodash'

/**
 * Call api for user data
 * @method getListItems
 * @param {*} param0
 * @param {*} payload
 */
const getListItems = async (
  { commit, state, dispatch, rootState },
  payload
) => {
  const { complete, loaded, config, url } = payload

  if (rootState.listType === 'nurse') {
    commit('UPDATE_NURSE_LOADING', true)
  }
  const options = {
    params: {
      ...config,
    },
  }
  try {
    const result = await api.get(url, options)
    if (result.statusText === 'OK') {
      const resultListType = result.data.dataType
      const { data } = result.data

      if (typeof resultListType !== 'undefined') {
        commit('UPDATE_LIST_TYPE', resultListType)
      }

      if (data.length) {
        const list =
          config.offset + 1 > state.limitRequestSize
            ? [...state.dataListItems, ...data]
            : data

        commit('UPDATE_NURSE_LOADING', false)
        commit('UPDATE_DATA_LIST_ITEMS', list)

        // if (rootState.listType === 'customer') {
        //   commit('UPDATE_DATA_LIST_ITEMS', list)
        // } else if (rootState.listType === 'nurse') {
        //   let nurses = approved
        //     ? list.filter((item) => item.verified === true)
        //     : list.filter((item) => item.verified === false)

        //   commit('UPDATE_DATA_LIST_ITEMS', nurses)
        //   commit('UPDATE_NURSE_LOADING', false)
        // }

        loaded()
        if (data.length !== state.limitRequestSize) {
          complete()
        }
        return
      }
      complete()
    }
  } catch (error) {
    // if (error.response.data.reason) {
    //   dispatch('removeToken')
    // }
    throw error
  }
}

/**
 * @param {*} params
 * @param {*} payload
 */
const loginUser = async ({ commit, dispatch }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  try {
    const result = await api.post('/auth/login', payload.data)
    if (result) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (error) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw error
  }
}

/**
 * @param {*} params
 * @param {*} payload
 */
const verify_code = async ({ commit, dispatch }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  try {
    const result = await api.post('/auth/verify', payload.data)
    if (result) {
      const { data } = result.data
      commit('UPDATE_LOADING_DATA_STATUS', false)
      commit('SET_TEHECA_AUTH', data)
      commit('ISLOGGEDIN', true)
      const currentAdmin = data.user
      commit('SET_CURRENT_ADMIN', currentAdmin)
      dispatch('setToken')
    }
    return result
  } catch (error) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw error
  }
}

/**
 * Upload image
 * @param {*} param0
 * @param {*} payload
 */

const uploadPicture = async ({ state }, payload) => {
  try {
    const result = await api.post(payload.url, payload)
    if (result) {
      return result
    }
  } catch (e) {
    throw e
  }
}
/**
 * Search call for nurse and customer
 * @method search
 * @param {Object} commit
 * @param {Object} payload
 */
const search = async ({ commit, state }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const options = {
    params: payload,
  }

  try {
    // if (results) {
    //   commit('UPDATE_IS_SEARCH', true)
    //   commit('UPDATE_DATA_LIST_SEARCH_ITEMS', results)
    //   commit('UPDATE_LOADING_DATA_STATUS', false)
    //   return results
    // }

    /* used to filter local list
     const results = state.dataListItems.filter((user) => {
       if (state.listType === 'nurse') {
         return [
           user.first_name,
           user.last_name,
           user.phone_number,
           user.email,
           user.address,
         ]
           .filter((x) => x)
           .join(', ')
           .includes(payload.searchTerm)
       }
       return [
         user.firstname,
         user.lastname,
         user.phonenumber,
         user.email,
         user.address,
       ]
         .filter((x) => x)
         .join(', ')
         .includes(payload.searchTerm)
     })
 
     if (results) {
       commit('UPDATE_IS_SEARCH', true)
       commit('UPDATE_DATA_LIST_SEARCH_ITEMS', results)
       commit('UPDATE_LOADING_DATA_STATUS', false)
     }*/
    const result = await api.get('/portal/search', options)
    const { data } = result.data

    if (data.length) {
      commit('UPDATE_IS_SEARCH', true)
      commit('UPDATE_DATA_LIST_SEARCH_ITEMS', data)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
    return result
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}
/**
 * Call to send user message
 * @method sendUserMessage
 * @param {Object} rootState
 * @param {Object} payload
 */
const sendUserMessage = async ({ rootState }, payload) => {
  const url = `/customer/${rootState.selectedId}/message`
  try {
    const result = await api.post(url, {
      ...payload,
      ...{ to: rootState.listItem.fcm_token },
    })
    if (result) {
      return result
    }
  } catch (e) {
    throw e
  }
}
const fetchIncomeTrace = async ({ commit, rootState }, payload) => {
  const options = {
    params: {
      offset: size(rootState.income_trace),
      limit: rootState.limitRequestSize,
    },
  }
  commit('UPDATE_LOADING_DATA_STATUS', true)

  const url = '/income_trace'
  try {
    const result = await api.get(url, options)
    if (result.data.status === 'success') {
      const { data } = result.data
      const income_trace = [...rootState.income_trace, ...data.rows]
      commit('UPDATE_INCOME_TRACE', income_trace)
      commit('UPDATE_COUNT', data.count)
      commit('UPDATE_LOADING_DATA_STATUS', false)

      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

/**
 * This action deletes the
 * @param {*} param0
 * @param {*} payload
 */
const deleteProfile = async ({ commit, rootState }, payload) => {
  try {
    return await api.delete(`/resume/profile/${payload.id}`)
  } catch (error) {
    throw Error(error)
  }
}

const changeAccountStatus = async ({ commit, rootState }, payload) => {
  try {
    if (!payload.update) {
      const res = await api.post(`/careteam/account/blockAccount`, payload)
      commit('UPDATE_ACCOUNT_STATUS', res.data.result)
    } else {
      const res = await api.put(`/careteam/account/blockAccount`, payload)

      commit('UPDATE_ACCOUNT_STATUS', payload)
    }
  } catch (error) {
    throw Error(error)
  }
}

const checkAccountStatus = ({ commit, rootState }, payload) => {
  return 'TESTING'
  // try {
  //   // const res = await api.get(`/careteam/account/blockAccount`)
  //   // commit('UPDATE_ACCOUNT_STATUS', { status: 'TESTING' })
  //   console.log(payload)
  //   return 'TESTING'
  // } catch (error) {
  //   throw Error(error)
  // }
}

export {
  checkAccountStatus,
  changeAccountStatus,
  fetchIncomeTrace,
  getListItems,
  loginUser,
  search,
  sendUserMessage,
  uploadPicture,
  verify_code,
  deleteProfile,
}
