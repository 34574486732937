<template>
  <v-card class="order-card">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orderItems"
      class="elevation-2 data"
      :search="search"
      :loading="loading"
      loading-text="Loading orders... Please wait"
    >
      <template v-slot:[`item.orderNumber`]="{ item }">
        <p class="font-weight-black pt-3">
          {{ item.orderNumber }}
        </p>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-btn text medium :color="getColor(item.status)">
          {{ item.status }}
        </v-btn>
      </template>

      <template v-slot:[`item.total`]="{ item }" class="amount">
        <p class="text-justify pt-3">
          {{ formatAmount(item.total) }}
        </p>
      </template>
      <template v-slot:[`item.process`]="{ item }">
        <v-btn small color="primary" @click="goToDetails(item)">
          View Order
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatCurrency, orderStates } from "../../helpers";
import { mapGetters } from "vuex";
const { CONFIRMED, RECEIVED, PENDING, COMPLETED, CANCELLED } = orderStates;

export default {
  name: "OrderDataTable",
  props: {
    requests: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "Care Request",
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Order NO",
          value: "orderNumber",
        },

        {
          text: "Contact",
          align: "left",
          sortable: false,
          value: "contact",
        },
        {
          text: "Order Date",
          value: "dateAdded",
        },
        {
          text: "Amount(UGX)",
          value: "total",
          sortable: true,
        },
        {
          text: "Payment",
          value: "paymentMethod",
        },
        {
          text: "Delivery",
          value: "deliveryMethod",
        },
        {
          text: "Status",
          value: "status",
        },
        { text: "Manage", value: "process", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingState",
    }),
    orderItems() {
      return this.requests;
    },
    showLoading() {
      return this.loading;
    },
  },
  methods: {
    getColor(value) {
      if (value === CANCELLED) return "red";
      else if (value === PENDING) return "orange";
      else if (value === CONFIRMED) return "blue";
      else if (value === RECEIVED || value == COMPLETED) return "green";
      else return "pink";
    },
    formatAmount(amount) {
      if (amount === null || amount === undefined) {
        return "0.0";
      }
      return formatCurrency(amount);
    },
    async goToDetails(item) {
      const payload = { id: item.userid };
      try {
        const result = await this.$store.dispatch(
          "fetchSelectedCustomer",
          payload
        );
        if (result) {
          const action = { path: `/shop/orders/${item.id}` };
          this.$router.push(action);
        }
      } catch (e) {}
    },
  },
};
</script>
