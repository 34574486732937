/* eslint-disable no-unused-vars */
import { api } from '@/utils/api'
import { size } from 'lodash'

/**
 * Send push notification
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<void>}
 */

const sendPushNotification = async ({ state }, payload) => {
  let url =
    payload.messageType === 'all'
      ? '/sendcomms/sendNotificationToTopic'
      : '/sendcomms/send_to_one_user'
  try {
    const result = await api.post(url, payload)
    if (result) {
      return result
    }
  } catch (error) {
    throw error
  }
}

/**
 * Calls the action to get sent notification
 * @param {*} param0
 * @param {*} payload
 */
const fetchData = async ({ state, commit, rootState }, payload) => {
  const request = {
    urlParams: {
      offset: size(state.messageList),
      limit: rootState.limitRequestSize,
    },
    url: '/sendcomms/sendNotificationToTopic',
    ...payload,
  }
  const { complete, loaded, url, urlParams } = request

  const options = {
    params: urlParams,
  }
  try {
    const result = await api.get(url, options)
    const { data } = result.data
    if (data.length) {
      const list = [...state.messageList, ...data]
      commit('UPDATE_PUSH_NOTIFICATION_LIST', list)
      loaded()
      if (data.length !== rootState.limitRequestSize) {
        complete()
      }
      return
    }
    complete()
  } catch (error) {
    throw error
  }
}

/**
 * Save health tip action.
 *
 * @method saveHealthTip
 * @param {*} param
 * @param {*} payload
 */
const saveHealthTip = async ({ state, commit }, payload) => {
  try {
    const result = await api.post('/healthtips', payload)
    if (result) {
      commit('UPDATE_CARE_TIPS', [...state.careTipsLists, ...[result.data]])
      return result
    }
  } catch (error) {
    throw error
  }
}
/**
 * Calls the action to get fetch health tips
 * @method loadCareTips
 * @param {*} state
 * @param {*} commit
 * @param {*} rootState
 * @param {Object} payload
 */
const loadCareTips = async ({ state, commit, rootState }, payload) => {

  const request = {
    urlParams: {
      offset: size(state.careTipsLists),
      limit: rootState.limitRequestSize,
    },
    url: '/healthtips',
    ...payload,
  }
  const { complete, loaded, url, urlParams } = request

  const options = {
    params: urlParams,
  }
  try {
    const result = await api.get(url, options)
    const { data } = result
    if (data.length) {
      const list = [...state.careTipsLists, ...data]
      console.log(list.length)
      commit('UPDATE_CARE_TIPS', list)
      loaded()
      if (data.length !== rootState.limitRequestSize) {
        complete()
      }
      return
    }
    complete()
  } catch (error) {
    throw error
  }
}

/**
 * Update how many times this tip has been sent
 * @method updateCount
 * @param {*} param0
 * @param {*} payload
 * @returns Promise
 */

const updateCount = async ({ state }, payload) => {
  try {
    const result = await api.put(`/healthtips/${payload.id}`, payload)
    if (result) {
      return result
    }
  } catch (error) {
    throw error
  }
}

export {
  sendPushNotification,
  fetchData,
  loadCareTips,
  saveHealthTip,
  updateCount,
}
