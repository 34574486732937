<template>
<router-view/>
</template>

<script>
export default {
  name: "ShopComponent",

};
</script>
