<template>
    <v-dialog v-model="getDeleteCustomerDialogStatus" width="500" persistent>
        <v-card>
            <v-card-title class="text-h5 warning" color="white">
                {{ title }}
            </v-card-title>

            <v-card-text class="text-h6">
                <br />
                {{ message }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" text @click="confirmAction()">
                    {{ okbuttonText }}
                </v-btn>
                <v-btn color="primary" text @click="hideDialog()">
                    {{ cancelButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "DeleteDialogComponent",
    props: {
        title: {
            type: String,
            default: "Attention!"
        },
        message: {
            type: String,
            default: "Are you sure you want to delete this profile"
        },
        okbuttonText: {
            type: String,
            default: "Yes, I'm sure"
        },
        cancelButtonText: {
            type: String,
            default: "No, cancel"
        }
    },
    data() {
        return {
            showDialog: false,
        }
    },
    computed: {
        ...mapGetters(['listItem', 'listType', 'getDeleteCustomerDialogStatus']),
    },
    methods: {
        hideDialog() {
            this.$store.commit('UPDATE_DELETE_CUSTOMER_DIALOG_STATUS', false)
        },
        async confirmAction() {
            try {
                let result
                if (this.listType == 'nurse') {
                    result = await this.$store.dispatch('deleteNurse', this.listItem)

                } else if (this.listType == 'customer') {
                    result = await this.$store.dispatch('deleteCustomer', this.listItem)

                }
                if (result) {
                    this.$toast.success("Task completed successfully", {
                        color: "success",
                        queueable: true,
                        timeout: 5000,
                    });
                    this.$store.commit('UPDATE_DELETE_CUSTOMER_DIALOG_STATUS', false)
                }
            } catch (error) {
                this.$toast.error("error processing request");
                throw Error(error)
            }

        }
    }
}
</script>