
const state = {
    selectedJob: {},
    jobCards: [],
    customerJobs: [],
    jobCardNurses: [],
    showJobDialog: false,
    showUpdateJobDialog: false
}

export {
    state
}
