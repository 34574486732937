
const state = {
    customerList: [],
    customerOrderCount: 0,
    customerOrders: [],
    deleteCustomerDialogStatus: false,
    nurseActiveTab: 0
}

export {
    state
}
