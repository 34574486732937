
const getSelectedJob = state => state.selectedJob

const getJobCards = state => state.jobCards

const showJobDialog = state => state.showJobDialog

const getCustomerJobs = state => state.customerJobs

const getJobCardNurses = state => state.jobCardNurses

const getUpdateJobDialogStatus = state => state.showUpdateJobDialog

export {
    getJobCards,
    getSelectedJob,
    getCustomerJobs,
    getJobCardNurses,
    showJobDialog,
    getUpdateJobDialogStatus
}
