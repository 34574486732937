// import { orderStates } from '../../../helpers'
// const { CONFIRMED, RECEIVED, PENDING } = orderStates

const UPDATE_JOB_CARD_LIST = (state, payload) => {
    state.jobCards = payload
}

const UPDATE_SELECTED_JOB = (state, payload) => {
    state.selectedJob = payload
}

const UPDATE_SHOW_JOB_DIALOG = (state, payload) => {
    state.showJobDialog = payload
}

const UPDATE_CUSTOMER_JOBS = (state, payload) => {
    state.customerJobs = payload
}

const UPDATE_JOBCARD_NURSES = (state, payload) => {
    state.jobCardNurses = payload
}

const SHOW_UPDATE_JOB_DIALOG = (state, payload) => {
    state.showUpdateJobDialog = payload
}

export {
    UPDATE_JOB_CARD_LIST,
    UPDATE_SELECTED_JOB,
    UPDATE_CUSTOMER_JOBS,
    UPDATE_JOBCARD_NURSES,
    UPDATE_SHOW_JOB_DIALOG,
    SHOW_UPDATE_JOB_DIALOG
}
