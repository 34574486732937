<template>
  <section class="nurse-container">
    <v-row>
      <v-col xs="12" sm="2" md="2" lg="3" xl="2">
        <div class="nurse-list">
          <v-progress-linear
            indeterminate
            color="primary"
            class="tab-width"
            v-if="loading"
          ></v-progress-linear>

          <v-tabs v-model="tab" class="elevation-1 tab-width" light>
            <v-tab @change="getApprovedNurses">
              <v-icon> mdi-check </v-icon>
              Approved
            </v-tab>
            <v-tab @change="getUnApprovedNurses">
              <v-icon> mdi-account-alert </v-icon>
              Unapproved
            </v-tab>

            <v-tab-item>
              <user-lists
                :infinite-handler="getApprovedNurseList"
                v-if="verified"
              />
            </v-tab-item>

            <v-tab-item>
              <user-lists
                :infinite-handler="getUnapprovedNurseList"
                v-if="!verified"
              />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
      <v-col xs="12" sm="10" md="10" lg="9" xl="10" class="nurse-profile">
        <router-view />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { UserLists } from "@/components/common";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NurseContent",
  components: {
    UserLists,
  },
  data() {
    return {
      show: false,
      tab: null,
      centered: true,
      grow: true,
      verified: true,
    };
  },
  computed: {
    ...mapGetters({
      nurse: "listItem",
      listType: "listType",
      loading: "getNurseLoading",
      isSearch: "getIsSearch",
    }),
    selectedListType() {
      return this.listType;
    },
  },
  updated() {
    if (this.nurse) {
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  methods: {
    ...mapActions([
      "getNurseLists",
      "getApprovedNurseList",
      "getUnapprovedNurseList",
    ]),
    selectedMessage(id) {
      this.$store.commit("RESET_SELECTED_ITEM_DETAILS");
      this.$store.commit("UPDATE_SELECTED_ID", id);
      this.show = true;
    },
    getApprovedNurses() {
      this.$store.commit("UPDATE_DATA_LIST_ITEMS", []);
      this.verified = true;
    },
    getUnApprovedNurses() {
      this.$store.commit("UPDATE_DATA_LIST_ITEMS", []);
      this.verified = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nurse-container {
  margin-left: -2.5%;
  margin-top: -1%;
  .tab-width {
    width: 300px;
  }

  .nurse-list {
    margin-left: -12px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 56px;
  }
}

.nurse-profile {
  height: 90vh;
  overflow-y: scroll;
}
</style>
