<template>
  <div class="drop-menue">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn :color="buttonColor" text tile outlined v-on="on">
          {{ value }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in actions"
          :key="index"
          @click="handleAction(item)"
        >
          <v-list-item-title color="black">
            {{ item }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Dropdown",
  props: {
    options: Array,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["isInternetConnected", "listItem", "getSelectedId"]),
    actions() {
      return this.options;
    },
    buttonColor() {
      return this.listItem.verified === true ? "primary" : "error";
    },
    value() {
      return this.listItem.verified === true ? "Approved" : "Pending approval";
    },
  },
  methods: {
    async handleAction(action) {
      if (this.isInternetConnected) {
        const status = action === "Approve";
        try {
          const result = await this.$store.dispatch("updateNurseStatus", {
            verified: status,
            profileId: this.$route.params.id,
          });

          if (result.data.status === "success") {
            this.$forceUpdate();
            this.$toast.success("Nurse verified");
          } else if (result.data.status === "failed") {
            this.$toast.error(result.data.error);
          }
        } catch (ex) {
          this.$toast.error(ex.response.data.error);
        }
      } else {
        this.$toast.error("No internet connection");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drop-menu {
  text-align: left;
  margin-left: 10%;
}
</style>
