
/**
 * Mutation to update the list of sent push notifications
 * @param state
 * @param payload
 *
 */
const UPDATE_PUSH_NOTIFICATION_LIST = (state, payload) => {
    state.messageList = payload
}

/**
 * Mutation to update the list of care tips
 * @param {*} state
 * @param {*} payload
 */
const UPDATE_CARE_TIPS = (state, payload) => {
    state.careTipsLists = payload
}

export {
    UPDATE_PUSH_NOTIFICATION_LIST,
    UPDATE_CARE_TIPS
}
