import { orderStates } from '../../../helpers'
const { CONFIRMED, RECEIVED, PENDING, CANCELLED } = orderStates

const UPDATE_ORDER_LIST = (state, payload) => {
  if (payload.status === CONFIRMED) {
    state.confirmedOrders = payload.orders
  }
  if (payload.status === PENDING) {
    state.pendingOrders = payload.orders
  }
  if (payload.status === RECEIVED) {
    state.receivedOrders = payload.orders
  }
  if (payload.status === CANCELLED) {
    state.cancelledOrders = payload.orders
  }
}

const UPDATE_SELECTED_ORDER = (state, payload) => {
  state.selectedOrder = payload
}

const UPDATE_SELECTED_CUSTOMER = (state, payload) => {
  state.selectedCustomer = payload
}

export {
  UPDATE_ORDER_LIST,
  UPDATE_SELECTED_ORDER,
  UPDATE_SELECTED_CUSTOMER,
}
