<template>
  <div>
    <div class="bookings-content">
      <v-tabs
        v-model="tab"
        class="elevation-1"
        light
        :centered="centered"
        :grow="grow"
      >
        <v-tabs-slider />

        <v-tab>New Bookings</v-tab>
        <v-tab>On Going Booking</v-tab>
        <v-tab>Completed</v-tab>
        <v-tab>Job Cards</v-tab>
        <v-tab-item>
          <data-tables
            :total="totalNewBookings"
            :loading="loading"
            :requests="newBookingsList"
            title="New/latest bookings"
          />
        </v-tab-item>
        <v-tab-item>
          <data-tables
            :total="totalOnGoingBookings"
            :loading="loading"
            :requests="activeBookingsList"
            title="OnGoing bookings"
          />
        </v-tab-item>
        <v-tab-item>
          <data-tables
            :total="totalCompletedBookings"
            :loading="loading"
            :requests="completedBookingsList"
            title="Completed/Cancelled bookings"
          />
        </v-tab-item>
        <v-tab-item>
          <job-data-table :jobs="getJobCards" title="Job Cards" />
        </v-tab-item>
      </v-tabs>
    </div>
   <router-view />
  </div>
</template>
<script>
import DataTables from "./section/DataTable";
import JobDataTable from "./section/JobDataTable";
import { size } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Booking",
  components: {
    "data-tables": DataTables,
    "job-data-table": JobDataTable,
  },
  data() {
    return {
      tab: null,
      icons: false,
      centered: false,
      grow: false,
      right: false,
      totalOnGoingBookings: 0,
      totalNewBookings: 0,
      totalCompletedBookings: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      "newBookingsList",
      "activeBookingsList",
      "completedBookingsList",
      "getJobCards",
    ]),
  },
  watch: {},
  created() {
    this.fetchBookingList();
    this.fetchActiveBookingList();
    this.fetchNewBookingList();
    this.$store.dispatch("getJobCardList");
  },
  methods: {
    async fetchActiveBookingList() {
      let payload = {
        status: "active",
      };
      const bookings = await this.getBookings(payload);
      if (size(bookings)) {
        this.totalOnGoingBookings = bookings.length;
        this.$store.commit("UPDATE_BOOKING_LIST", {
          ...payload,
          ...{ bookings },
        });
      }
    },
    async fetchNewBookingList() {
      let payload = {
        status: "new",
      };
      const bookings = await this.getBookings(payload);
      if (size(bookings)) {
        this.totalNewBookings = bookings.length;
        this.$store.commit("UPDATE_BOOKING_LIST", {
          ...payload,
          ...{ bookings },
        });
      }
    },
    async fetchBookingList() {
      let payload = {
        status: "completed",
      };
      const bookings = await this.getBookings(payload);
      if (size(bookings)) {
        this.totalCompletedBookings = bookings.length;
        this.$store.commit("UPDATE_BOOKING_LIST", {
          ...payload,
          ...{ bookings },
        });
      }
    },

    async getBookings(payload) {
      this.loading = !this.loading;
      try {
        const result = await this.$store.dispatch("getBookings", payload);
        if (result) {
          this.loading = false;
          return result.data.data;
        }
      } catch (e) {
        return [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bookings-content {
}
</style>
