<template>
	<div class="drop-menu">
		<v-menu offset-y>
			<template v-slot:activator="{ on }">
				<v-btn color="white" dark tile outlined v-on="on">
					{{ getStatus }}
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-for="(item, index) in actions"
					:key="index"
					@click="handleAction(item)"
				>
					<v-list-item-title color="black">
						{{ item }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "ShopDropdown",
	props: {
		value: {
			type: String,
			default: "Placed"
		},
		order: {
			type: Object,
			default: {}
		},
		options: {
			type: Array,
			default: ["Pending"]
		}
	},
	data: () => ({}),
	computed: {
		actions() {
			return this.options;
		},
		getStatus() {
			return this.value;
		},
		selectedOrder() {
			return this.order;
		}
	},
	methods: {
		async handleAction(action) {
			const payload = {
				id: this.$router.history.current.params.id,
				data: {
					key: "status",
					value: action,
					user: this.selectedOrder.userid
				}
			};
			try {
				const result = await this.$store.dispatch("updateOrder", payload);
				if (result) {
					return result;
				}
			} catch (e) {
				return e;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.drop-menu {
	text-align: left;
	margin-left: 10%;
}
</style>
