const state = {
  isSearch: false,
  currentUser: JSON.parse(localStorage.getItem('currentUser')) || {},
  showDialog: false,
  showDrawer: false,
  isAuthenticated: false,
  noResultsFound: false,
  showSendMsgDiaglog: false,
  searchListDataItems: [],
  careTeamList: [],
  dataListItems: [],
  customerList: [],
  nurseList: [],
  searchList: [],
  booking: {},
  listItem: {},
  listType: '',
  loadingState: false,
  title: '',
  selectedId: '',
  currentLoggedIn: '',
  limitRequestSize: 40,
  isConnected: true,
  fetchApproved: true,
  currentAdmin: '',
  income_trace: [],
  count: 0,
  account_status: { status: 'ACTIVE', user_id: null },
}

export { state }
