import { size } from 'lodash'
import { api } from '../../../utils/api'

const getJobCardList = async ({ state, rootState, dispatch, commit }) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/portal/jobcard'
  const options = {
    params: {
      offset: size(state.orderList),
      limit: rootState.limitRequestSize,
    },
  }

  try {
    const result = await api.get(url, options)
    if (result) {
      commit('UPDATE_LOADING_DATA_STATUS', false)
      commit('UPDATE_JOB_CARD_LIST', result.data.data)
      return result
    }
  } catch (error) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    if (error.response.data.reason) {
      dispatch('removeToken')
    }
    throw e
  }
}

const getCustomerJobList = async ({ state, rootState, commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/customer/${payload.id}`
  const options = {
    params: {
      offset: size(state.customerJobs),
      limit: rootState.limitRequestSize,
    },
  }

  try {
    const result = await api.get(url, options)
    if (result) {
      const { data } = result.data
      commit('UPDATE_CUSTOMER_JOBS', data)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const createJobCard = async ({ commit, state }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = '/portal/jobcard'

  try {
    const result = await api.post(url, payload)
    if (result) {
      let data = [result.data]
      const list = [...state.jobCards, ...data]
      commit('UPDATE_JOB_CARD_LIST', list)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const updateJobCard = async ({ commit, dispatch }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/${payload.id}`
  try {
    const result = await api.put(url, payload.data)
    if (result) {
      // const order = result.data.data
      commit('UPDATE_LOADING_DATA_STATUS', false)
      dispatch('getJobCardList')
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

const deleteJob = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/${payload.id}`
  try {
    return await api.delete(url)
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }

}

const deleteNurseJob = async ({ commit }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/nurse/${payload.id}`
  try {
    return await api.delete(url)
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }

}

const getJobCardNurses = async ({ commit, rootState, state }, payload) => {
  commit('UPDATE_LOADING_DATA_STATUS', true)
  const url = `/portal/jobcard/${payload.id}/nurses`

  const options = {
    params: {
      offset: size(state.jobCardNurses),
      limit: rootState.limitRequestSize,
    },
  }

  try {
    const result = await api.get(url, options)
    if (result) {
      const { data } = result.data
      commit('UPDATE_JOBCARD_NURSES', data)
      commit('UPDATE_LOADING_DATA_STATUS', false)
      return result
    }
  } catch (e) {
    commit('UPDATE_LOADING_DATA_STATUS', false)
    throw e
  }
}

export {
  getJobCardList,
  updateJobCard,
  deleteNurseJob,
  deleteJob,
  createJobCard,
  getJobCardNurses,
  getCustomerJobList,
}
