<template />
<script>
export default {
    name: 'Admin'

}
</script>
<style scoped>

</style>
