/* eslint-disable prefer-destructuring */
import { split } from 'lodash'

/**
 * Uses the file Reader such that file's data is a base64 encoded string
 * @method fileProcessor
 * @param  String fileName file name
 * @param  Object file file properties
 * @return {void}
 */

const fileProcessor = (fileName, file) => new Promise((resolve, reject) => {
    const fileType = split(file.type, '/')[1]
    const reader = new FileReader()
    reader.onload = () => {
        if (reader.result) {
            const payload = {
                image: {
                    filename: fileName + '.' + fileType,
                    data: split(reader.result, ',')[1]
                }
            }
            resolve({
                encoded: reader.result,
                data: payload
            })
        }

        if (reader.error) {
            reject(reader.error)
        }
    }
    reader.readAsDataURL(file)
})

export {
    fileProcessor
}
