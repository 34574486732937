<template>
  <div>
    <v-card class="order-card">
      <v-card-title>
        <v-row>
          <v-col cols="5" md="4">
            {{ title }}
          </v-col>
          <v-col cols="6"  md="6">
            <v-text-field v-model="search" label="Search" single-line hide-details />
          </v-col >
          <v-col cols="1" md="1" >
            <v-btn color="primary" block class="mb-2" @click="addNurseJob()"  >
              Add Job
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="jobItems" class="elevation-2 data" :search="search">
        <template v-slot:item.start_date="{ item }">
          <p class="mt-4">
            {{ item.start_date | setDateFormat("DD-MM-YYYY") }}
          </p>
        </template>

        <template v-slot:item.end_date="{ item }">
          <p class="mt-4">
            {{ item.end_date | setDateFormat("DD-MM-YYYY") }}
          </p>
        </template>

        <template v-slot:item.status="{ item }">
          <v-btn outlined small :color="getColor(item.status)">
            {{ item.status }}
          </v-btn>
        </template>
        <template v-slot:item.duration="{ item }">
          <v-btn text medium> {{ item.duration }} Days </v-btn>
        </template>
        <template v-slot:item.rate="{ item }">
          <v-btn text large color="black" class="special-font">
            {{ jobAmount(item.rate) }}
          </v-btn>
        </template>
        <template v-slot:item.amount="{ item }">
          <v-btn text large color="black" class="special-font">
            {{ jobAmount(item.amount) }}
          </v-btn>
        </template>
        <template v-slot:item.payment_status="{ item }">
          <v-btn outlined small :color="paymentColor(item.payment_status)" class="special-font">
            {{ item.payment_status }}
          </v-btn>
        </template>
        <template v-slot:item.process="{ item }">
          <v-btn small depressed color="warning" @click="viewJob(item)" class="special-font">
            <v-icon left>mdi-pencil </v-icon>
            Edit
          </v-btn>
        </template>
        <template v-slot:item.review="{ item }">
          <v-rating dense half-increments hover length="5" size="30" :value="item.rating"
            v-if="item.rating != null"></v-rating>
          <v-btn small depressed color="primary" @click="rateJob(item)" class="special-font" v-else>
            <v-icon left>mdi-star</v-icon>
            Rate
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <assign-nurse-dialog v-model="showDialog" :is-editing="isEditing" :title="dialogTitle" :job="selectedJob"
      :nurse="selectedNurse" :is-add-job="isAddJob"/>
    <review-form v-model="showAddReview" :is-editing="false" title="Add Nurse Review" :job="selectedJob" />
  </div>
</template>
<script>
import { formatDate, formatCurrency } from "../../helpers";
import { mapGetters } from "vuex";
import AssignNurseDialog from "../bookings/section/AssignNurseDialog";
import ReviewForm from "./ReviewForm.vue";

export default {
  name: "NurseJobDataTable",
  components: {
    "assign-nurse-dialog": AssignNurseDialog,
    "review-form": ReviewForm,
  },
  props: {
    title: {
      type: String,
      default: "My Jobs",
    },
    jobs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      job: {},
      isEditing: false,
      isAddJob: false, 
      showDialog: false,
      dialogTitle: "Add Nurse Job",
      headers: [
        {
          text: "Job Id",
          value: "jobId",
        },
        {
          text: "Start Date",
          value: "start_date",
        },

        {
          text: "End Date",
          value: "end_date",
        },
        {
          text: "Duration",
          value: "duration",
        },
        {
          text: "Daily Rate",
          value: "rate",
        },
        {
          text: "Nurse Pay",
          value: "amount",
        },

        {
          text: "Job Status",
          value: "status",
        },
        {
          text: "Pay Status",
          value: "payment_status",
        },
        { text: "Review", value: "review", sortable: false },
        { text: "Manage", value: "process", sortable: false },
      ],
      showAddReview: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedJob: "getSelectedJob",
      selectedNurse: "listItem",
    }),
    showLoading() {
      return this.loading;
    },
    jobItems() {
      return this.jobs;
    },
  },

  methods: {
    addNurseJob() {
      this.isEditing = false
      this.showDialog = true;
      this.dialogTitle = "Add Nurse Job"
      this.isAddJob = true
      
    },
    paymentColor(status) {
      return status == "Unpaid" ? "error" : "primary";
    },
    getColor(value) {
      if (value === "cancelled") return "red";
      else if (value === "pending") return "orange";
      else if (value === "ongoing") return "blue";
      else if (value === "completed") return "green";
      else return "blue";
    },
    formatDate(value) {
      return formatDate(value);
    },
    jobAmount(amount) {
      return formatCurrency(amount);
    },
    viewJob(item) {
      this.showDialog = !this.showDialog;
      this.isEditing = true
      this.isAddJob = false
      this.dialogTitle = "Editing Nurse Job"
      this.$store.commit("UPDATE_SELECTED_JOB", item);
    },
    rateJob(item) {
      this.showAddReview = true;
      this.$store.commit("UPDATE_SELECTED_JOB", item);
    },
  },
};
</script>
<style scoped lang="scss">
.special-font {
  font-weight: bold;
}
</style>
