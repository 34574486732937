<template>
  <router-view />
</template>
<script>
export default {
  name: 'Finance',
  created() {
    this.fetchIncomeTrace();
  },
  methods: {
    fetchIncomeTrace() {
      this.$store.dispatch("fetchIncomeTrace");
    },
  },
}
</script>