import moment from 'moment'

/**
 * Formats the trip date e.g Jan, 1, 1900
 * @method formatTripDate
 * @param  {String} value date to format
 * @return {String}
 */
const formatDateTime = (value, formatType, timestamp = true) => {
    if (moment(value).isValid() && typeof value !== 'undefined') {
        const dateTime = timestamp === true ? moment.unix(value).toDate() : value
        return moment(dateTime).format(formatType)
    }

    return 'Not available'
}

/**
 * @param {String} value
 * @param {String} formatType
 * @param {boolean} [timestamp=true]
 * @returns String
 */
const formatLocalTime = (value, formatType, timestamp = true) => {
    if (moment(value).isValid() && typeof value !== 'undefined') {
        let dateTime = timestamp === true ? moment.unix(value).toDate() : value
        return moment(dateTime).utcOffset('+3000').format(formatType)
    }
    return 'Not available'
}

/**
 * Format date to given format
 * @method setDateFormat
 * @param {String} value
 * @param {String} formatType
 * @returns {Object} Date with time
 */
const setDateFormat = (value, formatType) => {
    if (value !== null || value !== undefined) {
        return moment(value).format(formatType)
    }
    return 'Not Available'
}
/**
 * Formats a phone number.
 * Example: 123-456-7890 => (123) 456-7890
 *
 * @param {String} phone
 * @return {Void}
 */

const formatPhoneNumber = (phone) => {
    if (typeof phone !== 'undefined' && phone != null) {
        return phone.replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }
}
const capitalize = (value) => {
    if (!value) {
        return 'NA' 
    }
    return value.toUpperCase()
}

export {
    formatDateTime,
    formatLocalTime,
    setDateFormat,
    formatPhoneNumber,
    capitalize
}
