const getPendingOrders = (state) => state.pendingOrders

const getConfirmedOrders = (state) => state.confirmedOrders

const getReceivedOrders = (state) => state.receivedOrders

const getCancelledOrders = (state) => state.cancelledOrders

const getSelectedOrder = (state) => state.selectedOrder

const getSelectedCustomer = (state) => state.selectedCustomer

export {
  getPendingOrders,
  getConfirmedOrders,
  getReceivedOrders,
  getSelectedOrder,
  getSelectedCustomer,
  getCancelledOrders,
}
