const state = {
  nurseList: [],
  nurseJobList: [],
  loadingNurses: false,
  showAddNurseDialog: false,
  nurseReviews: [],
  nurse_id: '',
  showReviewDialog: false,
}

export { state }
