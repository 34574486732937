/**
 * Retrive the token saved as in localstorage
 * @method token
 * @param  {Object} state vuex state object
 * @return {Object} Contains the country code, user role etc
 */
const currentCountryCode = (state) => state.token.country_code

/**
 * GET THE LOADING STATE,
 * Whether to show the loading or not.
 * @param {*} state
 */
const getLoadingState = (state) => state.loadingState
/**
 * Get current selected id
 * @param {*} state
 */
const getSelectedId = (state) => state.selectedId

/**
 * Get Authentication state
 * @param {*} state
 */
const checkIsAuthenticated = (state) => state.isAuthenticated

/**
 * Holds data assigned from user lists and nurse lists for use in
 * the infiitescroller
 * @param {*} state
 */
const getDataListItems = (state) => state.dataListItems
/**
 * Holds data assigned from user lists and nurse lists for use in
 * @param {*} state
 * @method getDataListSearchItems
 */
const getDataListSearchItems = (state) => state.searchListDataItems

/**
 * Determine whether a search funtion is happening
 * @param {*} state
 */
const getIsSearch = (state) => state.isSearch

/**
 * [noResults description]
 * @method noResults
 * @param  {[type]}  state [description]
 * @return {[type]}        [description]
 */
const noResults = (state) => state.noResultsFound

/**
 * Get User List menu selected item
 * @method listItem
 * @param  state vuex rootState shared by all modules
 * @return  {Object} item details
 */
const listItem = (state) => state.listItem

/**
 * Determine which list to load nurse or customer
 * @param state
 * @returns {string}
 */
const listType = (state) => state.listType

/**
 * Hold the current seleted booking record.
 * @param {*} state
 */
const currentBooking = (state) => state.booking

/**
 * Holds current state of the dialog
 * @param {*} state
 */
const showDialog = (state) => state.showDialog
/**
 * Get Dashboard title
 * @param state
 * @returns {*}
 */
const dashboardTitle = (state) => state.title

/**
 * Holds current portal section title
 * @param {*} state
 */
const showDrawerState = (state) => state.showDrawer
/**
 * True/false to show send message dialog
 * @method showSendMsgDiaglog
 * @param {Object} state
 */
const showSendMsgDiaglog = (state) => state.showSendMsgDiaglog

const isInternetConnected = (state) => state.isConnected

const getNurseStatus = (state) => state.fetchApproved
const getCurrentAdmin = state => state.currentAdmin
const getCurrentUser = state => state.currentUser
/**
 * 
 * @param {*} state 
 * @returns  {Array}
 */
const getIncomeTrace = state => state.income_trace
/**
 * Count of total database records
 * @param {*} state 
 * @returns {Number}
 */
const getCount = state => state.count
/**
 * pagination size
 * @param {*} state 
 * @returns {Number}
 */
const getLimitRequestSize = state => state.limitRequestSize
/**
 * User account status
 * @param {*} state 
 * @returns {String}
 */
const getAccountStatus = state => state.account_status


export {
  currentCountryCode,
  getLoadingState,
  getSelectedId,
  checkIsAuthenticated,
  getDataListSearchItems,
  getDataListItems,
  getCurrentUser,
  showSendMsgDiaglog,
  getIsSearch,
  noResults,
  listItem,
  listType,
  currentBooking,
  showDialog,
  dashboardTitle,
  showDrawerState,
  isInternetConnected,
  getNurseStatus,
  getCurrentAdmin,
  getIncomeTrace,
  getCount,
  getLimitRequestSize,
  getAccountStatus,
}
