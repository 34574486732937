const UPDATE_NURSE_LIST = (state, payload) => {
  state.nurseList = payload
}

const UPDATE_NURSE_JOB_LIST = (state, payload) => {
  state.nurseJobList = payload
}

const UPDATE_NURSE_LOADING = (state, payload) => {
  state.loadingNurses = payload
}

const UPDATE_SHOW_NURSE_DIALOG = (state, payload) => {
  state.showAddNurseDialog = payload
}

const UPDATE_NURSE_REVIEWS = (state, payload) => {
  state.nurseReviews = payload
}

const SHOW_REVIEW_DIALOG = (state, payload) => {
  state.showReviewDialog = payload
}
export {
  UPDATE_NURSE_LIST,
  UPDATE_NURSE_JOB_LIST,
  UPDATE_NURSE_LOADING,
  UPDATE_SHOW_NURSE_DIALOG,
  UPDATE_NURSE_REVIEWS,
  SHOW_REVIEW_DIALOG
}
