<template>
  <div>
    <!-- <div class="d-flex align-start flex-column mb-6">
      <v-row dense>
        <v-col cols="2.5">
          <v-card color="deep-purple" dark>
            <v-card-title class="text-h5">Patient Care</v-card-title>

            <v-card-subtitle
              >Numbers related to completed care request
            </v-card-subtitle>
            <div>
              <v-btn class="ma-2" outlined x-large fab color="white">
                5000
              </v-btn>
              <v-btn class="ma-2" outlined x-large fab color="white">
                {{ pc }}
              </v-btn>
              <v-btn class="ma-2" outlined x-large fab color="white">
                {{ pc }}
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="2.5">
          <v-card color="indigo" dark>
            <v-card-title class="text-h5"> Postnatal Care </v-card-title>

            <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            >

            <v-card-actions>
              <v-btn text> Listen Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2.5">
          <v-card color="teal" dark>
            <v-card-title class="text-h5">Prepared Mama</v-card-title>

            <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            >

            <v-card-actions>
              <v-btn text> Listen Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2.5">
          <v-card color="light-green" dark>
            <v-card-title class="text-h5">Value Kit</v-card-title>

            <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            >

            <v-card-actions>
              <v-btn text> Listen Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2.5">
          <v-card color="red" dark>
            <v-card-title class="text-h5">Teheca Shop</v-card-title>

            <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            >

            <v-card-actions>
              <v-btn text> Listen Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div> -->
    <div class="align-start income-trace">
      <income-trace-datatable
        :data="getIncomeTrace"
        :count="getCount"
        title="Income Trace"
      />
    </div>
  </div>
</template>
<script>
import IncomeTraceDataTable from "./IncomeTraceDataTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "FinanceContent",
  components: {
    "income-trace-datatable": IncomeTraceDataTable,
  },
  data() {
    return {
      pc: 100,
    };
  },
  computed: {
    ...mapGetters(["getIncomeTrace", "getCount"]),
  },
};
</script>
<style scoped lang="scss">
.income-trace{
  height: auto;
  overflow-y: scroll;
  width: 100%;
}
</style>